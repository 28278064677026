// Online Javascript Editor for free
// Write, Edit and Run your Javascript code using JS Online Comp
import React, { useEffect, useState } from "react";
import "./rewarddetailscard.css";
import searchtotal from "../images/searchtotal.svg"
import circlerupee from "../images/new.svg"
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../config/firebase";
import { addDoc, collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { FaGift, FaShare, FaShareAltSquare } from "react-icons/fa";

const RewardDetailsCard = ({setRedeemCardOpen}) => {
  // Sample data (this would be fetched dynamically in a real-world app)
  const [projects, setProjects] = useState([
    { name: "Oakbrook gardennnnnnnnnnnnnnnnnn ", city: "City1111111111111", landRemaining: 9999910101010 },
    { name: "Lakeside", city: "City2", landRemaining: 56789 },
    { name: "Riverside", city: "City3", landRemaining: 34567 },
  ]);


  const db = getFirestore();
  const [isEarningsExpanded, setIsEarningsExpanded] = useState(false);
  const [isProjectsExpanded, setIsProjectsExpanded] = useState(false);
  const [landRewards, setLandRewards] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [allPropertyData,setAllPropertyData]=useState(null);
  const selectedProject = localStorage.getItem("selectedProject") || null;
  const [totalLands, setTotalLands] = useState(0);

  const [notification, setNotification] = useState("");

  const handleCopy = (reward) => {
    const referralLink = reward?.referralLink || "N/A";

    if (referralLink !== "N/A") {
      navigator.clipboard.writeText(referralLink)
        .then(() => {
          alert("Link copied!");
          
        })
        .catch((err) => console.error("Failed to copy text: ", err));
    } else {
      alert("No link to copy!");
      
    }
  };

  useEffect(() => {
    // Listen for the authenticated user
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchLandRewards(user.uid);
      } else {
        setCurrentUser(null);
        setLandRewards([]);
      }
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    fetchData()
  }, [selectedProject,totalLands,db]);

  useEffect(() => {
    fetchAllData()
  }, []);

  /* const fetchLandRewards = async (userId) => {
    try {
      const q = query(collection(db, "landRewards"), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      const rewards = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLandRewards(rewards);
    } catch (error) {
      console.error("Error fetching land rewards:", error);
    }
  }; */
  
  
  
const fetchLandRewards = async (userId) => {
  try {
    const q = query(collection(db, "landRewards"), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);

    const rewards = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const landsSum = rewards.reduce((sum, reward) => sum + (reward.LANDs || 0), 0);

    
    setLandRewards(rewards);
    setTotalLands(landsSum);
  } catch (error) {
    console.error("Error fetching land rewards:", error);
  }
};
  
  const fetchData = async () => {
    try {
     
      const realPropertyRef = collection(db, "realProperty");
      const q = query(realPropertyRef, where("propertyId", "==", selectedProject));
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Document ID
        ...doc.data(), // Document data
      }));
      console.log("fectch data ", data);
      setPropertyData(data[0]);
    } catch (err) {
      console.error("Error fetching property data:", err);
    } 
  };
  const fetchAllData = async () => {
    try {
      const realPropertyRef = collection(db, "realProperty");
      const q = query(realPropertyRef, where("propertyId", "!=", selectedProject)); // Fetch where propertyId is not equal to selectedProject
      const querySnapshot = await getDocs(q);
  
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Document ID
        ...doc.data(), // Document data
      }));
  
      setAllPropertyData(data); // Set all fetched data to the state
    } catch (err) {
      console.error("Error fetching property data:", err);
    }
  };



  /* const handleShare = async () => {
    try {
      // Step 1: Generate the shareable URL
      const projectName = propertyData.name; 
      const referralLink = `https://realestate.thirdeye.app/${projectName}?propId=${selectedProject}&userId=${currentUser?.uid}`;
  
      // Step 2: Share the link
      if (navigator.share) {
        await navigator.share({
          title: "Check out this project!",
          text: "Explore and invest in this exciting real estate opportunity.",
          url: referralLink,
        });
  
        //console.log("Link shared successfully!");
  
        // Step 3: Create a new document in `landRewards` after successful share
        const landRewardsRef = collection(db, "landRewards");
        await addDoc(landRewardsRef, {
          LANDs: 0,
          createdAt: new Date(), // Current timestamp
          projectId: selectedProject || "", // Project ID
          referralLink: referralLink || "", // Shared link
          referredUserId: "", // Initially empty
          userId: currentUser?.uid || "", // User ID
        });
  
        alert("Land reward entry created successfully!");
  
        //fetch updated land rewards
        await fetchLandRewards(currentUser?.uid);
      } else {
        alert("Sharing not supported on this device.");
      }
    } catch (err) {
      console.error("Error creating land reward entry or sharing the link:", err);
    }
  }; */
  

  const handleShare = async () => {
    try {
      // Step 1: Generate the shareable URL
      const currentUrl = window.location.origin; // Get the base URL dynamically
      const projectName = propertyData?.name;
      
      const sanitizedProjectName = projectName?.replace(/\s+/g, '-');
      const referralLink = `${currentUrl}/${sanitizedProjectName}?propId=${propertyData?.propertyId}&userId=${currentUser?.uid}`;
  
      // Step 2: Check if the referral link already exists in the `landRewards` collection
      const landRewardsRef = collection(db, "landRewards");
      const querySnapshot = await getDocs(
        query(landRewardsRef, where("referralLink", "==", referralLink))
      );
  
      const isReferralLinkExists =   !querySnapshot.empty ;
     
  
      // Step 3: Share the link
      if (navigator.share) {
        await navigator.share({
          title: "Check out this project!",
          text: "We value your time! Whether this property matches your interest or not, you'll still earn LAND rewards for exploring. Click the link and discover more.",
          url: referralLink,
        });
        
  
       
  
        // Step 4: Only create a new document if the referral link doesn't already exist
        if (!isReferralLinkExists) {
          await addDoc(landRewardsRef, {
            LANDs: 0,
            activity:"SHARE",
            createdAt: new Date(), // Current timestamp
            projectId: selectedProject || "", // Project ID
            referralLink:referralLink || "", // Referral link
            referredUserId: currentUser?.uid || "",
            userId: currentUser?.uid || "", // User ID
          });
  
          console.log("Land reward entry created successfully!");
        } else {
          console.log("Referral link already exists. No new document created.");
        }
  
        // Step 5: Fetch updated land rewards
        await fetchLandRewards(currentUser?.uid);
      } else {
        alert("Sharing not supported on this device.");
      }
    } catch (err) {
      console.error("Error creating land reward entry or sharing the link:", err);
    }
  };
  
  

 /*  const handleAllProjectShare = async (project) => {
    try {
      // Step 1: Generate the shareable URL
      const projectName = project?.name; 
      const referralLink = `https://realestate.thirdeye.app/${projectName}?propId=${project?.id}&userId=${currentUser?.uid}`;
  
      // Step 2: Share the link
      if (navigator.share) {
        await navigator.share({
          title: "Check out this project!",
          text: "Explore and invest in this exciting real estate opportunity.",
          url: referralLink,
        });
  
        console.log("Link shared successfully!");
  
        // Step 3: Create a new document in `landRewards` after successful share
        const landRewardsRef = collection(db, "landRewards");
        await addDoc(landRewardsRef, {
          LANDs: 0,
          createdAt: new Date(), // Current timestamp
          projectId: project?.id || "", // Project ID
          referralLink: referralLink || "", // Shared link
          referredUserId: "", // Initially empty
          userId: currentUser?.uid || "", // User ID
        });
  
        console.log("Land reward entry created successfully!");
  
        //fetch updated land rewards
        await fetchLandRewards(currentUser?.uid);
      } else {
        alert("Sharing not supported on this device.");
      }
    } catch (err) {
      console.error("Error creating land reward entry or sharing the link:", err);
    }
  }; */
  
  
 
  /* const handleAllProjectShare = async (project) => {
    try {
      // Step 1: Generate the shareable URL
      const projectName = project?.name;
      const referralLink = `https://realestate.thirdeye.app/${projectName}?propId=${project?.id}&userId=${currentUser?.uid}`;
  
      // Step 2: Check if the referral link already exists in the `landRewards` collection
      const landRewardsRef = collection(db, "landRewards");
      const querySnapshot = await getDocs(
        query(landRewardsRef, where("referralLink", "==", referralLink))
      );
  
      // Determine if the referral link already exists
      const isReferralLinkExists = !querySnapshot.empty;
  
      // Step 3: Share the link
      if (navigator.share) {
        await navigator.share({
          title: "Check out this project!",
          text: "Explore and invest in this exciting real estate opportunity.",
          url: referralLink,
        });
  
        console.log("Link shared successfully!");
  
        // Step 4: Create a new document in `landRewards`
        await addDoc(landRewardsRef, {
          LANDs: 0,
          createdAt: new Date(), // Current timestamp
          projectId: project?.id || "", // Project ID
          referralLink: isReferralLinkExists ? "" : referralLink, // Empty string if already exists
          referredUserId: "", // Initially empty
          userId: currentUser?.uid || "", // User ID
        });
  
        console.log("Land reward entry created successfully!");
  
        // Step 5: Fetch updated land rewards
        await fetchLandRewards(currentUser?.uid);
      } else {
        alert("Sharing not supported on this device.");
      }
    } catch (err) {
      console.error("Error creating land reward entry or sharing the link:", err);
    }
  }; */
  
   // handle redeem card open
  const handleRedeemCard=()=>{

    localStorage.setItem("totalLands", JSON.stringify(totalLands))
    /* window.open("/redeem","_blank") */
    setRedeemCardOpen(true)
  }



  const handleAllProjectShare = async (project) => {
    try {
      // Step 1: Generate the shareable URL
      const currentUrl = window.location.origin; // Get the base URL dynamically
      const projectName = project?.name;
      const sanitizedProjectName = projectName.replace(/\s+/g, '-');
      const referralLink = `${currentUrl}/${sanitizedProjectName}?propId=${project?.id}&userId=${currentUser?.uid}`;
  
      // Step 2: Check if the referral link already exists in the `landRewards` collection
      const landRewardsRef = collection(db, "landRewards");
      const querySnapshot = await getDocs(
        query(landRewardsRef, where("referralLink", "==", referralLink))
      );
  
      // Determine if the referral link already exists
      const isReferralLinkExists = !querySnapshot.empty;
     
        
      // Step 3: Share the link
      if (navigator.share) {
        await navigator.share({
          title: "Check out this project!",
          text: "We value your time! Whether this property matches your interest or not, you'll still earn LAND rewards for exploring. Click the link and discover more.",
          url: referralLink,
        });
  
      
        
  
        // Step 4: Only create a new document if the referral link doesn't already exist
        if (!isReferralLinkExists) {
          await addDoc(landRewardsRef, {
            LANDs: 0,
            createdAt: new Date(), // Current timestamp
            projectId: project?.id || "", // Project ID
            referralLink:referralLink || "", // Referral link
            referredUserId: currentUser?.uid || "", 
            userId: currentUser?.uid || "", // User ID
          });
  
          console.log("Land reward entry created successfully!");
        } else {
          console.log("Referral link already exists. No new document created.");
        }
  
        // Step 5: Fetch updated land rewards
        await fetchLandRewards(currentUser?.uid);
      } else {
        alert("Sharing not supported on this device.");
      }
    } catch (err) {
      console.error("Error creating land reward entry or sharing the link:", err);
    }
  };
  

  return (
    <div className="reward-details-card" >
      <h2 className="reward-details-card__title" >My LAND Rewards</h2>

      <div className="reward-details-card__header mb-6 pb-3" >
        <h2 className="reward-details-card__header mb-4" style={{fontSize:"1.5rem"}} onClick={() => setIsEarningsExpanded(!isEarningsExpanded)}>
        Your Earnings {isEarningsExpanded ? "-" : "+"}
        </h2>
        {isEarningsExpanded && (<div className="reward-details-card__grid-container">
          <div className="reward-details-card__font-medium">Date</div>
          <div className="reward-details-card__font-medium">Project</div>
          <div className="reward-details-card__font-medium">Referrer</div>
          <div className="reward-details-card__font-medium">LANDs</div>
          <div className="reward-details-card__font-medium">Link</div>
          {landRewards
  .sort((a, b) => {
    // Ensure both `createdAt` fields are valid and compare their timestamps
    const dateA = a.createdAt?.toDate() || new Date(0); // Default to epoch if undefined
    const dateB = b.createdAt?.toDate() || new Date(0);
    return dateA - dateB; // Ascending order: oldest first, newest last
  })
  .map((reward, index) => (
    <React.Fragment key={reward.userId || index}>
      <div>{reward.createdAt?.toDate().toLocaleDateString() || "N/A"}</div>
      <div>{reward?.projectId || "N/A"}</div>
      <div>{reward?.referredUserId || "N/A"}</div>
      <div>{reward?.LANDs || 0}</div>
      {reward.referralLink?(<div
        style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
        onClick={() => handleCopy(reward)}
      >
        {reward?.referralLink}
      </div>):(<div
        
      >
        NA
      </div>)}
    </React.Fragment>
  ))}

        </div>)}
        <div className="reward-details-card__total">
          Total:{totalLands} 

          <p style={{display:"flex", alignItems:"center",gap:'3px'}}   onClick={handleRedeemCard}  title="REDEEM HERE"> <FaGift /></p>
            
           
        </div>
      </div>

     {propertyData && (
      <div className=" mb-4">
     
        
          <div  className="reward-details-card__grid-container">
            <div>
              <div className="reward-details-card__project-item-name">
                <a /* style={{width:"50px"}} */>{propertyData?.name}</a> 
                <a /* style={{width:"40px"}} */>{propertyData?.city}</a> 
                
              </div>
              </div>

            
            
            
          </div>
          <div  className="reward-details-card__land-remaining" >
              <a style={{width:"300px"}}>LANDs remaining:{propertyData?.LANDs}</a> 
               <FaShareAltSquare onClick={handleShare} style={{width:"40px",height:"40px"}} title="SHARE AND EARN"/>
              {/* <img src={circlerupee} style={{width:"50px",height:"50"}} /> */}
             
                
                
                </div> 
       
      </div>)}


      

      <h2 style={{fontSize:"1.5rem" ,fontWeight:"500"}} className="reward-details-card__title text-center mb-4" onClick={() => setIsProjectsExpanded(!isProjectsExpanded)}>
        All Projects {isProjectsExpanded ? "-" : "+"}
      </h2>

      <div className="reward-details-card__header mb-6 pb-3" >
        
      {isProjectsExpanded && (
  <div className="reward-details-card__grid-container_allproject">
    {allPropertyData.length<=0?("No More Projects"):(allPropertyData.map((project, index) => (
     /*  <React.Fragment key={project.userId}>
        <div className="reward-details-card__row_allproject">
          <div>{project?.name}</div>
          <div>{project?.city}</div>
        </div>
        <div className="reward-details-card__land-remaining">
          <a style={{ width: "300px" }}>
            LANDs remaining: {project?.LANDs}
          </a>
          <img 
            src={circlerupee} 
            alt="rupee icon" 
            style={{ width: "50px", height: "50px" }} 
            onClick={() => handleAllProjectShare(project)} 
          />
        </div>
      </React.Fragment> */

      <div className=" mb-4">
     
        
          <div  className="reward-details-card__grid-container">
            <div>
              <div className="reward-details-card__project-item-name">
                <a /* style={{width:"50px"}} */>{project?.name}</a> 
                <a /* style={{width:"40px"}} */>{project?.city}</a> 
                
              </div>
              </div>

            
            
            
          </div>
          <div  className="reward-details-card__land-remaining" >
              <a style={{width:"300px"}}>LANDs remaining:{project?.LANDs}</a> 
               <FaShareAltSquare onClick={()=>handleAllProjectShare(project)} style={{width:"40px",height:"40px"}} title="SHARE AND EARN"/>
              {/* <img src={circlerupee} style={{width:"50px",height:"50"}} /> */}
             
                
                
                </div> 
       
      </div>
    )))}
  </div>
)}

        
      </div>
      
      
      



     
    </div>
  );
};

export default RewardDetailsCard;
