
import "./EarnLand.css";
import { Logo } from "../area";
import { useNavigate } from "react-router-dom";
import { Header, HeaderContent } from "../Faq.style";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import logo from "../../images/thirdeye.png";
import { useEffect, useState } from "react";

const EarnLand = () => {
    const navigate=useNavigate()
          const [isLoggedIn, setIsLoggedIn] = useState(false);
          const [user,setUser]=useState(null);
        
          useEffect(() => {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
              if (user) {
                setUser(user)
                setIsLoggedIn(true); 
              } else {
                setIsLoggedIn(false); 
              }
            });
        
            return () => unsubscribe();
          }, []);
  return (
    <>
    <Header>
    <HeaderContent>
    <Logo src={logo} alt="Thirdeye" onClick={()=>navigate("/")} />
          {isLoggedIn && (<h3
            className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
            onClick={() => {
              navigate("/rewardcustomerdash");
            }}
          >
            Dashboard
          </h3>)}
    </HeaderContent>
  </Header>
    <div className="earnland-container">
      <div className="earnland-header">
        <h1>
          Earn <span className="highlight">LAND Tokens</span> – Discover, Engage, Reward!
        </h1>
        <p>
          Welcome to the future of real estate with LAND Tokens, a unique reward system designed to make your experience on ThirdEye not just engaging but rewarding!
        </p>
      </div>

      <div className="earnland-section">
        <h2>What are LAND Tokens?</h2>
        <p>
          LAND Tokens are digital rewards you earn by interacting with projects on the ThirdEye platform. Whether you’re exploring premium plots, listing a project, or sharing details with others, LAND Tokens offer an exciting way to get rewarded for your engagement.
        </p>
      </div>

      <div className="earnland-how">
        <h2>How to Earn LAND Tokens?</h2>
        <div className="earnland-card">
          <h3>1. Explore Real Estate Projects</h3>
          <p>
            Browse through premium real estate listings and earn LAND Tokens just for discovering new opportunities.
            <span className="tip"> 💡 Tip: The more you explore, the more you earn!</span>
          </p>
        </div>

        <div className="earnland-card">
          <h3>2. Share with Your Network</h3>
          <p>
            Invite friends, family, or business associates to check out projects on ThirdEye. Earn LAND Tokens every time someone engages through your referral.
            <span className="tip"> 💬 Spread the word and watch your rewards grow!</span>
          </p>
        </div>

        <div className="earnland-card">
          <h3>3. Participate in Activities</h3>
          <p>
            From participating in surveys to attending virtual tours, ThirdEye provides multiple opportunities to earn LAND Tokens while staying informed.
            <span className="tip"> 🎉 Fun and rewarding at the same time!</span>
          </p>
        </div>

        <div className="earnland-card">
          <h3>4. List Your Projects</h3>
          <p>
            Are you a developer? Earn LAND Tokens when you list your real estate projects on ThirdEye. It’s our way of saying thanks for trusting us!
          </p>
        </div>
      </div>

      <div className="earnland-why">
        <h2>Why Earn LAND Tokens?</h2>
        <ul>
          <li>🏆 <strong style={{whiteSpace:"nowrap"}}>Valuable Rewards:</strong> Redeem LAND Tokens for exclusive benefits like Amazon Gift Cards or use them for future interactions on ThirdEye.</li>
          <li>🔗 <strong>Blockchain-Powered:</strong> Enjoy transparency, security, and ease of use with our blockchain-based token system in future.</li>
          <li>🚀 <strong>Innovative Experience:</strong> Be part of a game-changing real estate ecosystem that rewards you for your engagement.</li>
        </ul>
      </div>

      <div className="earnland-cta">
        <h2>Start Earning Today!</h2>
        <p>Don’t miss out on this exciting opportunity to explore, share, and earn. Every interaction on ThirdEye is a step closer to unlocking the full potential of your LAND Tokens.</p>
        <a className="cta-button" onClick={()=>navigate("/")}>👉 Start Exploring Now</a>
        <h2 style={{color:"black"}}>Earn. Engage. Explore. Welcome to the world of <strong style={{color:"#2980b9"}}>LAND Tokens</strong>!</h2>
      </div>
    </div>
    </>
  );
};

export default EarnLand;
