import React from "react";
import styled from "styled-components";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

const FooterContainer = styled.footer`
  background-color: #2d3748; /* Dark background */
  position:relative;
  bottom:0;
  color: white;
  padding: 1.5rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;

  a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s;

    &:hover {
      color: #38b2ac; /* Hover color */
    }

    @media (max-width: 576px) {
      font-size: 1.25rem;
    }
  }
`;

const ContactEmail = styled.p`
  margin: 0.5rem 0;
  font-size: 1rem;

  a {
    color: #63b3ed;
    text-decoration: underline;

    &:hover {
      color: #3182ce; /* Hover color */
    }
  }

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIcons>
        <a href="https://www.facebook.com/p/ThirdEye-App-100065116357938/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebook />
        </a>
        <a href="https://x.com/Thirdeyeapp" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com/thirdeyeapp_realestate1" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/company/saralam-technologies" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <FaLinkedin />
        </a>
        <a href="https://www.youtube.com/@ThirdeyeRealestate1" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <FaYoutube/>
        </a>
      </SocialIcons>
      <ContactEmail>
        Contact us: <a href="mailto:contact@saralam.tech">contact@saralam.tech</a>
      </ContactEmail>
    </FooterContainer>
  );
};

export default Footer;
