import React, { useState, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { auth } from "../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import "typeface-inter";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { CloudUpload as UploadIcon } from "@mui/icons-material";
import { Container, Header, HeaderInner, Logo, MainContent } from "./area";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import logo from "../images/thirdeye.png";
import { collection, addDoc } from "firebase/firestore";
import ReactQuill from "react-quill";
const db = getFirestore();
const storage = getStorage();

const AddProperty = () => {
  // History

  const navigateHistory = useNavigate();

  const handleBack = () => {
    navigateHistory(-1);
  };

  const handleInputField = () => {
    if (inputList.length < 3) {
      setInputList((prev) => [...prev, ""]);
    } else {
      alert("Can Upload Images more than 3");
    }
  };

  // History end

  const formRef = useRef();
  const [businessName, setBusinessName] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [tagline, setTagline] = useState("");
  const [projectDescr,setProjectDescr]=useState("")
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [smartLayoutUrl, setSmartLayoutUrl] = useState(
    "https://vasavi.thirdeye.app/"
  );
  const [planCopy, setPlanCopy] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileLabel, setUploadedFileLabel] = useState("");
  const navigate = useNavigate();
  const [showPropertyForm, setShowPropertyForm] = useState(false);
  const [totalArea, setTotalArea] = useState("");
  const [totalPlots, setTotalPlots] = useState("");
  const [soldPlots, setSoldPlots] = useState("");
  const [flag, setFlag] = useState(true);

  const [flagplan, setflagplan] = useState(true);
  // const [planCopy, setPlanCopy] = useState(null);

  const [imageList, setImageList] = useState([]);
  const [inputList, setInputList] = useState([]);

  const [Image1Copy, setImage1Copy] = useState(null);
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [uploadedImageLabel1, setUploadedImageLabel1] = useState("");

  const [Image2Copy, setImage2Copy] = useState(null);
  const [uploadProgress2, setuploadProgress2] = useState(0);
  const [uploadedImageLabel2, setuploadedImageLabel2] = useState("");

  const [Image3Copy, setImage3Copy] = useState(null);
  const [uploadProgress3, setuploadProgress3] = useState(0);
  const [uploadedImageLabel3, setuploadedImageLabel3] = useState("");

  const [Image4Copy, setImage4Copy] = useState(null);
  const [uploadProgress4, setUploadProgress4] = useState(0);
  const [uploadedImageLabel4, setUploadedImageLabel4] = useState("");

  const [showImageUploads, setShowImageUploads] = useState(false);
  const toolbarOptions = {
    toolbar: [
      ["bold", "italic", "underline"], // Basic formatting options
      [{ list: "ordered" }, { list: "bullet" }], // List options
      [{ align: [] }], // Text alignment
      [{ font: [] }], // Font family
      [{ color: [] }, { background: [] }], // Font and background color
      ["clean"], // Remove formatting
    ],
  };
  

  const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 50;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      padding: 0.625rem 1.5rem; /* lg:px-6 */
    }
    &.dark {
      background-color: #2d3748; /* dark:bg-gray-800 */
    }
  `;

  //const navigate = useNavigate();

  // const saveDataToFirestore = async () => {
  //   await setDoc(businessUserDocRef, businessUserData, { merge: true });

  //   // Add a new document to the collection and get the document reference
  //   const docRef = await addDoc(realPropertyCollectionRef, realPropertyData);

  //   // Get the unique ID of the document
  //   const propertyId = docRef.id;

  //   // Add the propertyId to the document
  //   await setDoc(doc(db, "realProperty", propertyId), { propertyId: propertyId }, { merge: true });

  //   await setDoc(userDocRef, { isNewUser: false }, { merge: true });

  //   navigate('/dashboard');
  // };

  const handleAddProperty = () => {
    if (!businessName) {
      alert("Name required!!");
      return;
    }
    setShowPropertyForm(true);
  };

  const handleFileChange = (event) => {
    setPlanCopy(event.target.files[0]);
    setUploadedFileLabel(event.target.files[0].name);
  };
  const handleImage1Change = (event) => {
    setImage1Copy(event.target.files[0]);
    setUploadedImageLabel1(event.target.files[0].name);
  };
  const handleImage2Change = (event) => {
    setImage2Copy(event.target.files[0]);
    setuploadedImageLabel2(event.target.files[0].name);
  };
  const handleImage3Change = (event) => {
    setImage3Copy(event.target.files[0]);
    setuploadedImageLabel3(event.target.files[0].name);
  };

  const handleImage4Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage4Copy(file);
      setUploadedImageLabel4(file.name);
    }
  };

  // const handleImageUpload = (event) => {
  //   const files = Array.from(event.target.files);

  //   if (imageList.length + files.length <= 3) {
  //     setImageList([...imageList, ...files]);
  //   } else {
  //     alert("You can only upload up to 3 images.");
  //   }

  // };

  const handleContinue = async (event) => {
    event.preventDefault();
    if (
      !propertyName ||
      !city ||
      !state ||
      !pincode ||
      !country ||
      !propertyType ||
      !totalArea ||
      !totalPlots ||
      !tagline ||
      !projectDescr
    ) {
      alert("Please fill in all mandatory fields before proceeding.");
      return;
    }

    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);
    const businessUserDocRef = doc(db, "businessUser", user.uid); // userId field in businessUser collection
    const realPropertyCollectionRef = collection(db, "realProperty"); // collection reference
    const businessUserData = businessName
      ? { businessName: businessName, userId: user.uid }
      : null;
    const realPropertyData = {
      name: propertyName || null,
      city: city || null,
      state: state || null,
      pincode: pincode || null,
      country: country || null,
      propertyType: propertyType || null,
      RERANO: null,
      businessId: user.uid,
      smartlayout: smartLayoutUrl || null,
      totalArea: totalArea || null,
      totalPlots: totalPlots || null,
      soldPlots: soldPlots || null,
      createdAt: new Date(),
      verified: false,
      published:false,
      LANDs: 10000,
      tagline: tagline || null,
      projectDescr:projectDescr || null

    };

    // const uploadAndGetURL = (file, path) => {
    //   return new Promise((resolve, reject) => {
    //     if (file) {
    //       const fileSizeLimit = 2 * 1024 * 1024; // 2MB limit

    //       if (file.size > fileSizeLimit) {
    //         reject(new Error(`File size exceeds the 2MB limit: ${file.name}`));
    //         alert(`File size exceeds the 2MB limit: ${file.name}`);
    //         return;
    //       }

    //       const storageRef = ref(storage, path + file.name);
    //       const uploadTask = uploadBytesResumable(storageRef, file);

    //       uploadTask.on(
    //         "state_changed",
    //         (snapshot) => {
    //           // upload progress
    //           const progress =
    //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //           if (path.includes("PropertyPlan")) {
    //             setUploadProgress(progress);
    //           } else if (
    //             path.includes("PropertyImages") &&
    //             file === Image1Copy
    //           ) {
    //             setUploadProgress1(progress);
    //           } else if (
    //             path.includes("PropertyImages") &&
    //             file === Image2Copy
    //           ) {
    //             setuploadProgress2(progress);
    //           } else if (
    //             path.includes("PropertyImages") &&
    //             file === Image3Copy
    //           ) {
    //             setuploadProgress3(progress);
    //           }
    //         },
    //         (error) => {
    //           // unsuccessful uploads
    //           console.error("Error uploading file: ", error);
    //           reject(error);
    //         },
    //         () => {
    //           // successful uploads on complete
    //           getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //             resolve(downloadURL);
    //           });
    //         }
    //       );
    //     } else {
    //       resolve(null); // Resolve with null if no file to upload
    //     }
    //   });
    // };
    const uploadAndGetURL = (file, path) => {
      return new Promise((resolve, reject) => {
        if (file) {
          const fileSizeLimit = 2 * 1024 * 1024; // 2MB limit

          if (file.size > fileSizeLimit) {
            reject(new Error(`File size exceeds the 2MB limit: ${file.name}`));
            alert(`File size exceeds the 2MB limit: ${file.name}`);
            return;
          }

          // Generate a unique ID for the file name
          const uniqueFileName = `${uuidv4()}_${file.name}`;
          const storageRef = ref(storage, path + uniqueFileName);
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // upload progress
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (path.includes("PropertyPlan")) {
                setUploadProgress(progress);
              } else if (
                path.includes("PropertyImages") &&
                file === Image1Copy
              ) {
                setUploadProgress1(progress);
              } else if (
                path.includes("PropertyImages") &&
                file === Image2Copy
              ) {
                setuploadProgress2(progress);
              } else if (
                path.includes("PropertyImages") &&
                file === Image3Copy
              ) {
                setuploadProgress3(progress);
              } else if (
                path.includes("PropertyImages") &&
                file === Image4Copy
              ) {
                setUploadProgress4(progress);
              }
            },
            (error) => {
              // unsuccessful uploads
              console.error("Error uploading file: ", error);
              reject(error);
            },
            () => {
              // successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              });
            }
          );
        } else {
          resolve(null); // Resolve with null if no file to upload
        }
      });
    };

    const uploadFiles = async () => {
      try {
        const uploadPromises = [];
        const updates = {};

        if (planCopy) {
          uploadPromises.push(
            uploadAndGetURL(planCopy, "PropertyPlan/").then((url) => {
              updates.planCopy = url;
            })
          );
        }

        if (Image1Copy) {
          uploadPromises.push(
            uploadAndGetURL(Image1Copy, "PropertyImages/").then((url) => {
              updates.Image1Copy = url;
            })
          );
        }

        if (Image2Copy) {
          uploadPromises.push(
            uploadAndGetURL(Image2Copy, "PropertyImages/").then((url) => {
              updates.Image2Copy = url;
            })
          );
        }

        if (Image3Copy) {
          uploadPromises.push(
            uploadAndGetURL(Image3Copy, "PropertyImages/").then((url) => {
              updates.Image3Copy = url;
            })
          );
        }

        if (Image4Copy) {
          uploadPromises.push(
            uploadAndGetURL(Image4Copy, "PropertyImages/").then((url) => {
              updates.Image4Copy = url;
            })
          );
        }

        // Wait for all uploads to complete (if any)
        await Promise.all(uploadPromises);

        // Update the Firestore data with available download URLs
        Object.assign(realPropertyData, updates);

        // Save the data to Firestore, even if no files were uploaded
        await saveDataToFirestore(
          userDocRef,
          businessUserDocRef,
          realPropertyCollectionRef,
          businessUserData,
          realPropertyData
        );
        console.log("Data saved to Firestore successfully.");
      } catch (error) {
        console.error("Error during upload and save:", error);
      }
    };

    uploadFiles();
  };


  const handleDeleteImage = (imageNumber) => {
    switch (imageNumber) {
      case 1:
        setImage1Copy(null);
        setUploadProgress1(0);
        setUploadedImageLabel1("");
        break;
      case 2:
        setImage2Copy(null);
        setuploadProgress2(0);
        setuploadedImageLabel2("");
        break;
      case 3:
        setImage3Copy(null);
        setuploadProgress3(0);
        setuploadedImageLabel3("");
        break;
      case 4:
        setImage4Copy(null);
        setUploadProgress4(0);
        setUploadedImageLabel4("");
        break;
      default:
        break;
    }
  };
  

  // const handleImageChange = (e, setUploadProgress, setUploadedFileLabel, imageField) => {
  //   const user = auth.currentUser;
  //   const userDocRef = doc(db, "users", user.uid);
  //   const businessUserDocRef = doc(db, "businessUser", user.uid); // userId field in businessUser collection
  //   const realPropertyCollectionRef = collection(db, "realProperty"); // collection reference
  //   const businessUserData = businessName ? { businessName: businessName, userId: user.uid } : null;
  //   const realPropertyData = {
  //     name: propertyName || null,
  //     city: city || null,
  //     state: state || null,
  //     pincode: pincode || null,
  //     country: country || null,
  //     propertyType: propertyType || null,
  //     RERANO: null,
  //     businessId: user.uid,
  //     smartlayout: smartLayoutUrl || null,
  //     totalArea: totalArea || null,
  //     totalPlots: totalPlots || null,
  //     soldPlots: soldPlots || null
  //   };
  //   const file = e.target.files[0];
  //   if (file && file.size <= 5 * 1024 * 1024) { // Check if file size is less than or equal to 5 MB
  //     const storageRef = ref(storage, 'PropertyImages/' + file.name);
  //     const uploadTask = uploadBytesResumable(storageRef, file);

  //     uploadTask.on('state_changed',
  //       (snapshot) => {
  //         // upload progress
  //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         setUploadProgress(progress);
  //       },
  //       (error) => {
  //         // unsuccessful uploads
  //         console.error("Error uploading file: ", error);
  //       },
  //       () => {
  //         // successful uploads on complete
  //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //           console.log('File available at', downloadURL);
  //           realPropertyData[imageField] = downloadURL; // Save the download URL to the appropriate field
  //           setUploadedFileLabel(file.name);
  //         });
  //       }
  //     );
  //   } else {
  //     alert('File size must be 5 MB or less.');
  //   }
  // };

  const saveDataToFirestore = async (
    userDocRef,
    businessUserDocRef,
    realPropertyCollectionRef,
    businessUserData,
    realPropertyData
  ) => {
    await setDoc(businessUserDocRef, businessUserData, { merge: true });

    // add a new document to the collection and get the document reference
    const docRef = await addDoc(realPropertyCollectionRef, realPropertyData);

    // get the unique ID of the document
    const propertyId = docRef.id;

    // add the propertyId to the document
    await setDoc(
      doc(db, "realProperty", propertyId),
      { propertyId: propertyId },
      { merge: true }
    );

    await setDoc(userDocRef, { isNewUser: false }, { merge: true });

    navigate("/dashboard");
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Republic of the",
    "Congo, Democratic Republic of the",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  // const handleImageChange = (e, setUploadProgress, setUploadedFileLabel) => {
  //   const file = e.target.files[0];
  //   if (file && file.size <= 5 * 1024 * 1024) { // Check if file size is less than or equal to 5 MB
  //     const reader = new FileReader();
  //     reader.onload = (upload) => {
  //       setUploadProgress(100); // Mock upload progress
  //       setUploadedFileLabel(file.name);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     alert('File size must be 5 MB or less.');
  //   }}

  return (
    <div>
      <Header>
        <HeaderInner>
          <div className="flex justify-start items-center">
            <Logo src={logo} alt="Thirdeye" onClick={()=>navigate("/")}/>
          </div>
        </HeaderInner>
      </Header>

      <div className="flex justify-center items-center min-h-screen bg-gray-100 p-5">
        <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-screen-md overflow-auto">
          <div
            className="app__container"
            style={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80%",
              width: "100%",
              margin: "0 auto",
              minHeight: "40vh",
              overflow: "auto",
              paddingTop: "30px",
            }}
          >
            <div className="w-full bg-custom-blue p-5 rounded-lg text-white text-center">
              <h1 className="font-inter">Register</h1>
            </div>
            <div className="w-full">
              <TextField
                fullWidth
                variant="outlined"
                label="Enter Business name"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                sx={{ width: "100%", marginTop: "20px" }}
              />
              {!showPropertyForm && (
                <button
                  className="w-full mt-5 p-2 bg-custom-blue text-white rounded-md"
                  onClick={handleAddProperty}
                >
                  Add New Property for Investors
                </button>
              )}
              {showPropertyForm && (
                <div>
                  <form ref={formRef} onSubmit={handleContinue}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Property Name"
                          value={propertyName}
                          onChange={(e) => setPropertyName(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Tagline"
                          value={tagline}
                          onChange={(e) => setTagline(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <TextField
                          fullWidth
                          variant="outlined"
                          label="Project Description"
                          value={projectDescr}
                          onChange={(e) => setProjectDescr(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        /> */}
                        <ReactQuill
        theme="snow"
        value={projectDescr}
        onChange={setProjectDescr}
        modules={toolbarOptions}
       placeholder="Property Details"
       
        style={{ height: "150px", marginBottom: "20px",fontSize:"20px", }}
      />
                        
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="State"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Pincode"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          options={countries}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              variant="outlined"
                              sx={{ width: "100%", marginTop: "20px" }}
                            />
                          )}
                          value={country}
                          onChange={(event, newValue) => {
                            setCountry(newValue);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ width: "100%", marginTop: "20px" }}
                        >
                          <InputLabel id="property-type-label">
                            Property Type
                          </InputLabel>
                          <Select
                            labelId="property-type-label"
                            value={propertyType}
                            onChange={(e) => setPropertyType(e.target.value)}
                            label="Property Type"
                          >
                            <MenuItem value={"Open Plot"}>Open Plot</MenuItem>
                            <MenuItem value={"Commercial Building"}>
                              Commercial Building
                            </MenuItem>
                            <MenuItem value={"Agriculture Land"}>
                              Agriculture Land
                            </MenuItem>
                            <MenuItem value={"Independent House"}>
                              Independent House
                            </MenuItem>
                            <MenuItem value={"Residential Apartment"}>
                              Residential Apartment
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Smart Layout URL"
                          value={smartLayoutUrl}
                          onChange={(e) => setSmartLayoutUrl(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title=" On Click Go To SmartLayout">
                                <IconButton
                                  href={smartLayoutUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Total Area"
                          value={totalArea}
                          onChange={(e) => setTotalArea(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Total Plots"
                          value={totalPlots}
                          onChange={(e) => setTotalPlots(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Sold Plots"
                          value={soldPlots}
                          onChange={(e) => setSoldPlots(e.target.value)}
                          sx={{ width: "100%", marginTop: "20px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          width: "100%",
                          marginTop: "20px",
                          backgroundColor: "#1a96f3",
                          color: "#fff",
                        }}
                      >
                        <UploadIcon style={{ marginRight: "5px" }} />
                        Upload Plan Copy
                        <input
                          type="file"
                          hidden
                          accept=".pdf,image/*"
                          onChange={handleFileChange}
                        />
                      </Button>

                      {uploadProgress > 0 && (
                        <LinearProgress
                          variant="determinate"
                          value={uploadProgress}
                        />
                      )}
                      {uploadedFileLabel && (
                        <Typography variant="body2">
                          {uploadedFileLabel}
                        </Typography>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {flag && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setShowImageUploads(true);
                              setFlag(false);
                            }}
                            sx={{
                              width: "100%",
                              marginTop: "20px",
                              backgroundColor: "#1a96f3",
                              color: "#fff",
                            }}
                          >
                            <UploadIcon style={{ marginRight: "5px" }} />
                            Upload Images
                          </Button>
                        )}
                      </Grid>

                      {showImageUploads && (
                        <>
                          <div className="bg-white p-4 rounded-md shadow-md">
                            <p>upload Images</p>

                            <div className="mt-4  justify-end">
                              {/* <Grid item xs={12} sx={{ paddingLeft: "10px" }}>
                                <Button
                                  variant="contained"
                                  component="label"
                                  sx={{
                                    width: "100%",
                                    marginTop: "10px",
                                    backgroundColor: "#1a96f3",
                                    color: "#fff",
                                  }}
                                >
                                  <UploadIcon style={{ marginRight: "5px" }} />
                                  Upload Image 1
                                  <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleImage1Change}
                                  />
                                </Button>
                                {uploadProgress1 > 0 && (
                                  <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress1}
                                  />
                                )}
                                {uploadedImageLabel1 && (
                                  <Typography variant="body2">
                                    {uploadedImageLabel1}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sx={{ paddingLeft: "10px" }}>
                                {Image1Copy && (
                                  <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                      width: "100%",
                                      marginTop: "10px",
                                      backgroundColor: "#1a96f3",
                                      color: "#fff",
                                    }}
                                  >
                                    <UploadIcon
                                      style={{ marginRight: "5px" }}
                                    />
                                    Upload Image 2
                                    <input
                                      type="file"
                                      hidden
                                      accept="image/*"
                                      onChange={handleImage2Change}
                                    />
                                  </Button>
                                )}
                                {uploadProgress2 > 0 && (
                                  <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress2}
                                  />
                                )}
                                {uploadedImageLabel2 && (
                                  <Typography variant="body2">
                                    {uploadedImageLabel2}
                                  </Typography>
                                )}
                              </Grid>

                              <Grid item xs={12} sx={{ paddingLeft: "10px" }}>
                                {Image2Copy && (
                                  <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                      width: "100%",
                                      marginTop: "10px",
                                      backgroundColor: "#1a96f3",
                                      color: "#fff",
                                    }}
                                  >
                                    <UploadIcon
                                      style={{ marginRight: "5px" }}
                                    />
                                    Upload Image 3
                                    <input
                                      type="file"
                                      hidden
                                      accept="image/*"
                                      onChange={handleImage3Change}
                                    />
                                  </Button>
                                )}
                                {uploadProgress3 > 0 && (
                                  <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress3}
                                  />
                                )}
                                {uploadedImageLabel3 && (
                                  <Typography variant="body2">
                                    {uploadedImageLabel3}
                                  </Typography>
                                )}
                              </Grid>

                              <Grid item xs={12} sx={{ paddingLeft: "10px" }}>
                                {Image3Copy && (
                                  <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                      width: "100%",
                                      marginTop: "10px",
                                      backgroundColor: "#1a96f3",
                                      color: "#fff",
                                    }}
                                  >
                                    <UploadIcon
                                      style={{ marginRight: "5px" }}
                                    />
                                    Upload Image 4
                                    <input
                                      type="file"
                                      hidden
                                      accept="image/*"
                                      onChange={handleImage4Change}
                                    />
                                  </Button>
                                )}
                                {uploadProgress4 > 0 && (
                                  <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress4}
                                  />
                                )}
                                {uploadedImageLabel4 && (
                                  <Typography variant="body2">
                                    {uploadedImageLabel4}
                                  </Typography>
                                )}
                              </Grid> */}

<Grid item xs={12} sx={{ paddingLeft: "10px" }}>
  <Button
    variant="contained"
    component="label"
    sx={{
      width: "100%",
      marginTop: "10px",
      backgroundColor: "#1a96f3",
      color: "#fff",
    }}
  >
    <UploadIcon style={{ marginRight: "5px" }} />
    Upload Image 1
    <input
      type="file"
      hidden
      accept="image/*"
      onChange={handleImage1Change}
    />
  </Button>
  {uploadProgress1 > 0 && (
    <LinearProgress variant="determinate" value={uploadProgress1} />
  )}
  {uploadedImageLabel1 && (
    <>
      <Typography variant="body2">{uploadedImageLabel1}</Typography>
      <Button
        variant="text"
        color="error"
        onClick={() => handleDeleteImage(1)}
      >
        Delete
      </Button>
    </>
  )}
</Grid>

<Grid item xs={12} sx={{ paddingLeft: "10px" }}>
  {Image1Copy && (
    <Button
      variant="contained"
      component="label"
      sx={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "#1a96f3",
        color: "#fff",
      }}
    >
      <UploadIcon style={{ marginRight: "5px" }} />
      Upload Image 2
      <input
        type="file"
        hidden
        accept="image/*"
        onChange={handleImage2Change}
      />
    </Button>
  )}
  {uploadProgress2 > 0 && (
    <LinearProgress variant="determinate" value={uploadProgress2} />
  )}
  {uploadedImageLabel2 && (
    <>
      <Typography variant="body2">{uploadedImageLabel2}</Typography>
      <Button
        variant="text"
        color="error"
        onClick={() => handleDeleteImage(2)}
      >
        Delete
      </Button>
    </>
  )}
</Grid>

<Grid item xs={12} sx={{ paddingLeft: "10px" }}>
  {Image2Copy && (
    <Button
      variant="contained"
      component="label"
      sx={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "#1a96f3",
        color: "#fff",
      }}
    >
      <UploadIcon style={{ marginRight: "5px" }} />
      Upload Image 3
      <input
        type="file"
        hidden
        accept="image/*"
        onChange={handleImage3Change}
      />
    </Button>
  )}
  {uploadProgress3 > 0 && (
    <LinearProgress variant="determinate" value={uploadProgress3} />
  )}
  {uploadedImageLabel3 && (
    <>
      <Typography variant="body2">{uploadedImageLabel3}</Typography>
      <Button
        variant="text"
        color="error"
        onClick={() => handleDeleteImage(3)}
      >
        Delete
      </Button>
    </>
  )}
</Grid>

<Grid item xs={12} sx={{ paddingLeft: "10px" }}>
  {Image3Copy && (
    <Button
      variant="contained"
      component="label"
      sx={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "#1a96f3",
        color: "#fff",
      }}
    >
      <UploadIcon style={{ marginRight: "5px" }} />
      Upload Image 4
      <input
        type="file"
        hidden
        accept="image/*"
        onChange={handleImage4Change}
      />
    </Button>
  )}
  {uploadProgress4 > 0 && (
    <LinearProgress variant="determinate" value={uploadProgress4} />
  )}
  {uploadedImageLabel4 && (
    <>
      <Typography variant="body2">{uploadedImageLabel4}</Typography>
      <Button
        variant="text"
        color="error"
        onClick={() => handleDeleteImage(4)}
      >
        Delete
      </Button>
    </>
  )}
</Grid>

                            </div>
                          </div>

                          <div className="hidden"></div>
                        </>
                      )}
                    </Grid>
                  </form>
                </div>
              )}
            </div>
            <div className="w-full mt-5 flex justify-between">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBack}
                sx={{
                  width: "45%",
                  marginTop: "20px",
                  backgroundColor: "#1a96f3",
                  color: "#fff",
                }}
              >
                BACK
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() =>
                  formRef.current && formRef.current.requestSubmit()
                }
                sx={{
                  width: "45%",
                  marginTop: "20px",
                  backgroundColor: "#1a96f3",
                  color: "#fff",
                }}
              >
                CONTINUE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProperty;
