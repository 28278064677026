import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import DynamicDetails from './DynamicDetails'; 
import NotFound from './NotFound';
export const DynamicRoute = () => {
  const { sanitizedProjectName } = useParams();
  const decodedTitle = sanitizedProjectName.replace(/-/g, ' ');

  const [docExists, setDocExists] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfDocExists = async () => {
      const db = getFirestore();
      const propertyRef = collection(db, 'realProperty');
      const q = query(propertyRef, where('name', '==', decodedTitle));

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setDocExists(true); 
        } else {
          setDocExists(false); 
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
        setDocExists(false); 
      } finally {
        setLoading(false); 
      }
    };

    checkIfDocExists();
  }, [decodedTitle]);

  if (loading) {
    return  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500">
      {" "}
    </div>
  </div>
  }

  
  if (docExists) {
    return <DynamicDetails/>; 
  } else {
    return <NotFound />; 
  }
};
