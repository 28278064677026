// // import { useState, useEffect } from "react";
// // import { auth } from "../config/firebase";
// // import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// // import { useNavigate } from "react-router-dom";
// // import { doc, getDoc, setDoc } from "firebase/firestore";
// // import { getFirestore } from "firebase/firestore";
// // import { getAuth } from "firebase/auth";
// // import logo from "../images/thirdeye.png";
// // import styled from "styled-components";

// // const Container = styled.div`
// //   font-family: sans-serif;
// //   background-color: #f7fafc; /* bg-gray-100 */
// //   height: 100vh;
// //   overflow: hidden;
// //   padding-bottom: 150px;
// // `;

// // const Header = styled.header`
// //   background-color: #fff; /* bg-white */
// //   border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
// //   padding: 0.625rem 1rem; /* px-4 py-2.5 */
// //   @media (min-width: 1024px) {
// //     padding: 0.625rem 1.5rem; /* lg:px-6 */
// //   }
// //   &.dark {
// //     background-color: #2d3748; /* dark:bg-gray-800 */
// //   }
// // `;

// // const HeaderContent = styled.div`
// //   display: flex;
// //   flex-wrap: wrap;
// //   justify-content: space-between;
// //   align-items: center;
// // `;

// // const Logo = styled.img`
// //   height: 2.75rem; /* h-11 */
// //   width: auto;
// //   align-self: center;
// // `;

// // const MainContent = styled.div`
// //   display: flex;
// //   flex-direction: column;
// //   justify-content: center;
// //   align-items: center;
// //   height: 80vh; /* Adjust height for better view on smaller screens */
// //   max-width: 90%; /* Ensure it doesn't overflow on smaller screens */
// //   width: 24rem; /* Default width */
// //   margin: 0 auto;
// //   margin-top: 0.5rem; /* mt-2 */
// //   background-color: #f7fafc; /* bg-gray-100 */
// //   position: relative;

// //   @media (max-width: 640px) {
// //     width: 90%; /* Adjust width for smaller screens */
// //     height: 70vh; /* Adjust height for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     width: 95%; /* Further adjust width for very small screens */
// //     height: 60vh; /* Further adjust height for very small screens */
// //   }
// // `;

// // const BlueBox = styled.div`
// //   width: 80%; /* w-4/5 */
// //   background-color: #3b82f6; /* bg-custom-blue */
// //   padding: 1.25rem; /* p-5 */
// //   border-radius: 0.5rem; /* rounded-lg */
// //   color: #fff;
// //   text-align: center;
// //   font-family: "Inter", sans-serif;

// //   @media (max-width: 640px) {
// //     width: 90%; /* Adjust width for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     width: 95%; /* Further adjust width for very small screens */
// //   }
// // `;

// // const FormContainer = styled.div`
// //   width: 80%; /* w-4/5 */
// //   background-color: #fff;
// //   padding: 1.25rem; /* p-5 */
// //   border-radius: 0.5rem; /* rounded-lg */
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
// //   margin-top: 1.25rem; /* mt-5 */
// //   transition: all 0.5s ease-in-out; /* transition duration-500 ease-in-out */
// //   transform: translateY(0);

// //   @media (max-width: 640px) {
// //     width: 90%; /* Adjust width for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     width: 95%; /* Further adjust width for very small screens */
// //   }
// // `;

// // const Input = styled.input`
// //   width: 100%;
// //   padding: 0.5rem; /* p-2 */
// //   border: 2px solid #d1d5db; /* border-2 border-gray-300 */
// //   border-radius: 0.375rem; /* rounded-md */
// //   margin-top: 0.5rem; /* mt-2 */
// // `;

// // const Button = styled.button`
// //   width: 100%;
// //   margin-top: 1.25rem; /* mt-5 */
// //   padding: 0.5rem; /* p-2 */
// //   background-color: #3b82f6; /* bg-custom-blue */
// //   color: #fff;
// //   border-radius: 0.375rem; /* rounded-md */
// // `;

// // const Recaptcha = styled.div`
// //   margin-top: 1rem;

// //   @media (max-width: 640px) {
// //     margin-top: 0.75rem; /* Adjust margin for smaller screens */
// //   }

// //   @media (max-width: 360px) {
// //     margin-top: 0.5rem; /* Further adjust margin for very small screens */
// //   }
// // `;

// // //getAuth().settings.appVerificationDisabledForTesting = true;

// // const db = getFirestore();

// // export const Auth = () => {
// //   const [phone, setPhone] = useState("+91");
// //   const [hasFilled, setHasFilled] = useState(false);
// //   const [otp, setOtp] = useState("");
// //   const [user, setUser] = useState(null);

// //   let navigate = useNavigate();

// //   useEffect(() => {
// //     window.recaptchaVerifier = new RecaptchaVerifier(
// //       "recaptcha",
// //       {
// //         size: "invisible",
// //         callback: (response) => {
// //           // reCAPTCHA solved allow signInWithPhoneNumber.
// //         },
// //       },
// //       auth
// //     );
// //   }, []);

// //   const handleSend = (event) => {
// //     event.preventDefault();
// //     setHasFilled(true);
// //     let appVerifier = window.recaptchaVerifier;
// //     // try {
// //       getAuth().settings.appVerificationDisabledForTesting = false;

// //       signInWithPhoneNumber(auth, phone, appVerifier)
// //         .then((confirmationResult) => {
// //           window.confirmationResult = confirmationResult;
// //         })
// //         .catch((error) => {
// //           console.error(error);
// //           alert("An error occurred while sending the OTP: " + error.message);
// //         });
// //     // } catch (error) {
// //     //   console.error(error);
// //     //   alert("An error occurred while initializing reCAPTCHA: " + error.message);
// //     // }
// //   };

// //   const verifyOtp = async (event) => {
// //     let otp = event.target.value;
// //     setOtp(otp);

// //     if (otp.length === 6) {
// //       let confirmationResult = window.confirmationResult;
// //       confirmationResult
// //         .confirm(otp)
// //         .then(async (result) => {
// //           let user = result.user;
// //           console.log(user);
// //           // alert('User signed in successfully');

// //           const docRef = doc(db, "users", user.uid);
// //           const docSnap = await getDoc(docRef);

// //           if (docSnap.exists()) {
// //             setUser(docSnap.data());
// //           } else {
// //             console.log("No such document!");
// //             await setDoc(docRef, {
// //               name: "",
// //               city: "",
// //               createdAt: new Date().toISOString(),
// //               expireAt: null,
// //               fcmTokens: null,
// //               id: user.uid,
// //               membershipId: null,
// //               mobile: user.phoneNumber,
// //             });
// //             setUser({ name: "", city: "", id: user.uid });
// //           }

// //           navigate("/auth-success");
// //         })
// //         .catch((error) => {
// //           alert("User couldn't sign in (bad verification code?)");
// //         });
// //     }
// //   };

// //   if (!hasFilled) {
// //     return (
// //       <Container>
// //         <Header className="dark:bg-gray-800">
// //           <HeaderContent>
// //             <div className="flex justify-start items-center">
// //               <Logo src={logo} alt="Thirdeye" />
// //             </div>
// //           </HeaderContent>
// //         </Header>

// //         <MainContent>
// //           <BlueBox>
// //             <h2>Register</h2>
// //           </BlueBox>
// //           <FormContainer>
// //             <form onSubmit={handleSend}>
// //               <Input
// //                 type="text"
// //                 placeholder="Phone Number"
// //                 value={phone}
// //                 onChange={(event) => setPhone(event.target.value)}
// //               />
// //               <Button type="submit">SEND OTP</Button>
// //             </form>
// //           </FormContainer>
// //           <Recaptcha id="recaptcha"></Recaptcha>
// //         </MainContent>
// //       </Container>
// //     );
// //   } else {
// //     return (
// //       <Container>
// //         <Header>
// //           <HeaderContent>
// //             <Logo src={logo} alt="Thirdeye"/>
// //           </HeaderContent>
// //         </Header>

// //         <MainContent>
// //           <BlueBox>
// //             <h2>Register</h2>
// //           </BlueBox>
// //           <FormContainer>
// //             <form onSubmit={handleSend}>
// //               <Input
// //                 type="text"
// //                 placeholder="Phone Number"
// //                 value={phone}
// //                 onChange={(event) => setPhone(event.target.value)}
// //               />
// //               <Input
// //                 type="text"
// //                 placeholder="OTP"
// //                 value={otp}
// //                 onChange={verifyOtp}
// //                 style={{ marginTop: "1.25rem" }} // Adjust for OTP input
// //               />
// //               <Button type="submit">Verify OTP</Button>
// //             </form>
// //           </FormContainer>
// //           <Recaptcha id="recaptcha" />
// //         </MainContent>
// //       </Container>
// //     );
// //   }
// // };
import { useState, useEffect, useRef } from "react";
import { auth } from "../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logo from "../images/thirdeye.png";
import styled, { keyframes } from "styled-components";
import Backgroundimage from "../images/RewardBg.jpeg";
import "./auth.css";

// Your styled components...
const Container = styled.div`
  font-family: sans-serif;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Backgroundimage});  // Apply background image
    background-color:white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 0.4; // Set the opacity to 40%
    z-index: -1; // Ensure the background is behind the content
  }
  }
`;

const Header = styled.header`
  background-color: #fff; /* bg-white */
  border-bottom: 1px solid #e2e8f0; /* border-gray-200 */
  padding: 0.625rem 1rem; /* px-4 py-2.5 */
  poition: fixed;
  gap: 10px;
  border-radius:10px;
  @media (min-width: 1024px) {
    padding: 0.625rem 1.5rem; /* lg:px-6 */
  }
  &.dark {
    background-color: #2d3748; /* dark:bg-gray-800 */
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 2.75rem; /* h-11 */
  width: auto;
  align-self: center;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Adjust height for better view on smaller screens */
  max-width: 90%; /* Ensure it doesn't overflow on smaller screens */
  width: 24rem; /* Default width */
  margin: 0 auto;
  margin-top: 0; /* mt-2 */
  padding: 0;
  //background-color: #f7fafc; /* bg-gray-100 */
  position: relative;
  // opacity: 100;

  @media (max-width: 640px) {
    width: 90%; /* Adjust width for smaller screens */
    height: 70vh; /* Adjust height for smaller screens */
  }

  @media (max-width: 360px) {
    width: 95%; /* Further adjust width for very small screens */
    height: 60vh; /* Further adjust height for very small screens */
  }
`;

const BlueBox = styled.div`
  width: 80%; /* w-4/5 */
  background-color: #3b82f6; /* bg-custom-blue */
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 640px) {
    width: 90%; /* Adjust width for smaller screens */
  }

  @media (max-width: 360px) {
    width: 95%; /* Further adjust width for very small screens */
  }
`;

const FormContainer = styled.div`
  width: 80%; /* w-4/5 */
  background-color: #fff;
  padding: 1.25rem; /* p-5 */
  border-radius: 0.5rem; /* rounded-lg */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
  margin-top: 1.25rem; /* mt-5 */
  transition: all 0.5s ease-in-out; /* transition duration-500 ease-in-out */
  transform: translateY(0);

  @media (max-width: 640px) {
    width: 90%; /* Adjust width for smaller screens */
  }

  @media (max-width: 360px) {
    width: 95%; /* Further adjust width for very small screens */
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem; /* p-2 */
  border: 2px solid #d1d5db; /* border-2 border-gray-300 */
  border-radius: 0.375rem; /* rounded-md */
  margin-top: 0.5rem; /* mt-2 */
`;

const Button = styled.button`
  width: 100%;
  margin-top: 1.25rem; /* mt-5 */
  padding: 0.5rem; /* p-2 */
  background-color: #3b82f6; /* bg-custom-blue */
  color: #fff;
  border-radius: 0.375rem; /* rounded-md */
`;

const Recaptcha = styled.div`
  margin-top: 1rem;

  @media (max-width: 640px) {
    margin-top: 0.75rem; /* Adjust margin for smaller screens */
  }

  @media (max-width: 360px) {
    margin-top: 0.5rem; /* Further adjust margin for very small screens */
  }
`;

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkingText = styled.p`
  font-weight: bold;
`;

const h1Style = {
  textAlign: "center",
  //fontSize: '48px',
  fontWeight: 700,
  //textTransform: 'uppercase',
  color: " #3b82f6",
  // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  letterSpacing: "2px",
  marginBottom: "20px",
  // position:'absolute',
  top: "0",
  "@media  (max-width: 1440px)": {
    // Desktop
    fontSize: "48px",
  },
  "@media  (max-width: 1024px)": {
    // Laptop
    fontSize: "36px",
  },
  "@media   (max-width: 768px)": {
    // Tablet
    fontSize: "28px",
  },
  "@media  (max-width: 480px)": {
    // Mobile
    fontSize: "22px",
  },
  "@media   (max-width: 320px)": {
    // Mobile
    fontSize: "16px",
  },
};

const db = getFirestore();
// const auth = getAuth();

export const Auth2 = ({setRewardAuthNewUser,setRewardAlertFormOpen}) => {
  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState(null);
  const recaptchaVerifierRef = useRef(null);
  const [isOtpSending, setIsOtpSending] = useState(false);
  const [timer, setTimer] = useState(30); // Initialize 30 seconds timer
  const [isTimerActive, setIsTimerActive] = useState(false); // Control timer state
  let navigate = useNavigate();
  const selectedProject = localStorage.getItem("selectedProject");
  const senderUserId = localStorage.getItem("senderUserId") || null;
  const trackProject = localStorage.getItem("trackProject")|| null;

  useEffect(() => {
    let countdown;
    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => clearInterval(countdown);
  }, [isTimerActive, timer]);

  /* const updateLANDs = async (user) => {
    if (senderUserId && trackProject) {
      try {
        if (user?.uid === senderUserId) {
          console.log("Referred user cannot be the sender. Exiting.");
          return; 
        }
        // Query Firestore for matching documents

        const q = query(
          collection(db, "landRewards"),
          where("userId", "==", senderUserId),
          where("referredUserId", "==", user?.uid), // added in last
          where("projectId", "==", trackProject),
          where("activity", "==", "REDEEM"),
          orderBy("createdAt", "asc")
        );

        // Get the matching documents
        const querySnapshot = await getDocs(q);

        // Store matching documents in an array
        const matchingDocs = [];
        querySnapshot.forEach((docSnap) => {
          matchingDocs.push({ id: docSnap.id, ...docSnap.data() });
        });

        if (matchingDocs.length > 0) {
          // Get the latest document's createdAt (Firestore Timestamp)
          const latestCreatedAt =
            matchingDocs[matchingDocs.length - 1].createdAt;

          // Convert Firestore Timestamp to JavaScript Date
          const latestCreatedAtDate = new Date(
            latestCreatedAt.seconds * 1000 +
              latestCreatedAt.nanoseconds / 1000000
          );

          // Get the current time
          const now = new Date();

          // Calculate the difference in milliseconds
          const timeDifferenceMillis = Math.abs(now - latestCreatedAtDate);

          // Convert the time difference to minutes
          const minutesDifference = timeDifferenceMillis / (1000 * 60); // 1000 ms * 60 seconds
          //24hrs *60 mins=1440mins
          if (Number(minutesDifference) > 1440) {
            // Add a new document to the `landRewards` collection
            await addDoc(collection(db, "landRewards"), {
              LANDs: 1,
              activity: "REDEEM",
              createdAt: new Date(),
              projectId: trackProject,
              referralLink: "",
              referredUserId: user?.uid || "",
              userId: senderUserId,
            });
            console.log("New document created in landRewards.");

            // Update `realProperty` collection
            const realPropertyQuery = query(
              collection(db, "realProperty"),
              where("propertyId", "==", trackProject)
            );

            const realPropertySnapshot = await getDocs(realPropertyQuery);

            if (!realPropertySnapshot.empty) {
              const realPropertyDoc = realPropertySnapshot.docs[0];
              const currentLANDs = realPropertyDoc.data().LANDs || 0;

              // Update the LANDs in `realProperty`
              await updateDoc(realPropertyDoc.ref, {
                LANDs: Number(currentLANDs) - 1,
              });
              console.log("Updated LANDs in realProperty.");
            }

            // Clear localStorage items
            localStorage.removeItem("senderUserId");
            localStorage.removeItem("trackProject");
          } else {
            console.log(
              "You can only redeem LANDs once every 24 hours. Please try again later."
            );
          }
        } else {
          // Add a new document to the `landRewards` collection
          await addDoc(collection(db, "landRewards"), {
            LANDs: 1,
            activity: "REDEEM",
            createdAt: new Date(),
            projectId: trackProject,
            referralLink: "",
            referredUserId: user?.uid || "",
            userId: senderUserId,
          });
          console.log("New document created in landRewards.");
          // Update `realProperty` collection
          const realPropertyQuery = query(
            collection(db, "realProperty"),
            where("propertyId", "==", trackProject)
          );

          const realPropertySnapshot = await getDocs(realPropertyQuery);

          if (!realPropertySnapshot.empty) {
            const realPropertyDoc = realPropertySnapshot.docs[0];
            const currentLANDs = realPropertyDoc.data().LANDs || 0;

            // Update the LANDs in `realProperty`
            await updateDoc(realPropertyDoc.ref, {
              LANDs: currentLANDs - 1,
            });
            console.log("Updated LANDs in realProperty.");
          }

          // Clear localStorage items
          localStorage.removeItem("senderUserId");
          localStorage.removeItem("trackProject");
        }
      } catch (error) {
        console.error("Error updating LANDs:", error);
      }
    }
  }; */

 


  const getDateFromTimestamp = (timestamp) => {
     return new Date(
       timestamp.seconds * 1000 +
       timestamp.nanoseconds / 1000000
     );
   };
   
   const claimupdateLANDs = async (user) => {
     try {
       if (!user) {
         console.error("No user logged in.");
         return;
       }
   
       const isReferredUser = senderUserId !== "null" && trackProject !== "null";
       const isSelfClaim = senderUserId === "null" || trackProject === "null";
   
       if (isReferredUser) {
         console.log("Referred User Flow");
   
         if (user.uid === senderUserId) {
           console.log("Referred user cannot be the sender. Exiting.");
           return;
         }
   
         await claimaddNewRewardAndUpdateRealProperty(user)
   
        
   
       } else if (isSelfClaim) {
         console.log("Self Claim Flow");
   
         // Query for self-claimed rewards
         const queryRef = query(
           collection(db, "landRewards"),
           where("userId", "==", user.uid),
           where("activity", "==", "CLAIM"),
           where("projectId", "==", selectedProject),
           
           orderBy("createdAt", "asc")
         );
   
         const querySnapshot = await getDocs(queryRef);
         const matchingDocs = querySnapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));
   
         if (matchingDocs.length > 0) {
           const latestCreatedAt = getDateFromTimestamp(matchingDocs[matchingDocs.length - 1].createdAt);
           const now = new Date();
           const minutesDifference = Math.abs(now - latestCreatedAt) / (1000 * 60);
   
           if (minutesDifference > 1440) {
             console.log("It is after 24 hours. Adding reward.");
             await claimaddNewRewardAndUpdateRealPropertytoLoggedInUser(user);
           } else {
             alert("You've already claimed for the day!");
           }
         } else {
           await claimaddNewRewardAndUpdateRealPropertytoLoggedInUser(user);
         }
       } else {
         console.error("Invalid flow. No action taken.");
       }
   
     } catch (error) {
       console.error("Error updating LANDs:", error);
     }
   };
   
     
       const claimaddNewRewardAndUpdateRealPropertytoLoggedInUser=async(user)=>{
         try {
           // Add a new document to the `landRewards` collection
           await addDoc(collection(db, "landRewards"), {
             LANDs: 1,
             activity: "CLAIM",
             createdAt: new Date(),
             projectId: selectedProject,
             referralLink: "",
             referredUserId:"",
             userId: user?.uid,
           });
     
           
           alert("Congrats! you've got LAND.");
       
           // Update `realProperty` collection
           const realPropertyQuery = query(
             collection(db, "realProperty"),
             where("propertyId", "==", selectedProject)
           );
       
           const realPropertySnapshot = await getDocs(realPropertyQuery);
       
           if (!realPropertySnapshot.empty) {
             const realPropertyDoc = realPropertySnapshot.docs[0];
             const currentLANDs = realPropertyDoc.data().LANDs;
       
             if ( Number(currentLANDs) > 0) {
               await updateDoc(realPropertyDoc.ref, {
                 LANDs: Number(currentLANDs) - 1,
               });
               console.log("Updated LANDs in realProperty.");
             } else {
               console.warn("LANDs value is not a valid number or is already zero.");
             }
           } else {
             console.error("No matching document found in realProperty.");
           }
       
         
         } catch (error) {
           console.error("Error adding new reward or updating realProperty:", error);
         }
       }
     
     
       const claimaddNewRewardAndUpdateRealProperty = async (user) => {
         try {
           // Query landRewards for senderUserId
           const landRewardsQuery1 = query(
             collection(db, "landRewards"),
             where("userId", "==", senderUserId),
             where("activity", "==", "CLAIM"),
             where("projectId", "==", trackProject),
             orderBy("createdAt", "asc") // To get the oldest to the newest
           );
           const landRewardsSnapshot1 = await getDocs(landRewardsQuery1);
       
           // Query landRewards for user
           const landRewardsQuery2 = query(
             collection(db, "landRewards"),
             where("userId", "==", user?.uid),
             where("activity", "==", "CLAIM"),
             where("projectId", "==", trackProject),
             orderBy("createdAt", "asc")
           );
           const landRewardsSnapshot2 = await getDocs(landRewardsQuery2);
       
           // Handle 24-hour restriction for senderUserId
           if (!landRewardsSnapshot1.empty) {
             const senderMatchingDocs = landRewardsSnapshot1.docs.map((docSnap) => ({
               id: docSnap.id,
               ...docSnap.data(),
             }));
             const latestCreatedAt = senderMatchingDocs[senderMatchingDocs.length - 1].createdAt.toDate();
             const now = new Date();
             const minutesDifference = Math.abs(now - latestCreatedAt) / (1000 * 60);
       
             if (minutesDifference <= 1440) {
               console.log("A reward was claimed less than 24 hours ago. Please try again later.");
               
             }
             else{
               await addDoc(collection(db, "landRewards"), {
                 LANDs: 1,
                 activity: "CLAIM",
                 createdAt: new Date(),
                 projectId: trackProject,
                 referralLink: "",
                 referredUserId: user?.uid || "",
                 userId: senderUserId,
               });
               console.log("New document created in landRewards for senderUserId.");
               await updateRealPropertyLANDs(trackProject, senderUserId);
   
             }
           }
       
           // Handle 24-hour restriction for user
           if (!landRewardsSnapshot2.empty) {
             const userMatchingDocs = landRewardsSnapshot2.docs.map((docSnap) => ({
               id: docSnap.id,
               ...docSnap.data(),
             }));
             const latestCreatedAt = userMatchingDocs[userMatchingDocs.length - 1].createdAt.toDate();
             const now = new Date();
             const minutesDifference = Math.abs(now - latestCreatedAt) / (1000 * 60);
       
             if (minutesDifference <= 1440) {
               alert("You've already claimed for the day.");
               
             }
             else{
               await addDoc(collection(db, "landRewards"), {
                 LANDs: 1,
                 activity: "CLAIM",
                 createdAt: new Date(),
                 projectId: trackProject,
                 referralLink: "",
                 referredUserId: senderUserId || "",
                 userId: user?.uid,
               });
               alert("Congrats! You've got LAND.");
               await updateRealPropertyLANDs(trackProject, user?.uid);
             }
           }
       
           // Add a new reward for senderUserId if conditions are met
           if (landRewardsSnapshot1.empty) {
             await addDoc(collection(db, "landRewards"), {
               LANDs: 1,
               activity: "CLAIM",
               createdAt: new Date(),
               projectId: trackProject,
               referralLink: "",
               referredUserId: user?.uid || "",
               userId: senderUserId,
             });
             console.log("New document created in landRewards for senderUserId.");
             await updateRealPropertyLANDs(trackProject, senderUserId);
           }
       
           // Add a new reward for user if conditions are met
           if (landRewardsSnapshot2.empty) {
             await addDoc(collection(db, "landRewards"), {
               LANDs: 1,
               activity: "CLAIM",
               createdAt: new Date(),
               projectId: trackProject,
               referralLink: "",
               referredUserId: senderUserId || "",
               userId: user?.uid,
             });
             alert("Congrats! You've got LAND.");
             await updateRealPropertyLANDs(trackProject, user?.uid);
           }
       
           // Clear localStorage items
           localStorage.removeItem("senderUserId");
           localStorage.removeItem("trackProject");
         } catch (error) {
           console.error("Error adding new reward or updating realProperty:", error);
         }
       };
  
       // Helper function to update LANDs in realProperty
           const updateRealPropertyLANDs = async (propertyId, userId) => {
             try {
               const realPropertyQuery = query(
                 collection(db, "realProperty"),
                 where("propertyId", "==", propertyId)
               );
               const realPropertySnapshot = await getDocs(realPropertyQuery);
           
               if (!realPropertySnapshot.empty) {
                 const realPropertyDoc = realPropertySnapshot.docs[0];
                 const currentLANDs = realPropertyDoc.data().LANDs;
           
                 if (Number(currentLANDs) > 0) {
                   await updateDoc(realPropertyDoc.ref, {
                     LANDs: Number(currentLANDs) - 1,
                   });
                   console.log(`Updated LANDs in realProperty for ${userId}.`);
                 } else {
                   console.warn("LANDs value is not a valid number or is already zero.");
                 }
               } else {
                 console.error(`No matching document found in realProperty for ${userId}.`);
               }
             } catch (error) {
               console.error("Error updating LANDs in realProperty:", error);
             }
           };

  function checkverify() {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
        "recaptcha",
        {
          size: "invisible",
          callback: (response) => {
            handleSend();
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
          },
        },
        auth
      );
    }
  }

  const handleSend = async (event) => {
    event.preventDefault();
    if (isOtpSending || isTimerActive) return;

    setIsOtpSending(true);
    setHasFilled(true);

    checkverify();
    const appVerifier = recaptchaVerifierRef.current;

    try {
      await signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setTimer(30); // Reset timer to 30 seconds
          setIsTimerActive(true); // Start the timer
        })
        .catch((error) => {
          console.error(error);
          alert("An error occurred while sending the Passcode: " + error.message);
        });
    } catch (error) {
      console.error(error);
      alert("An error occurred while initializing reCAPTCHA: " + error.message);
    } finally {
      setIsOtpSending(false);
    }
  };

  const verifyOtp = async (event) => {
    event.preventDefault();
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then(async (result) => {
          let user = result.user;
          console.log(user);

          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUser(docSnap.data());
            await claimupdateLANDs(user);
            setRewardAlertFormOpen(true)
            
          } else {
            console.log("No such document!");
            await setDoc(docRef, {
              name: "",
              city: "",
              createdAt: new Date().toISOString(),
              expireAt: null,
              fcmTokens: null,
              id: user.uid,
              membershipId: null,
              mobile: user.phoneNumber,
            });
            setUser({ name: "", city: "", id: user.uid });
            /* navigate("/auth-success"); */
            await claimupdateLANDs(user);
           setRewardAuthNewUser(true)
            
          }
        })
        .catch((error) => {
          console.error("Verification failed: ", error);
          alert("User couldn't sign in (bad verification code?)");
        });
    }
  };

  const handleResendOtp = async () => {
    await handleSend(); // Resend the OTP
    setTimer(30); // Reset the timer
    setIsTimerActive(true); // Restart the timer
  };
  if (!selectedProject) {
    alert("No Project Selected");
    return;
  }

  if (!hasFilled) {
    return (
      <Container>
       {/*  <Header className="dark:bg-gray-800">
          <HeaderContent>
            <div className="flex justify-start items-center">
              <Logo src={logo} alt="Thirdeye" />
            </div>
          </HeaderContent>
        </Header> */}
        <div style={{display:"flex", flexDirection:"column"}}>
        <MainContent>
          <div style={{ display: "none" }}>
            <h2
              className="mb-4 text-center font-bold font-sans-serif "
              style={{ color: "#3b82f6" }}
            >
              YOU
            </h2>

            <div className="flex justify-center">
              <ul
                className="list-disc font-sans-serif"
                style={{
                  marginLeft: "45px",
                  fontWeight: "inherit",
                  color: "#3b82f6",
                }}
              >
                <li style={{ color: "#3b82f6" }}>
                  Want to raise funds without selling your entire property?
                </li>
                <li style={{ color: "#3b82f6" }}>
                  Want to invest only a few rupees in real estate?
                </li>
              </ul>
            </div>
            <BlinkingText
              className="mt-4"
              style={{ textAlign: "center", color: "#3b82f6" }}
            >
              The solution is here!
            </BlinkingText>
          </div>
          <div style={h1Style}>
            {/* <h1 style={{ color: "black" }}>
              Share the link with your social media contacts and earn LANDs cash
              it out whenever you want.
            </h1> */}
          </div>

          <BlueBox>
            <h2>Enter Mobile Number</h2>
          </BlueBox>
          <FormContainer>
            <form onSubmit={handleSend}>
              <Input
                type="text"
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
              <Button type="submit" disabled={isOtpSending || isTimerActive}>
                {isOtpSending ? "Sending..." : "Get Passcode"}
              </Button>
            </form>
          </FormContainer>
          <Recaptcha id="recaptcha"></Recaptcha>
        </MainContent>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px",cursor:"pointer" }}>
          <a
            href="https://thirdeye.app/thirdeye-app-privacy-policy.html"
            target="_blank"
            className="privacy-link"
          >
            Privacy Policy
          </a>
          <Link to="/faq" className="privacy-link-2">
            FAQ
          </Link>
          <p
            className="privacy-link-2"
            onClick={() => {
              window.open("https://youtu.be/oVV3E93nRKY", "_blank");
            }}
          >
            Video
          </p>
        </div>
        </div>

      </Container>
    );
  } else {
    return (
      <Container>
         {/* <Header>
          <HeaderContent>
             <Logo src={logo} alt="Thirdeye" /> 
           
          </HeaderContent>
        </Header>  */}
        <div style={{display:"flex", flexDirection:"column"}}>
        <MainContent>
          <div style={{ display: "none" }}>
            <h2 className="mb-4 text-center font-bold font-sans-serif">YOU</h2>

            <div className="flex justify-center">
              <ul
                className="list-disc font-sans-serif"
                style={{ marginLeft: "45px", fontWeight: "inherit" }}
              >
                <li>
                  Want to get some money without selling your entire property?
                </li>
                <li>Want to invest only a few rupees in real estate?</li>
              </ul>
            </div>
            <BlinkingText className="mt-4" style={{ textAlign: "center" }}>
              The solution is here!
            </BlinkingText>
          </div>
          <div style={h1Style}>
           {/*  <h1 style={{ color: "black" }}>
              Share the link with your social media contacts and earn LANDs cash
              it out whenever you want
            </h1> */}
          </div>

          <BlueBox>
            <h2>Enter Mobile Number</h2>
          </BlueBox>
          <FormContainer>
            <form>
              <Input
                type="text"
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
              <Input
                type="text"
                placeholder="Passcode"
                value={otp}
                onChange={verifyOtp}
                style={{ marginTop: "1.25rem" }}
              />
              <Button
                type="submit"
                onClick={verifyOtp}
                disabled={otp.length !== 6}
              >
                Verify Passcode
              </Button>
              {timer === 0 && (
                <Button type="submit" onSubmit={handleResendOtp}>
                  Resend Passcode
                </Button>
              )}
              {isTimerActive && <p>Resend Passcode in {timer} seconds</p>}
            </form>
          </FormContainer>
          <Recaptcha id="recaptcha" />
        </MainContent>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px",cursor:"pointer" }}>
          <a
            href="https://thirdeye.app/thirdeye-app-privacy-policy.html"
            target="_blank"
            style={{ textDecoration: "none" }}
            className="privacy-link-2"
          >
            Privacy Policy
          </a>
          <Link to="/faq" className="privacy-link-2">
            FAQ
          </Link>
          <p
            className="privacy-link-2"
            onClick={() => {
              window.open("https://youtu.be/oVV3E93nRKY", "_blank");
            }}
          >
            Video
          </p>
        </div>
        </div>
      </Container>
    );
  }
};
