import React, { useEffect, useState } from "react";
import "./FreePromotion.css";
import { Logo } from "../area";
import { useNavigate } from "react-router-dom";
import { Header, HeaderContent } from "../Faq.style";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import logo from "../../images/thirdeye.png";

const FreePromotion = () => {
    const navigate=useNavigate()
      const [isLoggedIn, setIsLoggedIn] = useState(false);
      const [user,setUser]=useState(null);
    
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user)
            setIsLoggedIn(true); 
          } else {
            setIsLoggedIn(false); 
          }
        });
    
        return () => unsubscribe();
      }, []);
  return (
    <>
    <Header>
    <HeaderContent>
    <Logo src={logo} alt="Thirdeye" onClick={()=>navigate("/")} />
          {isLoggedIn && (<h3
            className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
            onClick={() => {
              navigate("/rewardcustomerdash");
            }}
          >
            Dashboard
          </h3>)}
    </HeaderContent>
  </Header>
    <div className="freepromo-container">
      <div className="freepromo-header">
        <h1>Promote Your Project for <span className="highlight">FREE</span> on ThirdEye!</h1>
        <p>
          Are you a real estate developer seeking maximum exposure for your projects 
          without the hefty price tag? <strong>ThirdEye</strong> offers you the chance to 
          list and promote your projects <span className="highlight">absolutely free</span> — 
          helping you reach potential buyers effortlessly!
        </p>
      </div>

      <div className="freepromo-why">
        <h2>Why Choose ThirdEye?</h2>
        <ul>
          <li>
            <strong>✅ Free Promotion:</strong> Showcase your projects at no cost and attract a broader audience of buyers and investors.
          </li>
          <li>
            <strong>🚀 Cutting-Edge Technology:</strong> Utilize advanced <span className="highlight">AR/VR smart layouts</span> to bring your properties to life.
          </li>
          <li>
            <strong>🏆 LAND Token Rewards:</strong> Offer visitors and buyers exciting rewards, boosting engagement and interest.
          </li>
          <li>
            <strong>🔗 Fractional Ownership:</strong> Open up new opportunities for buyers with diverse budgets through fractional sales.
          </li>
        </ul>
      </div>

      <div className="freepromo-steps">
        <h2>How to Promote Your Project for FREE?</h2>
        <ol>
          <li><strong>1. List Your Project:</strong> Upload your project details on the ThirdEye platform — completely free!</li>
          <li><strong>2. Attract Buyers:</strong> Leverage our tools and token rewards to capture attention and drive traffic.</li>
          <li><strong>3. Boost Sales:</strong> Reach the right audience and close deals faster with no upfront costs.</li>
        </ol>
      </div>

      <div className="freepromo-included">
        <h2>What's Included in Free Promotion?</h2>
        <ul>
          <li>✅ Project Listing on the ThirdEye Platform</li>
          <li>✅ Access to LAND Token Rewards System</li>
          <li>✅ Engagement Tools for Better Visibility</li>
        </ul>
      </div>

      <div className="freepromo-cta">
        <h2>Start Promoting Today!</h2>
        <p>Don’t miss this opportunity to showcase your project and connect with buyers — without spending a dime!</p>
        <a className="cta-button" onClick={()=>navigate("/auth")}>🚀 Get Started Now</a>
      </div>
    </div>
    </>
  );
};

export default FreePromotion;
