import React, { useEffect, useState } from "react";
import "./Details.css";
import { Header } from "./dashboard";
import logo from "../images/thirdeye.png";
import { Logo } from "./area";
import { HeaderContent } from "./Faq.style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FaRupeeSign, FaTachometerAlt, FaTimes, FaTimesCircle, FaWindowClose } from "react-icons/fa";

import img01 from "../images/AK1.jpeg";
import img02 from "../images/AK2.jpeg";
import img03 from "../images/AK3.jpeg";
import img04 from "../images/AK4.jpeg";
import img05 from "../images/AparnaKoheda.png"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Auth2 } from "./auth2";
import { Auth } from "./auth";
import AuthSuccess from "./authsucc";
import AlertComponent from "./alertcomponent";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import sample from "../images/sampleimg.jpg";
import cmg from "../images/commingsoon.jpg";
import { addDoc, collection, getDoc, getDocs, getFirestore, orderBy, query, updateDoc, where } from "firebase/firestore";

const DynamicDetails = () => {

 


  // List of images for the carousel
  const [images,setImages]=useState([])
  const [ crowdSaleDoc,setCrowdSaleDoc]=useState([])

  const [authFormOpen, setAuthFormOpen] = useState(false);
  const navigate = useNavigate();

  const [rewardFormOpen, setRewardFormOpen] = useState(false);
  const [authNewUser, setauthNewUser] = useState(false);
  const [alertFormOpen, setAlertFormOpen] = useState(false);
  const [propertyDoc,setPropertyDoc]=useState(null)
  const db = getFirestore();

  const [rewardAuthNewUser, setRewardAuthNewUser] = useState(false);
  const [rewardAlertFormOpen, setRewardAlertFormOpen] = useState(false);
  const [propertyDataAvailable,setPropertyDataAvailable]=useState(null);

  const selectedProject = localStorage.getItem("selectedProject") || null;
  const senderUserId = localStorage.getItem("senderUserId") || null;
  const trackProject = localStorage.getItem("trackProject") || null;

  const { sanitizedProjectName } = useParams(); // Fetch dynamic segment
  const { search } = useLocation(); // Fetch query parameters
  const decodedTitle = sanitizedProjectName.replace(/-/g, ' ');
  const [user,setUser]=useState(null)

  const queryParams = new URLSearchParams(search);
  const propId = queryParams.get("propId");
  const userId = queryParams.get("userId");

  

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        setIsLoggedIn(true); 
      } else {
        setIsLoggedIn(false); 
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem("senderUserId", userId);
    /* localStorage.setItem("selectedProject", propId); */
    localStorage.setItem("trackProject", propId);

    console.log("checking senderUserId adn trackproject",userId,propId)
  }, []);



  const fetchPropertyDoc=async()=>{
    const db = getFirestore();
  const propertyRef = collection(db, 'realProperty');
  const q = query(propertyRef, where('propertyId', '==', selectedProject));

  try {
    const querySnapshot = await getDocs(q); // Get documents that match the query
    if (!querySnapshot.empty) {
      // Assuming you expect only one document
      const docData = querySnapshot.docs[0].data();
      setImages([docData?.Image1Copy || "", docData?.Image2Copy || "",docData?.Image3Copy || "",docData?.Image4Copy || ""])
      setPropertyDoc(docData); // Set the state with the document data
    } else {
      console.log('No matching documents found.');
      setPropertyDoc(null); // Set state to null if no document is found
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
    setPropertyDoc(null); // Handle error by setting the state to null
  }
  }
  useEffect(()=>{
      fetchPropertyDoc()
  },[selectedProject])


  //fetch crowd sale

   const fetchCrowdSale = async () => {
      try {
        const q = query(collection(db, "crowdSale"), where('propertyId', '==', selectedProject));
        const querySnapshot = await getDocs(q);
     
        const crowdsale = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
       
     
       setCrowdSaleDoc(crowdsale)
      } catch (error) {
        console.error("Error fetching land rewards:", error);
      }
    };
  
    useEffect(()=>{
      fetchCrowdSale()
  
    },[db])


 
   // Utility to convert Firestore timestamp to JavaScript Date
 const getDateFromTimestamp = (timestamp) => {
   return new Date(
     timestamp.seconds * 1000 +
     timestamp.nanoseconds / 1000000
   );
 };
 
 const claimupdateLANDs = async (user) => {
   try {
     if (!user) {
       console.error("No user logged in.");
       return;
     }
 
     const isReferredUser = senderUserId !== "null" && trackProject !== "null";
     const isSelfClaim = senderUserId === "null" || trackProject === "null";
 
     if (isReferredUser) {
       console.log("Referred User Flow");
 
       if (user.uid === senderUserId) {
         console.log("Referred user cannot be the sender. Exiting.");
         return;
       }
 
       await claimaddNewRewardAndUpdateRealProperty(user)
 
      
 
     } else if (isSelfClaim) {
       console.log("Self Claim Flow");
 
       // Query for self-claimed rewards
       const queryRef = query(
         collection(db, "landRewards"),
         where("userId", "==", user.uid),
         where("activity", "==", "CLAIM"),
         where("projectId", "==", selectedProject),
         
         orderBy("createdAt", "asc")
       );
 
       const querySnapshot = await getDocs(queryRef);
       const matchingDocs = querySnapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));
 
       if (matchingDocs.length > 0) {
         const latestCreatedAt = getDateFromTimestamp(matchingDocs[matchingDocs.length - 1].createdAt);
         const now = new Date();
         const minutesDifference = Math.abs(now - latestCreatedAt) / (1000 * 60);
 
         if (minutesDifference > 1440) {
           console.log("It is after 24 hours. Adding reward.");
           await claimaddNewRewardAndUpdateRealPropertytoLoggedInUser(user);
         } else {
           alert("You've already claimed for the day!");
         }
       } else {
         await claimaddNewRewardAndUpdateRealPropertytoLoggedInUser(user);
       }
     } else {
       console.error("Invalid flow. No action taken.");
     }
 
   } catch (error) {
     console.error("Error updating LANDs:", error);
   }
 };
 
   
     const claimaddNewRewardAndUpdateRealPropertytoLoggedInUser=async(user)=>{
       try {
         // Add a new document to the `landRewards` collection
         await addDoc(collection(db, "landRewards"), {
           LANDs: 1,
           activity: "CLAIM",
           createdAt: new Date(),
           projectId: selectedProject,
           referralLink: "",
           referredUserId:"",
           userId: user?.uid,
         });
   
         
         alert("Congrats! you've got LAND.");
     
         // Update `realProperty` collection
         const realPropertyQuery = query(
           collection(db, "realProperty"),
           where("propertyId", "==", selectedProject)
         );
     
         const realPropertySnapshot = await getDocs(realPropertyQuery);
     
         if (!realPropertySnapshot.empty) {
           const realPropertyDoc = realPropertySnapshot.docs[0];
           const currentLANDs = realPropertyDoc.data().LANDs;
     
           if ( Number(currentLANDs) > 0) {
             await updateDoc(realPropertyDoc.ref, {
               LANDs: Number(currentLANDs) - 1,
             });
             console.log("Updated LANDs in realProperty.");
           } else {
             console.warn("LANDs value is not a valid number or is already zero.");
           }
         } else {
           console.error("No matching document found in realProperty.");
         }
     
       
       } catch (error) {
         console.error("Error adding new reward or updating realProperty:", error);
       }
     }
   
   
     const claimaddNewRewardAndUpdateRealProperty = async (user) => {
       try {
         // Query landRewards for senderUserId
         const landRewardsQuery1 = query(
           collection(db, "landRewards"),
           where("userId", "==", senderUserId),
           where("activity", "==", "CLAIM"),
           where("projectId", "==", selectedProject),
           orderBy("createdAt", "asc") // To get the oldest to the newest
         );
         const landRewardsSnapshot1 = await getDocs(landRewardsQuery1);
     
         // Query landRewards for user
         const landRewardsQuery2 = query(
           collection(db, "landRewards"),
           where("userId", "==", user?.uid),
           where("activity", "==", "CLAIM"),
           where("projectId", "==", selectedProject),
           orderBy("createdAt", "asc")
         );
         const landRewardsSnapshot2 = await getDocs(landRewardsQuery2);
     
         // Handle 24-hour restriction for senderUserId
         if (!landRewardsSnapshot1.empty) {
           const senderMatchingDocs = landRewardsSnapshot1.docs.map((docSnap) => ({
             id: docSnap.id,
             ...docSnap.data(),
           }));
           const latestCreatedAt = senderMatchingDocs[senderMatchingDocs.length - 1].createdAt.toDate();
           const now = new Date();
           const minutesDifference = Math.abs(now - latestCreatedAt) / (1000 * 60);
     
           if (minutesDifference <= 1440) {
             console.log("A reward was claimed less than 24 hours ago. Please try again later.");
             
           }
           else{
             await addDoc(collection(db, "landRewards"), {
               LANDs: 1,
               activity: "CLAIM",
               createdAt: new Date(),
               projectId: selectedProject,
               referralLink: "",
               referredUserId: user?.uid || "",
               userId: senderUserId,
             });
             console.log("New document created in landRewards for senderUserId.");
             await updateRealPropertyLANDs(selectedProject, senderUserId);
 
           }
         }
     
         // Handle 24-hour restriction for user
         if (!landRewardsSnapshot2.empty) {
           const userMatchingDocs = landRewardsSnapshot2.docs.map((docSnap) => ({
             id: docSnap.id,
             ...docSnap.data(),
           }));
           const latestCreatedAt = userMatchingDocs[userMatchingDocs.length - 1].createdAt.toDate();
           const now = new Date();
           const minutesDifference = Math.abs(now - latestCreatedAt) / (1000 * 60);
     
           if (minutesDifference <= 1440) {
             alert("You've already claimed for the day.");
             
           }
           else{
             await addDoc(collection(db, "landRewards"), {
               LANDs: 1,
               activity: "CLAIM",
               createdAt: new Date(),
               projectId: selectedProject,
               referralLink: "",
               referredUserId: senderUserId || "",
               userId: user?.uid,
             });
             alert("Congrats! You've got LAND.");
             await updateRealPropertyLANDs(selectedProject, user?.uid);
           }
         }
     
         // Add a new reward for senderUserId if conditions are met
         if (landRewardsSnapshot1.empty) {
           await addDoc(collection(db, "landRewards"), {
             LANDs: 1,
             activity: "CLAIM",
             createdAt: new Date(),
             projectId: selectedProject,
             referralLink: "",
             referredUserId: user?.uid || "",
             userId: senderUserId,
           });
           console.log("New document created in landRewards for senderUserId.");
           await updateRealPropertyLANDs(selectedProject, senderUserId);
         }
     
         // Add a new reward for user if conditions are met
         if (landRewardsSnapshot2.empty) {
           await addDoc(collection(db, "landRewards"), {
             LANDs: 1,
             activity: "CLAIM",
             createdAt: new Date(),
             projectId: selectedProject,
             referralLink: "",
             referredUserId: senderUserId || "",
             userId: user?.uid,
           });
           alert("Congrats! You've got LAND.");
           await updateRealPropertyLANDs(selectedProject, user?.uid);
         }
     
         // Clear localStorage items
         localStorage.removeItem("senderUserId");
         localStorage.removeItem("trackProject");
       } catch (error) {
         console.error("Error adding new reward or updating realProperty:", error);
       }
     };

     // Helper function to update LANDs in realProperty
         const updateRealPropertyLANDs = async (propertyId, userId) => {
           try {
             const realPropertyQuery = query(
               collection(db, "realProperty"),
               where("propertyId", "==", propertyId)
             );
             const realPropertySnapshot = await getDocs(realPropertyQuery);
         
             if (!realPropertySnapshot.empty) {
               const realPropertyDoc = realPropertySnapshot.docs[0];
               const currentLANDs = realPropertyDoc.data().LANDs;
         
               if (Number(currentLANDs) > 0) {
                 await updateDoc(realPropertyDoc.ref, {
                   LANDs: Number(currentLANDs) - 1,
                 });
                 console.log(`Updated LANDs in realProperty for ${userId}.`);
               } else {
                 console.warn("LANDs value is not a valid number or is already zero.");
               }
             } else {
               console.error(`No matching document found in realProperty for ${userId}.`);
             }
           } catch (error) {
             console.error("Error updating LANDs in realProperty:", error);
           }
         };
 
      const updateLANDs = async (user) => {
          
         try {
           if (!user) {
             console.error("No user logged in.");
             return;
           }
       
           const isReferredUser = senderUserId !== "null" && trackProject !== "null";
           const isSelfClaim = senderUserId === "null" || trackProject === "null";
       
           if (isReferredUser) {
             console.log("Referred User Flow");
       
             if (user.uid === senderUserId) {
               console.log("Referred user cannot be the sender. Exiting.");
               return;
             }
       
             await addNewRewardAndUpdateRealProperty(user)
 
 
 
           } 
           else if (isSelfClaim) {
             console.log("Self Claim Flow");
       
             // Query for self-claimed rewards
             const queryRef = query(
               collection(db, "landRewards"),
               where("userId", "==", user?.uid),
               where("activity", "==", "ENQUIRE"),
               where("projectId", "==", selectedProject),
               orderBy("createdAt", "asc")
             );
       
             const querySnapshot = await getDocs(queryRef);
             const matchingDocs = querySnapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));
       
             if (matchingDocs.length > 0) {
               alert("you've already sent an enquiry for this project.Click CLAIM LAND if you want to get more rewards");
               return
               
             } else {
               await addNewRewardAndUpdateRealPropertytoLoggedInUser(user);
             }
           } else {
             console.error("Invalid flow. No action taken.");
           }
           
           
 
 
           
         } catch (error) {
           
         }
         
         }
       
         const addNewRewardAndUpdateRealPropertytoLoggedInUser=async(user)=>{
           try {
             const landRewardsQuery = query(
               collection(db, "landRewards"),
               where("userId", "==", user?.uid),
               where("activity", "==", "ENQUIRE"),
               where("projectId", "==", selectedProject)
             );
         
             const landRewardsSnapshot = await getDocs(landRewardsQuery);
         
             if (!landRewardsSnapshot.empty) {
              alert("you've already sent an enquiry for this project.Click CLAIM LAND if you want to get more rewards");
               return; // Exit if reward already exists
             }
     
     
     
             // Add a new document to the `landRewards` collection
             await addDoc(collection(db, "landRewards"), {
               LANDs: 5,
               activity: "ENQUIRE",
               createdAt: new Date(),
               projectId: selectedProject,
               referralLink: "",
               referredUserId:"",
               userId: user?.uid,
             });
       
             
             alert("Congrats! you've got LAND.");
         
             // Update `realProperty` collection
             const realPropertyQuery = query(
               collection(db, "realProperty"),
               where("propertyId", "==", selectedProject)
             );
         
             const realPropertySnapshot = await getDocs(realPropertyQuery);
         
             if (!realPropertySnapshot.empty) {
               const realPropertyDoc = realPropertySnapshot.docs[0];
               const currentLANDs = realPropertyDoc.data().LANDs;
         
               if (Number(currentLANDs) > 0) {
                 await updateDoc(realPropertyDoc.ref, {
                   LANDs: Number(currentLANDs) - 5,
                 });
                 console.log("Updated LANDs in realProperty.");
               } else {
                 console.warn("LANDs value is not a valid number or is already zero.");
               }
             } else {
               console.error("No matching document found in realProperty.");
             }
         
           
           } catch (error) {
             console.error("Error adding new reward or updating realProperty:", error);
           }
         }
       
       
         const addNewRewardAndUpdateRealProperty = async (user) => {
           try {
             // Query landRewards for senderUserId
             const landRewardsQuery1 = query(
               collection(db, "landRewards"),
               where("userId", "==", senderUserId),
               where("activity", "==", "ENQUIRE"),
               where("projectId", "==", selectedProject)
             );
             const landRewardsSnapshot1 = await getDocs(landRewardsQuery1);
         
             // Query landRewards for user
             const landRewardsQuery2 = query(
               collection(db, "landRewards"),
               where("userId", "==", user?.uid),
               where("activity", "==", "ENQUIRE"),
               where("projectId", "==", selectedProject)
             );
             const landRewardsSnapshot2 = await getDocs(landRewardsQuery2);
         
             

             if(!landRewardsSnapshot2.empty){
              alert("you've already sent an enquiry for this project.Click CLAIM LAND if you want to get more rewards");
             }


         
             // Check and handle landRewards for senderUserId
             if (landRewardsSnapshot1.empty) {
               await addDoc(collection(db, "landRewards"), {
                 LANDs: 5,
                 activity: "ENQUIRE",
                 createdAt: new Date(),
                 projectId: selectedProject,
                 referralLink: "",
                 referredUserId: user?.uid || "",
                 userId: senderUserId,
               });
               console.log("New document created in landRewards for senderUserId.");
         
               const realPropertyQuery = query(
                 collection(db, "realProperty"),
                 where("propertyId", "==", selectedProject)
               );
               const realPropertySnapshot = await getDocs(realPropertyQuery);
         
               if (!realPropertySnapshot.empty) {
                 const realPropertyDoc = realPropertySnapshot.docs[0];
                 const currentLANDs = realPropertyDoc.data().LANDs;
         
                 if (Number(currentLANDs) > 0) {
                   await updateDoc(realPropertyDoc.ref, {
                     LANDs: Number(currentLANDs) - 5,
                   });
                   console.log("Updated LANDs in realProperty for senderUserId.");
                 } else {
                   console.warn("LANDs value is not a valid number or is already zero.");
                 }
               } else {
                 console.error("No matching document found in realProperty for senderUserId.");
               }
             }
         
             // Check and handle landRewards for user
             if (landRewardsSnapshot2.empty) {
               await addDoc(collection(db, "landRewards"), {
                 LANDs: 5,
                 activity: "ENQUIRE",
                 createdAt: new Date(),
                 projectId: selectedProject,
                 referralLink: "",
                 referredUserId: senderUserId || "",
                 userId: user?.uid,
               });
               alert("Congrats! you've got LAND.");
         
               const realPropertyQuery = query(
                 collection(db, "realProperty"),
                 where("propertyId", "==", selectedProject)
               );
               const realPropertySnapshot = await getDocs(realPropertyQuery);
         
               if (!realPropertySnapshot.empty) {
                 const realPropertyDoc = realPropertySnapshot.docs[0];
                 const currentLANDs = realPropertyDoc.data().LANDs;
         
                 if (Number(currentLANDs) > 0) {
                   await updateDoc(realPropertyDoc.ref, {
                     LANDs: Number(currentLANDs) - 5,
                   });
                   console.log("Updated LANDs in realProperty for user.");
                 } else {
                   console.warn("LANDs value is not a valid number or is already zero.");
                 }
               } else {
                 console.error("No matching document found in realProperty for user.");
               }
             }
         
             // Clear localStorage items
             localStorage.removeItem("senderUserId");
             localStorage.removeItem("trackProject");
           } catch (error) {
             console.error("Error adding new reward or updating realProperty:", error);
           }
         };


  const fetchAllData = async () => {
    try {
      const realPropertyRef = collection(db, "realProperty");
      
      const querySnapshot = await getDocs(realPropertyRef);
  
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Document ID
        ...doc.data(), // Document data
      }));
      const decodedTitle = sanitizedProjectName.replace(/-/g, ' ');
      console.log(decodedTitle)
      const listing = data.find((item) => item.name === decodedTitle);
  
      setPropertyDataAvailable(listing); 
    } catch (err) {
      console.error("Error fetching property data:", err);
    }
  };
  useEffect(() => {
    fetchAllData()
  }, [db]);



  // State to track the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to handle going to the next image
  const nextImage = () => {
    if(!images[(currentImageIndex + 1) % images.length]){
      return
    }

    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to handle going to the previous image
  const prevImage = () => {
    if(!images[(currentImageIndex- 1 + images.length) % images.length]){
      return
    }
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleClaimLand = async () => {
      try {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            // If the user is logged in, claim the land and alert
            await claimupdateLANDs(user);
           
          } else {
            // If no user is logged in, update state
            setIsLoggedIn(false);
          }
        });
      } catch (error) {
        console.error("Error in claimLand:", error);
      }
    };
    
  
    const handleEnquire=async()=>{
      
      try {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            // If the user is logged in, claim the land and alert
            await updateLANDs(user);
           
          } else {
            // If no user is logged in, update state
            setIsLoggedIn(false);
          }
        });
      } catch (error) {
        console.error("Error in EnquireLand:", error);
      }
  
      
  
    }

 

 /*  if (!propertyDataAvailable) {
    // Redirect to a "Not Found" page if the id doesn't match any listing
    return <Navigate to="/404" replace />;
  }  */

  return (
    <>
      <Header>
        <HeaderContent>
          <Logo src={logo} alt="Thirdeye" onClick={()=>navigate("/")} />
          {isLoggedIn && (<h3
            className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
            onClick={() => {
              navigate("/rewardcustomerdash");
            }}
          >
            Dashboard
          </h3>)}
        </HeaderContent>
      </Header>
      <div className="details-container">
        <div className="details-header">
          <h1 className="details-title">{decodedTitle}</h1>
          <p className="details-location">
          {propertyDoc?.city},{propertyDoc?.state}{" "}
          </p>
        </div>
        <div className="photo-map">
          {/* Image Section */}
          <div className="photo">
            <button
              onClick={prevImage}
              title="previous"
              className="arrow left-arrow"
            >
              <ChevronLeftIcon></ChevronLeftIcon>
            </button>
            <img
              src={images[currentImageIndex]}
              alt="Property"
              className="details-image"
            />
            <button
              onClick={nextImage}
              title="next"
              className="arrow right-arrow"
            >
              <ChevronRightIcon></ChevronRightIcon>
            </button>
          </div>

          {/* Map Section */}
          <div className="map">
            <h1 className="layout">Smart Layout</h1>
            <iframe
              /* src="https://maps.app.goo.gl/YtxUYLEPrBQYMduB6?igu=1"
              title="Smart Layout" */
               src={propertyDoc?.smartlayout}
    title="OpenStreetMap Location"
              allowFullScreen
              style={{ width: "100%", height: "400px", padding: "1px" }}
            />
          </div>
        </div>
        <div className="details-info">
          <div className="details-section">
            <h2 className="details-section-title">Property Details</h2>
            {/* <p className="details-description">
    <strong>Discover the Future of Urban Living at Koheda</strong><br />
    Welcome to our latest real estate development, a sprawling 10-acre project in Koheda, designed to redefine modern living. Nestled amidst nature yet conveniently connected to major urban hubs, this project combines contemporary design with sustainable living practices, offering a lifestyle of comfort, convenience, and community.


<h3><strong>Key Highlights</strong></h3>
<ul>
    <li><strong>Prime Location:</strong>
        <ul>
            <li>Strategically located in <strong>Koheda</strong>, with easy access to highways, schools, healthcare, and shopping centers.</li>
            <li>Close proximity to emerging commercial and industrial zones, ensuring excellent investment potential.</li>
        </ul>
    </li>
   
    <li><strong>Green Living:</strong>
        <ul>
            <li>Over 40% of the area dedicated to lush <strong>landscaping</strong>, parks, and open spaces.</li>
            <li>Incorporates rainwater harvesting, solar lighting, and other eco-friendly technologies.</li>
        </ul>
    </li>
    <li><strong>Modern Amenities:</strong>
        <ul>
            <li>Community clubhouse with a gym, swimming pool, and indoor games.</li>
            <li>Outdoor sports facilities, jogging tracks, and children’s play areas to keep the entire family active and engaged.</li>
            <li>24/7 security with advanced surveillance systems for a safe and serene environment.</li>
        </ul>
    </li>
    <li><strong>Investment Opportunity:</strong>
        <ul>
            <li>Ideal for homebuyers and investors seeking to capitalize on <strong>rapidly growing real estate markets</strong>.</li>
            <li>High appreciation potential due to Koheda’s development as a sought-after residential and commercial destination.</li>
        </ul>
    </li>
</ul>
</p> */}

<div dangerouslySetInnerHTML={{ __html: propertyDoc?.projectDescr }}         style={{ fontSize: "16px", lineHeight: "1.6" }} />

            
            <a
              onClick={isLoggedIn?(handleEnquire):(() => {
                setAuthFormOpen(true);
                setRewardFormOpen(false);
                setauthNewUser(false);
                setAlertFormOpen(false);
              })}
              className="details-invest-btn"
            >
              <FaRupeeSign /> <FaRupeeSign />
              Enquire & Earn LAND
            </a>
            {authFormOpen && (
              <div className="reward-auth-deatail-share">
                <div className="detail-share-modal-content">
                  <button style={{display:"flex",justifyContent:"flex-end"}}
                    onClick={() => {
                      setAuthFormOpen(false);
                      setauthNewUser(false);
                      setAlertFormOpen(false);
                    }}
                  >
                     <FaTimesCircle style={{width:"32px",height:"32px"}}/>
                  </button>
                  {!authNewUser && !alertFormOpen && (
                    <Auth
                      setauthNewUser={setauthNewUser}
                      setAlertFormOpen={setAlertFormOpen}
                    />
                  )}
                  {authNewUser && (
                    <AuthSuccess
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                    />
                  )}
                  {alertFormOpen && (
                    <AlertComponent
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                      setRewardFormOpen={setRewardFormOpen}
                      setAuthFormOpen={setAuthFormOpen}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="details-stats">
            <h2 className="details-section-title">Sale Information</h2>
            <ul className="details-stats-list">
              <li className="details-stats-item">
                <strong>Total Unit:</strong> {propertyDoc?.totalArea}
              </li>
              
              <li className="details-stats-item">
                <strong>Selling Rate:</strong> {crowdSaleDoc[0]?.sellingRate}
              </li>
              <li className="details-stats-item">
                <strong>Location:</strong> {propertyDoc?.city},{propertyDoc?.state}
              </li>
            </ul>
            <a
              onClick={isLoggedIn?(handleClaimLand):(() => {
               
                setAuthFormOpen(false);
                setRewardFormOpen(true);
                setRewardAuthNewUser(false);
                setRewardAlertFormOpen(false);
              })}
              className="details-invest-btn"
            >
              <FaRupeeSign /> Claim Your LAND{" "}
            </a>
            {rewardFormOpen && (
              <div className="reward-auth-deatail-share">
                <div className="detail-share-modal-content">
                  <button  style={{display:"flex",justifyContent:"flex-end"}}
                    onClick={() => {
                      setRewardAlertFormOpen(false);
                      setRewardFormOpen(false);
                      setRewardAuthNewUser(false);
                      setRewardAlertFormOpen(false);
                    }}
                  >
                    <FaTimesCircle style={{width:"32px",height:"32px"}}/>
                  </button>
                  {!rewardAuthNewUser && !rewardAlertFormOpen && (
                    <Auth2
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                    />
                  )}
                  {rewardAuthNewUser && (
                    <AuthSuccess
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                    />
                  )}
                  {rewardAlertFormOpen && (
                    <AlertComponent
                      setRewardAuthNewUser={setRewardAuthNewUser}
                      setRewardAlertFormOpen={setRewardAlertFormOpen}
                      setAlertFormOpen={setAlertFormOpen}
                      setauthNewUser={setauthNewUser}
                      rewardFormOpen={rewardFormOpen}
                      setRewardFormOpen={setRewardFormOpen}
                      setAuthFormOpen={setAuthFormOpen}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicDetails;
