
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip, InputLabel, InputAdornment, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./rewarddetailscard.css";
import icoreport from "../images/icons/ico-report.png";

import { FaBars, FaGift, FaHome, FaTag } from "react-icons/fa";
import {
  doc,
  getDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import logothirdeye from "../images/thirdeye.png";
import logo from "../images/favicon.png";
import { updateDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import icodetails from "../images/icons/ico-details.jpg";
import icocoin from "../images/icons/rupee-coin.svg";
import icolocation from "../images/icons/ico-location.jpg";
import icobin from "../images/icons/ico-bin.jpg";
import { addDoc } from "firebase/firestore";
import Web3 from "web3";
import { Link } from "react-router-dom";
import styled from "styled-components";

import green_verified from "../images/green_verified.png";
import red_not_verified from "../images/red_not_verified.png";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  BusinessIdUnique,
  ButtonContainerUnique,
  Container,
  ContainerUnique,
  FieldContainerUnique,
  Grid,
  Input,
  InputGroup,
  InputUnique,
  Label,
  LabelUnique,
  ProfileButton,
  ProfileIcon,
  PropertyNameWrapper,
  SectionDivider,
  SectionTitle,
  SectionTitleWrapper,
  Select,
} from "./dashboard";
import { SdCardAlertOutlined } from "@mui/icons-material";

export const Container1 = styled.div`
  width: 100%;

  max-width: 600px; /* Adjust this value as needed */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
 /*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  margin: 50px auto 0 auto; /* Reduced margin-top from 120px to 100px */

  @media (max-width: 400px) {
    padding: 10px;
    margin-top: 50px; /* Adjusted for smaller screens */
  }
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  background: white;
  border-bottom: 1px solid #e2e8f0;
  padding: 0.625rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 576px) {
    padding: 0.625rem 1rem;
  }

  @media (max-width: 300px) {
    flex-direction: column;
    padding: 0.5rem;
  }
`;

const Logo = styled.img`
  height: 2.75rem;
  width: auto;

  @media (max-width: 576px) {
    height: 2.5rem;
  }

  @media (max-width: 300px) {
    height: 1.75rem; /* Adjust height to ensure visibility on very small screens */
  }
`;
export const Logo1 = styled.img`
  height: 1.5rem; /* Default size */
  width: auto;
  margin-top: -0.5rem; /* Adjust to move the logo up */

  @media (max-width: 576px) {
    height: 1.4rem; /* Slightly smaller on medium-small screens */
    margin-top: -0.4rem; /* Adjust for medium-small screens */
  }

  @media (max-width: 460px) {
    height: 1.3rem; /* Smaller for narrower screens */
    margin-top: -0.35rem; /* Fine-tune alignment */
  }

  @media (max-width: 360px) {
    height: 1.2rem; /* Smaller size for small screens */
    margin-top: -0.3rem; /* Fine-tune for small screens */
  }

  @media (max-width: 300px) {
    height: 1rem; /* Ensure visibility on very small screens */
    margin-top: -0.25rem; /* Adjust for very small screens */
  }
`;

const SearchInputWrapper = styled.div`
//   position: relative;
  width: 100%;
//   max-width: 20rem;

  @media (max-width: 576px) {
    max-width: 15rem;
  }

  @media (max-width: 300px) {
    max-width: 8rem; /* Further reduced for very small screens */
  }
`;

const SearchInput = styled.input`
  padding: 0.5rem;
  margin-right: 0.25rem;
  color: #6b7280;
  border-radius: 0.375rem;
  background: #f3f4f6;
  transition: all 0.3s;
  width: 100%; /* Ensure input takes full width of its container */
  font-size: 12px;

  &:hover {
    color: #1f2937;
    background: #e5e7eb;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(229, 231, 235, 0.3);
  }

  @media (max-width: 576px) {
    padding: 0.375rem;
  }

  @media (max-width: 300px) {
    padding: 0.25rem;
    font-size: 0.75rem;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Checkbox = styled.input`
  margin-right: 0.25rem;
`;

const WalletLabel = styled.span`
  color: #6b7280;
  display: flex;
  align-items: center; /* Align vertically with the icon */

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }

  @media (max-width: 300px) {
    font-size: 0.75rem;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 14rem;
  background: white;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 50;

  @media (max-width: 576px) {
    width: 12rem;
  }

  @media (max-width: 300px) {
    width: 9rem; /* Adjusted for very small screens */
  }
`;

const DropdownItem = styled.a`
  display: block;
  padding: 0.5rem 1rem;
  color: #6b7280;
  transition: background 0.3s;

  &:hover {
    background: #e5e7eb;
    color: #1f2937;
  }

  @media (max-width: 576px) {
    padding: 0.5rem 0.75rem;
  }

  @media (max-width: 300px) {
    padding: 0.375rem 0.5rem;
    font-size: 0.75rem;
  }
`;

const DropdownProfile = styled.div`
  padding: 0.75rem 1rem;
  background: white;

  @media (max-width: 576px) {
    padding: 0.5rem 0.75rem;
  }

  @media (max-width: 300px) {
    padding: 0.375rem 0.5rem;
  }
`;

const DropdownProfileName = styled.span`
  display: block;
  font-weight: 600;
  color: #1f2937;

  @media (max-width: 300px) {
    font-size: 0.75rem;
  }
`;

const DropdownProfileCity = styled.span`
  display: block;
  color: #6b7280;

  @media (max-width: 300px) {
    font-size: 0.75rem;
  }
`;

export const Modal = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 576px) {
    padding: 0.75rem;
  }

  @media (max-width: 300px) {
    padding: 0.5rem;
  }
`;

export const ModalButtons = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 576px) {
    margin-top: 0.75rem;
  }

  @media (max-width: 300px) {
    margin-top: 0.5rem;
  }
`;

export const ModalButton = styled.button`
  background: ${(props) => (props.primary ? "#10b981" : "#6b7280")};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 0.5rem;
  transition: background 0.3s;

  &:hover {
    background: ${(props) => (props.primary ? "#059669" : "#4b5563")};
  }

  @media (max-width: 576px) {
    padding: 0.375rem 0.75rem;
    margin-left: 0.375rem;
  }

  @media (max-width: 300px) {
    padding: 0.25rem 0.5rem;
    margin-left: 0.25rem;
    font-size: 0.75rem;
  }
`;

//Content 2

export const Sidebar = styled.div`
  position: fixed;
  top: 60px; /* Adjust this value based on your header height */
  left: 0;
  width: 20%;
  padding: 20px;
  background-color: #2d3748;
  height: calc(
    100vh - 60px
  ); /* Adjust this value based on your header height */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 40;

  @media (max-width: 768px) {
    width: 25%;
  }

  @media (max-width: 576px) {
    width: 22%;
    padding: 16px;
  }

  @media (max-width: 400px) {
    width: 20%;
    padding: 12px;
  }

  @media (max-width: 375px) {
    width: 25%;
    padding: 10px;
  }

  @media (max-width: 390px) and (max-height: 844px) {
    width: 20%;
    padding: 10px;
  }

  @media (max-width: 360px) {
    width: 20%;
    padding: 8px;
  }

  @media (max-width: 344px) {
    width: 20%;
    padding: 6px;
  }

  @media (max-width: 300px) {
    width: 10%;
    padding: 4px;
  }

  @media (max-width: 200px) {
    width: 15%;
    padding: 2px;
  }
`;

export const MenuItem = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#4a5568" : "transparent")};
  &:hover {
    background-color: #4a5568;
  }

  @media (max-width: 576px) {
    padding: 10px;
  }

  @media (max-width: 400px) {
    padding: 8px;
  }

  @media (max-width: 375px) {
    padding: 6px;
  }

  @media (max-width: 360px) {
    padding: 5px;
  }

  @media (max-width: 344px) {
    padding: 4px;
  }

  @media (max-width: 300px) {
    padding: 3px;
  }

  @media (max-width: 200px) {
    padding: 2px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #4a5568;
  }

  @media (max-width: 576px) {
    padding: 16px;
  }

  @media (max-width: 400px) {
    padding: 12px;
  }

  @media (max-width: 375px) {
    padding: 10px;
  }

  @media (max-width: 360px) {
    padding: 8px;
  }

  @media (max-width: 344px) {
    padding: 6px;
  }

  @media (max-width: 300px) {
    padding: 4px;
  }

  @media (max-width: 200px) {
    padding: 2px;
  }
`;

export const Logoim = styled.img`
  align-self: center;
  height: 44px;
  width: auto;

  @media (max-width: 576px) {
    height: 40px;
  }

  @media (max-width: 400px) {
    height: 36px;
  }

  @media (max-width: 375px) {
    height: 34px;
  }

  @media (max-width: 360px) {
    height: 32px;
  }

  @media (max-width: 344px) {
    height: 30px;
  }

  @media (max-width: 300px) {
    height: 28px;
  }

  @media (max-width: 200px) {
    height: 24px;
  }
`;

export const Text = styled.p`
  color: white;
  text-align: center;
  font-size: 1rem;

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }

  @media (max-width: 400px) {
    font-size: 0.75rem;
  }

  @media (max-width: 375px) {
    font-size: 0.7rem;
  }

  @media (max-width: 360px) {
    font-size: 0.675rem;
  }

  @media (max-width: 344px) {
    font-size: 0.65rem;
  }

  @media (max-width: 300px) {
    font-size: 0.625rem;
  }

  @media (max-width: 200px) {
    font-size: 0.5rem;
  }
`;

export const PropertyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Equivalent to space-y-4 */
  padding: 1.5rem; /* Equivalent to p-6 */
  background-color: #f7fafc; /* Equivalent to bg-gray-100 */
`;

export const DetailRow = styled.div`
  display: flex;
  gap: 1rem; /* Equivalent to space-x-4 */
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
`;

export const DetailField = styled.div`
  flex: 1;
  max-width: 33%; /* Equivalent to w-1/3 */
  @media (max-width: 768px) {
    max-width: 100%; /* Full width on small screens */
  }
  @media (max-width: 390px) {
    max-width: 100%;
  }
`;

export const FieldLabel = styled.label`
  display: block;
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: 500; /* Equivalent to font-medium */
  color: #4a5568; /* Equivalent to text-gray-700 */
`;

export const FieldInput = styled.input`
  margin-top: 0.25rem; /* Equivalent to mt-1 */
  padding: 0.5rem; /* Equivalent to p-2 */
  width: 100%;
  border: 1px solid #e2e8f0; /* Equivalent to border-gray-300 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow-sm */
  background-color: #ffffff; /* Background color */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); /* Equivalent to focus ring */
  }
`;

export const SellButton = styled.button`
  background-color: #3182ce; /* Equivalent to bg-blue-500 */
  color: #ffffff; /* Equivalent to text-white */
  padding: 0.5rem 1rem; /* Equivalent to py-2 px-4 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow-sm */
  cursor: pointer;
  &:hover {
    background-color: #38a169; /* Equivalent to hover:bg-green-600 */
  }
`;

export const OfferDetailsContainer = styled.div`
  padding: 0.75rem 1rem; /* Equivalent to py-3 px-4 */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column; /* Stacks children vertically on small screens */
    align-items: center;
  }
`;

export const OfferDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Equivalent to space-y-2 */
`;

export const PropertyName1 = styled.span`
  font-size: 1.125rem; /* Equivalent to text-lg */
  font-weight: bold;
  color: #6b7280; /* Equivalent to text-gray-500 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OfferIconRow = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center; /* Center icon on small screens */
    margin-top: 1rem; /* Adds space between rows */
  }
`;

export const OfferIconImage = styled.img`
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  width: 3.75rem; /* Equivalent to w-15 */
  height: 3rem; /* Equivalent to h-12 */
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow */
  margin-right: 1rem; /* Equivalent to mr-4 */
  &:hover {
    transform: translateY(-0.25rem) scale(1.1); /* Equivalent to hover:-translate-y-1 hover:scale-110 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Equivalent to hover:shadow-lg */
  }
  &:active {
    transform: scale(0.9); /* Equivalent to active:scale-90 */
  }
  @media (max-width: 768px) {
    margin-right: 0; /* Removes right margin on small screens */
  }
`;

export const PropertyContainer = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
//    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-direction: column; /* Stacks children vertically on small screens */
    align-items: center;
  }
    &:hover{
    background-color:  #e5e7eb;
    }

`;

export const PropertyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
   &:hover{
    background-color:transparent;
    }
`;

export const PropertyName = styled.span`
  font-size: 1.125rem; /* Tailwind text-lg */
  font-weight: bold;
  color: #6b7280; /* Tailwind text-gray-500 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Apple";
`;

export const PropertyBusinessId = styled.span`
  font-size: 1rem; /* Tailwind text-mg */
  color: #9ca3af; /* Tailwind text-gray-400 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Apple";
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const Icon = styled.img`
  border-radius: 0.375rem; /* Tailwind rounded-md */
  width: 3rem; /* Tailwind w-15 */
  height: 3rem; /* Tailwind h-12 */
  transition: all 0.5s ease-in-out;
  transform: scale(0.9); /* Set default scale to 0.9 */
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06); /* Tailwind shadow */
  margin-right: 1rem; /* Tailwind mr-4 */

  &:hover {
    transform: translateY(-0.25rem) scale(1); /* Hover state to scale up slightly */
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05); /* Tailwind hover:shadow-lg */
  }

  &:active {
    transform: scale(0.85); /* Slightly smaller scale on active */
  }
`;

const CardContainer = styled.div`
  padding: 0.75rem 1rem; /* Tailwind py-3 px-4 */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column; /* Stacks children vertically on small screens */
    align-items: center;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Tailwind space-y-2 */
`;

const NameText = styled.span`
  font-size: 1.125rem; /* Tailwind text-lg */
  font-weight: bold;
  color: #6b7280; /* Tailwind text-gray-500 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Apple";
`;

const IdText = styled.span`
  font-size: 1rem; /* Tailwind text-mg */
  color: #9ca3af; /* Tailwind text-gray-400 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Apple";
`;

const IconGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center; /* Center icon on small screens */
    margin-top: 1rem; /* Adds space between rows */
  }
`;

export const IconButton = styled.img`
  border-radius: 0.375rem; /* Tailwind rounded-md */
  width: 3rem; /* Tailwind w-15 */
  height: 3rem; /* Tailwind h-12 */
  transition: all 0.5s ease-in-out;
  transform: scale(0.9); /* Set default scale to 0.9 */
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06); /* Tailwind shadow */
  margin-right: 1rem; /* Tailwind mr-4 */

  &:hover {
    transform: translateY(-0.25rem) scale(1); /* Hover state to scale up slightly */
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05); /* Tailwind hover:shadow-lg */
  }

  &:active {
    transform: scale(0.9); /* Slightly smaller scale on active */
  }
  @media (max-width: 344px) {
    width: 2.5rem; /* Adjust margin for 344px width screens */
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 1rem; /* Tailwind mb-4 */
`;

const FieldTitle = styled.h4`
  font-weight: bold; /* Tailwind font-bold */
  margin-bottom: 0.5rem; /* Space between title and input */
`;

export const DetailsContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Equivalent to space-y-4 */
  padding: 1.5rem; /* Equivalent to p-6 */
  background-color: #f7fafc; /* Equivalent to bg-gray-100 */
  @media (max-width: 540px) {
    padding: 1rem; /* Adjust padding for smaller screens */
  }
`;

// Container for each row of details
const DetailRow1 = styled.div`
  display: flex;
  gap: 1rem; /* Equivalent to space-x-4 */
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
`;

// Container for each detail field
const DetailField1 = styled.div`
  flex: 1;
  max-width: 33%; /* Equivalent to w-1/3 */
  @media (max-width: 768px) {
    max-width: 50%; /* Adjust width on medium screens */
  }
  @media (max-width: 540px) {
    max-width: 100%; /* Full width on small screens */
  }
`;

// Styling for labels
const FieldLabel1 = styled.label`
  display: block;
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: 500; /* Equivalent to font-medium */
  color: #4a5568; /* Equivalent to text-gray-700 */
`;

// Styling for input fields
const FieldInput1 = styled.input`
  margin-top: 0.25rem; /* Equivalent to mt-1 */
  padding: 0.5rem; /* Equivalent to p-2 */
  width: 100%;
  border: 1px solid #e2e8f0; /* Equivalent to border-gray-300 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow-sm */
  background-color: #ffffff; /* Background color */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); /* Equivalent to focus ring */
  }
`;

// Container for the button section
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem; /* Equivalent to space-x-4 */
  margin-top: 1rem; /* Equivalent to mt-4 */
  @media (max-width: 540px) {
    flex-direction: column; /* Stack buttons vertically on small screens */
    align-items: stretch; /* Stretch buttons to fill the container */
    gap: 0.5rem; /* Reduce gap for smaller screens */
  }
`;

// Styling for the buttons
const CancelButton = styled.button`
  background-color: #f56565; /* Equivalent to bg-red-500 */
  color: #ffffff; /* Equivalent to text-white */
  padding: 0.5rem 1rem; /* Equivalent to py-2 px-4 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow-sm */
  cursor: pointer;
  &:hover {
    background-color: #e53e3e; /* Equivalent to hover:bg-red-600 */
  }
  @media (max-width: 540px) {
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }
`;

const CompleteButton = styled.button`
  background-color: #48bb78; /* Equivalent to bg-green-500 */
  color: #ffffff; /* Equivalent to text-white */
  padding: 0.5rem 1rem; /* Equivalent to py-2 px-4 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Equivalent to shadow-sm */
  cursor: pointer;
  &:hover {
    background-color: #38a169; /* Equivalent to hover:bg-green-600 */
  }
  @media (max-width: 540px) {
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }
`;

//location

const IframeContainer = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 12px;
  max-width: 600px;
  border-radius: 0.375rem; /* Equivalent to rounded-lg */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-lg */

  @media (max-width: 768px) {
    padding: 0.75rem;
    max-width: 90%;
  }

  @media (max-width: 576px) {
    padding: 0.5rem;
    max-width: 100%;
  }
`;

// Inner container for the iframe
const IframeInnerContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 1rem; /* Equivalent to mb-4 */
`;

// Styled iframe
const StyledIframe = styled.iframe`
  width: 500px;
  height: 550px;
  border: none;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3; /* Maintain aspect ratio for responsiveness */
  }

  @media (max-width: 576px) {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3; /* Maintain aspect ratio for responsiveness */
  }
`;
//images plan

const ResponsiveContainer = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  // margin-top: 12px;
  max-width: 600px;
  margin:auto;
  display:flex;
  flex-direction: column-reverse;

  @media (max-width: 400px) {
    margin-top: 60px;
    padding: 2px;
  }
`;

const ResponsiveIframeWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  max-width: 500px;
  height: 550px;
`;

const PlanLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: black;
  &:hover {
    color: red;
  }
`;

const NoPlanText = styled.div`
  text-align: center;
  opacity: 0.5;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  align-items: center;

  @media (max-width: 400px) {
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: ${(props) => (props.isExpanded ? "2px solid blue" : "none")};
  }
`;

const NoImageText = styled.p`
  opacity: 0.5;
  padding-left: 50%;
  text-align: center;
`;

const ExpandedImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
`;

const ExpandedImageContainer = styled.div`
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ExpandedImage = styled.img`
  transform: scale(${(props) => props.zoomLevel});
  transform-origin: center;
  max-width: 100%;
  max-height: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: transparent;
`;

const  Search = () => {
  
  const [fractionalOwnershipscoinsopen, setfractionalOwnershipscoinsopen] =
    useState(null);
  const [fractionalOwnershipsdetailsopen, setfractionalOwnershipdetailsopen] =
    useState(null);
  const [
    fractionalOwnershipslocationsopen,
    setfractionalOwnershipslocationsopen,
  ] = useState(null);
  const [offername, setoffername] = useState(null);
  const [userList,setUserList]=useState([])
  const [offerdetailsopen, setofferdetailsopen] = useState(null);
  const [fracList, setFracList] = useState([]);
  const [assets, setAssets] = useState([]);
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
   const [landRewardList,setLandRewardList]=useState([])
  const [user, setUser] = useState(null);
  const profileRef = useRef(null);
  const db = getFirestore();
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState("");
  const [hasRealProperty, setHasRealProperty] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pressedDetailsId, setPressedDetailsId] = useState(null);
  const [pressedCoinId, setPressedCoinId] = useState(null);
  const [pressedLocationId, setPressedLocationId] = useState(null);
  const [pressedBinId, setPressedBinId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [digitalAssets, setDigitalAssets] = useState([]);
  const [crowdSales, setCrowdSales] = useState([]);
  const [crowdSaleUnits, setCrowdSaleUnits] = useState([]);
  const [fractionalOwnerships, setFractionalOwnerships] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(null);
  const [coinOpen, setCoinOpen] = useState(null);
  const [locationOpen, setLocationOpen] = useState(null);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isCreatingAsset, setIsCreatingAsset] = useState(false);
  const [editedProperty, setEditedProperty] = useState({});
  const [editedCity, setEditedCity] = useState("");
  const [editedPropertyType, setEditedPropertyType] = useState("");
  const [editedTotalArea, setEditedTotalArea] = useState("");
  const [editedTotalPlots, setEditedTotalPlots] = useState("");
  const [editedSoldPlots, setEditedSoldPlots] = useState("");
  const [smartLayout, setSmartLayout] = useState("");
  const [isConfirmBuyOpen, setIsConfirmBuyOpen] = useState(false);
  const [isConfirmBuyBlockChain, setIsConfirmBuyBlockChain] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [fractionalQuantity, setFractionalQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [flag, setFlag] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const [crowdSaleUnitList, setCrowdsaleUnitList] = useState([]);
  const [fractvalue, setfractvalue] = useState("");
  const [pricevalue, setpricevalue] = useState("");
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isTransaction, setIsTransaction] = useState(true);
  const [propId, setPropId] = useState(null);
  const [propLoading, setPropLoading] = useState(true);
  const [offerLoading, setOfferLoading] = useState(true);
  const [userPropLoading, setUserPropLoading] = useState(false);
  const [noBlockChain, setNoBlockChain] = useState(false);
  const [isWalletCategory, setIsWalletCategory] = useState(false);
  const ThirdEye_Market_Contract = "0x8507B0cc80566d35Acdf833bD2Fe90f2Cca56bf1";
  const [unitAmount, setUnitAmount] = useState("");
  const [cost, setCost] = useState("");
  const [crowdSaleList, setCrowdsaleList] = useState([]);

  const [expandedImage, setExpandedImage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [loading,setLoading]=useState(true)

  const [propertyNames, setPropertyNames] = useState({});
  const [businessIds, setBusinessIds] = useState({});
  const [paymentrecived, setIsPaymentrecived] = useState("");
  const [iscancel, setIsCancel] = useState(false);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  const currentDate = new Date();
  const [paymentlist, setPaymentList] = useState([]);
  const [isProfileEditing , setIsProfileEditing ] = useState(false);
  const [userData, setUserData] = useState({ mobile: "", name: "", city: "" });
  //const [isEditing, setIsEditing] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [userName, setUserName] = useState("");
  const [userCity, setUserCity] = useState("");
   const [pressedReportId,setPressedReportId]=useState(null);

  const [discountFactor,setDiscountFactor]= useState("");
  const [actualDiscount,setActualDiscount] = useState("");
   /* const [reportOpen,setReportOpen]=useState(null) */
   //fetch users list
   /*   const fetchUserList = async () => {
       try {
         const q = query(collection(db, "users"));
         const querySnapshot = await getDocs(q);
      
         const userslist = querySnapshot.docs.map((doc) => ({
           id: doc.id,
           ...doc.data(),
         }));
        
      
        setUserList(userslist)
       } catch (error) {
         console.error("Error fetching land rewards:", error);
       }
     };
   
     useEffect(()=>{
       fetchUserList()
   
     },[db])

       const fetchLandRewards = async () => {
         try {
           const q = query(collection(db, "landRewards"));
           const querySnapshot = await getDocs(q);
        
           const rewards = querySnapshot.docs.map((doc) => ({
             id: doc.id,
             ...doc.data(),
           }));
           
        
          setLandRewardList(rewards)
         } catch (error) {
           console.error("Error fetching land rewards:", error);
         }
       };
     
       useEffect(()=>{
         fetchLandRewards()
     
       },[db]) */


      /*  useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true)
            const [usersSnapshot, rewardsSnapshot] = await Promise.all([
              getDocs((collection(db, "users"))),
              getDocs((collection(db, "landRewards"))),
            ]);
            
            const userslist = usersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            const rewards = rewardsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            
            setUserList(userslist);
            setLandRewardList(rewards);
          } catch (error) {
            console.error("Error fetching data:", error);
          }finally{
            setLoading(false)
          }
        };
      
        fetchData();
      }, [db]); */
      




        // In-memory caches
  /* const userCache = useRef([]);
  const rewardsCache = useRef([]); */

  /* useEffect(() => {
    const fetchData = async () => {
      if (userCache.current.length > 0 && rewardsCache.current.length > 0) {
        // Load data from cache
        setUserList(userCache.current);
        setLandRewardList(rewardsCache.current);
        return;
      }

      try {
        setLoading(true);

        // Fetch data from Firestore
        const [usersSnapshot, rewardsSnapshot] = await Promise.all([
          getDocs(collection(db, "users")),
          getDocs(collection(db, "landRewards")),
        ]);

        // Process user data
        const userslist = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Process reward data
        const rewards = rewardsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Update state
        setUserList(userslist);
        setLandRewardList(rewards);

        // Store in cache
        userCache.current = userslist;
        rewardsCache.current = rewards;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [db]); */
//   useEffect(  () => {
//     if (userProfileOpen) {
//       const fetchUserprofileData = async () => {
//         try {
//           const auth = getAuth();
//           const user = auth.currentUser; // Get the currently authenticated user
    
//           if (user) { // Check if the user is authenticated
//             const db = getFirestore();
//             const profileRef = collection(db, "users"); // Reference to the "users" collection
//             const profileQuery = query(
//               profileRef,
//               where("id", "==", user.uid) // Match the UID of the current user
//             );
    
//             const querySnapshot = await getDocs(profileQuery);
    
//             if (!querySnapshot.empty) { // Check if any documents match the query
//               querySnapshot.forEach((doc) => {
//                 const data = doc.data();
//                 setUserData({ mobile: data.mobile, name: data.name, city: data.city });
//                 setUserName(data.name);
//                 setUserCity(data.city);
//               });
//             } else {
//               console.log("No such document found for the current user!");
//             }
//           } else {
//             console.log("No authenticated user found!");
//           }
//         } catch (error) {
//           console.error("Error fetching user profile data:", error);
//         }
//       };
    
//       fetchUserprofileData();
//     }
    
//   }, [userProfileOpen])



//   const handleprofileSave = async () => {
//     const auth = getAuth();
//     const user = auth.currentUser;
//     const db = getFirestore();
    
//     try {
//     await updateDoc(doc(db, "users", user.uid), {
//       name: userName,
//       city: userCity,
//     });
    

//     // const userDoc = doc(db, "users", user.uid);
//     // try {
//     //   await updateDoc(userDoc, {
//     //     name: userName,
//     //     city: userCity,
//     //   });
//       setUserData({ ...user, name: userName, city: userCity }); // Update the local state
//       setIsProfileEditing(false);
      
//       setUserProfileOpen(false);
//       console.log("User details updated successfully");
//     } catch (error) {
//       alert("Error updating document: ", error);
//     }
//   };

//   const fetchPaymentDetails = async (paymentId) => {
//     try {
//       const response = await fetch(`/payment-details/${paymentId}`);
//       const paymentDetails = await response.json();
//       return paymentDetails;
//     } catch (error) {
//       console.error("Error fetching payment details:", error);
//       throw error;
//     }
//   };

  // const fetchPaymentDetails = async (paymentId) => {
  //   try {
  //     const response = await fetch(`https://api.razorpay.com/v1/payments/${paymentId}`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': 'Basic ' + btoa('rzp_test_lXlGDmFvauzSOO':'39RvAS5Q7Timwcf1tVmxRLuM'),
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Failed to fetch: ${response.statusText}`);
  //     }

  //     const paymentDetails = await response.json();
  //     return paymentDetails;
  //   } catch (error) {
  //     console.error("Error fetching payment details:", error.message);
  //     throw error;
  //   }
  // };

  // const fetchPaymentDetails = async (paymentId) => {
  //   try {
  //     const key = 'rzp_test_lXlGDmFvauzSOO';
  //     const secret = '39RvAS5Q7Timwcf1tVmxRLuM';
  //     const authString = btoa(`${key}:${secret}`);

  //     const response = await fetch(`https://api.razorpay.com/v1/payments/${paymentId}`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': 'Basic ' + authString,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Failed to fetch: ${response.statusText}`);
  //     }

  //     const paymentDetails = await response.json();
  //     return paymentDetails;
  //   } catch (error) {
  //     console.error("Error fetching payment details:", error.message);
  //     throw error;
  //   }
  // };
  // server.js local host
  // const fetchPaymentDetails = async (paymentId) => {
  //   try {
  //     const response = await fetch(`https://api.razorpay.com/v1/payments/${paymentId}`);
  //     const paymentDetails = await response.json();
  //     return paymentDetails;
  //   } catch (error) {
  //     console.error("Error fetching payment details:", error);
  //     throw error;
  //   }
  // };

  // const fetchPaymentDetails = async (paymentId) => {
  //   const RAZORPAY_KEY_ID = "rzp_test_lXlGDmFvauzSOO";
  //   const RAZORPAY_SECRET = "39RvAS5Q7Timwcf1tVmxRLuM";

  //   try {
  //     const response = await fetch(
  //       `https://api.razorpay.com/v1/payments/${paymentId}`,
  //       {
  //         mode: 'no-cors',
  //         method: "GET",
  //         headers: {
  //           Authorization:
  //             "Basic " + btoa(`${RAZORPAY_KEY_ID}:${RAZORPAY_SECRET}`),
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.statusText}`);
  //     }

  //     const paymentDetails = await response.json();
  //     return paymentDetails;
  //   } catch (error) {
  //     console.error("Error fetching payment details:", error);
  //     throw error;
  //   }
  // };

  //pay function for crowdSale
 
  
//   const handlePay = async (sale, cost, paymentMethod, paymentId) => {
//     // await handlePayment(sale);
    
//     const matchingProperty = properties.find(
//       (prop) => prop.propertyId === sale.propertyId
//     );

//     const fractionalOwnershipDoc = {
//       createdAt: new Date().toLocaleDateString("en-GB", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric",
//       }),
//       createdToSort: new Date(),
//       crowdSaleId: sale.propertyId,
//       digitalAssetId: null,
//       propertyId: sale.propertyId,
//       propertyName: matchingProperty ? matchingProperty.name : "",
//       tokenName: null,
//       totalOwned: null,
//       totalTokens: null,
//       userId: User.uid,
//     };

//     const fractionalOwnershipDocRefAdded = await addDoc(
//       collection(db, "fractionalOwnership"),
//       fractionalOwnershipDoc
//     );

//     // Create a new fractional object with the added ID
//     const newFract = {
//       ...fractionalOwnershipDoc,
//       id: fractionalOwnershipDocRefAdded.id,
//     };

//     const crowdSaleUnitsCollectionRef = await collection(db, "crowdSaleUnits");
//     const crowdSaleUnitsDocRef = await doc(crowdSaleUnitsCollectionRef);
//     const crowdSaleUnitsDoc = {
//       crowdSaleId: sale.propertyId,
//       foId: fractionalOwnershipDocRefAdded.id,
//       ownedUnits: unitAmount,
//       paymentMethod: paymentMethod,
//       paymentTxnId: paymentId,
//       propertyId: sale.propertyId,
//       purchasedAt: new Date().toLocaleDateString("en-GB", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric",
//       }),
//       sellingUnits: "",
//       status: "Open",
//       totalAmount: cost,
//       userId: User.uid,
//       discount: actualDiscount,
//     };

//     console.log("cost is ", cost);
//     await setDoc(crowdSaleUnitsDocRef, crowdSaleUnitsDoc, { merge: true });

//     // Create a new fractional object with the added ID
//     const newUnit = {
//       ...crowdSaleUnitsDoc,
//       id: crowdSaleUnitsDocRef.id,
//     };

//     setCrowdsaleUnitList((prevList) => [...prevList, newUnit]);

//     const fractionalOwnershipDocRef = await  doc(
//       db,
//       "fractionalOwnership",
//       sale.propertyId
//     );
//     const fractionalOwnershipDocSnap = await getDoc(fractionalOwnershipDocRef);

//     await updateDoc(doc(db, "crowdSale", sale.propertyId), {
//       availableUnits: Number(Number(sale.availableUnits) - Number(unitAmount)),
//       currentSaleAmount: Number(Number(sale.currentSaleAmount) + Number(cost)),
     
//     });

//     if (fractionalOwnershipDocSnap.exists()) {
//       console.log(
//         "A document already exists in the fractionalOwnership collection for this property"
//       );
//       return;
//     }

//     await fetchPaymentData();
//     await fetchCrowdSaleUnitData();
//     await fetchCrowdSaleData();
//     await fetchUserData();

//     const matchingPropertyunit = crowdSaleUnitList.find(
//       (prop) => prop.crowdSaleId === sale.propertyId
//     );
//     await fetchPaymentData();
//     await fetchCrowdSaleUnitData();
//     await fetchCrowdSaleData();
//     await fetchUserData();
//     const matchingpaymentid = paymentlist.find(
//       (prop) => prop.payment_id === matchingPropertyunit?.paymentTxnId
//     );
//     console.log(paymentlist);
//     console.log(matchingpaymentid);
//     console.log(matchingpaymentid.id);
//     await updateDoc(doc(db, "paymentTransaction", matchingpaymentid.id), {
//       crowdSaleUnitId: matchingPropertyunit.id,
//     });

//     // Directly adding the new fract to the fracList
//     setFracList((prevList) => [...prevList, newFract]);
    
//     await fetchUserData();
//     setIsProcessing(false);
    
//     setSelectedSection("Properties");
//     setCost("");
//     setUnitAmount("");
//     setCoinOpen(null);
//     setDiscountFactor(0);
//     setActualDiscount(0);
//     await fetchfractionaldata();
//     await fetchCrowdSaleUnitData();
//     await fetchCrowdSaleData();
//   };
//   const handlePayLater = async (sale, cost) => {
//     console.log("pay later ", cost)
//     setIsPayLater(false)
//     setIsProcessing(true)
//     // await handlePayment(sale);
//     const paymentMethod = "Pay Later"
//     const  paymentId = ""
    
//     const matchingProperty = properties.find(
//       (prop) => prop.propertyId === sale.propertyId
//     );

//     const fractionalOwnershipDoc = {
//       createdAt: new Date().toLocaleDateString("en-GB", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric",
//       }),
//       createdToSort: new Date(),
//       crowdSaleId: sale.propertyId,
//       digitalAssetId: null,
//       propertyId: sale.propertyId,
//       propertyName: matchingProperty ? matchingProperty.name : "",
//       tokenName: null,
//       totalOwned: null,
//       totalTokens: null,
//       userId: User.uid,
//     };

//     const fractionalOwnershipDocRefAdded = await addDoc(
//       collection(db, "fractionalOwnership"),
//       fractionalOwnershipDoc
//     );

//     // Create a new fractional object with the added ID
//     const newFract = {
//       ...fractionalOwnershipDoc,
//       id: fractionalOwnershipDocRefAdded.id,
//     };

//     const crowdSaleUnitsCollectionRef = await collection(db, "crowdSaleUnits");
//     const crowdSaleUnitsDocRef = await doc(crowdSaleUnitsCollectionRef);
//     const crowdSaleUnitsDoc = {
//       crowdSaleId: sale.propertyId,
//       foId: fractionalOwnershipDocRefAdded.id,
//       ownedUnits: unitAmount,
//       paymentMethod: paymentMethod,
//       paymentTxnId: paymentId,
//       propertyId: sale.propertyId,
//       purchasedAt: new Date().toLocaleDateString("en-GB", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric",
//       }),
//       sellingUnits: "",
//       status: "Open",
//       totalAmount: cost,
//       userId: User.uid,
//       discount: actualDiscount,
//     };

//     console.log("cost is ", cost);
//     await setDoc(crowdSaleUnitsDocRef, crowdSaleUnitsDoc, { merge: true });

//     // Create a new fractional object with the added ID
//     const newUnit = {
//       ...crowdSaleUnitsDoc,
//       id: crowdSaleUnitsDocRef.id,
//     };

//     setCrowdsaleUnitList((prevList) => [...prevList, newUnit]);

//     const fractionalOwnershipDocRef = await  doc(
//       db,
//       "fractionalOwnership",
//       sale.propertyId
//     );
//     const fractionalOwnershipDocSnap = await getDoc(fractionalOwnershipDocRef);

//     await updateDoc(doc(db, "crowdSale", sale.propertyId), {
//       availableUnits: Number(Number(sale.availableUnits) - Number(unitAmount)),
//       currentSaleAmount: Number(Number(sale.currentSaleAmount) + Number(cost)),
//     });

//     if (fractionalOwnershipDocSnap.exists()) {
//       console.log(
//         "A document already exists in the fractionalOwnership collection for this property"
//       );
//       return;
//     }

//     await fetchPaymentData();
//     await fetchCrowdSaleUnitData();
//     await fetchCrowdSaleData();
//     await fetchUserData();

//     // const matchingPropertyunit = crowdSaleUnitList.find(
//     //   (prop) => prop.crowdSaleId === sale.propertyId
//     // );
//     // const matchingpaymentid = paymentlist.find(
//     //   (prop) => prop.payment_id === matchingPropertyunit.paymentTxnId
//     // );
//     // console.log(paymentlist);
//     // console.log(matchingpaymentid);
//     // console.log(matchingpaymentid.id);
//     // await updateDoc(doc(db, "paymentTransaction", matchingpaymentid.id), {
//     //   crowdSaleUnitId: matchingPropertyunit.id,
//     // });

//     // Directly adding the new fract to the fracList
//     setFracList((prevList) => [...prevList, newFract]);
    
//     await fetchUserData();
//     setIsProcessing(false);
    
//     setSelectedSection("Properties");
//     setCost("");
//     setUnitAmount("");
//     setCoinOpen(null);
//     await fetchCrowdSaleUnitData();
//     await fetchCrowdSaleData();
//   };
  // razorpay checking with id and method

  //payments check out and store in db

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://checkout.razorpay.com/v1/checkout.js";
//     script.async = true;
//     document.body.appendChild(script);
//   }, []);

//   const saveTransaction = async (transactionData) => {
//     try {
//       const docRef = await addDoc(
//         collection(db, "paymentTransaction"),
//         transactionData
//       );
//       console.log("Transaction stored with ID: ", docRef.id);
//     } catch (e) {
//       console.error("Error adding document: ", e);
//     }
//   };

  // const PaymentButton = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPayLater, setIsPayLater] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  // const handlePayment = () => {
  //   setIsProcessing(true);

  //   const options = {
  //     key: "YOUR_RAZORPAY_KEY", // Use the test key from Razorpay dashboard
  //     amount: 50000, // Amount in paise (50000 = 500 INR)
  //     currency: "INR",
  //     name: "Your Company Name",
  //     description: "Test Transaction",
  //     image: "https://example.com/your-logo.png",
  //     order_id: "", // Replace with generated order_id (optional)
  //     handler: function (response) {
  //       // Handle payment success
  //       setIsProcessing(false);
  //       setPaymentStatus("Transaction successful!");
  //       console.log(response);
  //     },
  //     prefill: {
  //       name: "John Doe",
  //       email: "johndoe@example.com",
  //       contact: "9999999999",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //     modal: {
  //       ondismiss: function () {
  //         // Handle modal dismissal (optional)
  //         setIsProcessing(false);
  //         setPaymentStatus("Payment cancelled by user.");
  //       },
  //     },
  //   };

  //   const rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  // };
  //razopay with saving data in db
  //working with out saving payment method

  // const handlePayment = async (sale) => {
  //   setIsProcessing(true);

  //   const amountInPaise = Math.round(Number(cost) * 100);
  //   const options = {
  //     key: "rzp_test_lXlGDmFvauzSOO", // Replace with your Razorpay key
  //     amount: amountInPaise, // Amount in paise (10000 paise = 100 INR)
  //     currency: "INR",
  //     name: "ThirdEye",
  //     description: "Test Transaction",
  //     image: {logo}, // Replace with your logo URL
  //     handler: function (response) {
  //       setIsProcessing(false);
  //       setPaymentStatus("Transaction successful!");
  //       console.log(response); // Log the payment response (for development purposes)

  //       // Prepare transaction data for Firestore
  //       const transactionData = {
  //         payment_id: response.razorpay_payment_id,
  //         // order_id: response.razorpay_order_id,
  //         // signature: response.razorpay_signature,
  //          // Only include order_id and signature if they are not undefined
  //          ...(response.razorpay_order_id && { order_id: response.razorpay_order_id }),
  //          ...(response.razorpay_signature && { signature: response.razorpay_signature }),
  //         amount: cost, // Amount in INR
  //         currency: "INR",
  //         timestamp: new Date(),
  //       };

  //       // Save transaction to Firebase Firestore
  //        saveTransaction(transactionData);

  //        handlePay(sale)
  //     },
  //     prefill: {
  //       name: "John Doe",
  //       email: "johndoe@example.com",
  //       contact: "9999999999",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //     modal: {
  //       ondismiss: function () {
  //         setIsProcessing(false);
  //         setPaymentStatus("Payment cancelled by user.");
  //       },
  //     },
  //   };

  //   const rzp = new window.Razorpay(options);
  //   rzp.open();

  // };

  //with paymenth method

//   const handlePayment = async (sale, cost) => {
//     setIsProcessing(true);

//     const amountInPaise = Number((Number(cost) * 100).toFixed(2));
//     console.log(amountInPaise);
//     const options = {
//       key:   "rzp_live_1iVaUFUp4l6VuD",// "rzp_test_lXlGDmFvauzSOO", // Replace with your Razorpay key
//       amount: amountInPaise, // Amount in paise (10000 paise = 100 INR)
//       currency: "INR",
//       name: "ThirdEye",
//       description: " Transaction",
//       image: { logo }, // Replace with your logo URL
//       handler: async function (response) {
        
//         setPaymentStatus("Transaction successful!");
//         console.log(response); // Log the payment response (for development purposes)
//         const paymentId = response.razorpay_payment_id;

//         // const paymentDetails = await fetchPaymentDetails(paymentId);
//         const paymentMethod = "online payment "; // Get payment method
//         console.log("Payment Method:", paymentMethod);

//         // You can access the payment method from Razorpay's payment response
//         // const paymentmethod = response.method || "unknown"; // Razorpay provides the payment method here

//         // Prepare transaction data for Firestore
//         const transactionData = {
//           payment_id: paymentId,
//           // Only include order_id and signature if they are not undefined
//           ...(response.razorpay_order_id && {
//             order_id: response.razorpay_order_id,
//           }),
//           ...(response.razorpay_signature && {
//             signature: response.razorpay_signature,
//           }),
//           amount: cost, // Amount in INR
//           currency: "INR",
//           payment_method: paymentMethod, // Save the payment method
//           timestamp: new Date(),
//         };

//         console.log("paymentid", paymentId);
//         console.log("paymentmethod", paymentMethod);

//         // Save transaction to Firebase Firestore
//         await saveTransaction(transactionData);

//         // Call handlePay only after successful payment and pass paymentMethod to handlePay
//         await handlePay(sale, cost, paymentMethod, paymentId);
//       },
//       // prefill: {
//       //   name: "John Doe",
//       //   email: "johndoe@example.com",
//       //   contact: "9999999999",
//       // },
//       theme: {
//         color: "#3399cc",
//       },
//       modal: {
//         ondismiss: function () {
//           setIsProcessing(false);
//           setPaymentStatus("Payment cancelled by user.");
//         },
//       },
//     };

//     const rzp = new window.Razorpay(options);
//     rzp.open();
//   };

  /* const handlePay = async (sale) => {
        try {
          const matchingProperty = properties.find(
            (prop) => prop.propertyId === sale.propertyId
          );
      
          const fractionalOwnershipDoc = {
            createdAt: new Date().toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
            createdToSort: new Date(),
            crowdSaleId: sale.propertyId,
            digitalAssetId: null,
            propertyId: sale.propertyId,
            propertyName: matchingProperty ? matchingProperty.name : "",
            tokenName: null,
            totalOwned: null,
            totalTokens: null,
            userId: User.uid,
          };
      
          const fractionalOwnershipDocRefAdded = await addDoc(
            collection(db, "fractionalOwnership"),
            fractionalOwnershipDoc
          );
      
          // Create a new fractional object with the added ID
          const newFract = {
            ...fractionalOwnershipDoc,
            id: fractionalOwnershipDocRefAdded.id,
            saleId: sale.id, // Include sale.id to distinguish different entries
          };
      
          const crowdSaleUnitsCollectionRef = collection(db, "crowdSaleUnits");
          const crowdSaleUnitsDocRef = doc(crowdSaleUnitsCollectionRef, sale.propertyId);
          const crowdSaleUnitsDoc = {
            crowdSaleId: sale.propertyId,
            foId: fractionalOwnershipDocRefAdded.id,
            ownedUnits: unitAmount,
            paymentMethod: "",
            paymentTxnId: "",
            propertyId: sale.propertyId,
            purchasedAt: new Date().toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
            sellingUnits: "",
            status: "Open",
            totalAmount: cost,
          };
      
          await setDoc(crowdSaleUnitsDocRef, crowdSaleUnitsDoc, { merge: true });
      
          await updateDoc(doc(db, "crowdSale", sale.propertyId), {
            availableUnits: Number(sale.availableUnits) - Number(unitAmount),
          });
      
          // Add the new fractional ownership document to the list, allowing multiple entries with the same propertyId
          setFracList((prevList) => [...prevList, newFract]);
      
          fetchUserData();
          alert("Payment Successful");
          setSelectedSection("Properties");
          setCost("");
          setUnitAmount("");
          setCoinOpen(null);
        } catch (error) {
          console.error("Error processing payment:", error);
          alert("Payment failed. Please try again.");
        }
      }; */

  const handleImageClick = (imageSrc) => {
    // setExpandedImage(expandedImage === imageSrc ? null : imageSrc);
    setExpandedImage((prev) => (prev === imageSrc ? null : imageSrc));
    setZoomLevel(1); // Reset zoom when changing the image
  };
  const handleNoBlockChain = () => {
    setIsWalletCategory(false);
    setNoBlockChain(true);
  };

  const handleWheel = (event) => {
    event.preventDefault();
    setZoomLevel((prevZoom) => {
      let newZoom = prevZoom + (event.deltaY > 0 ? -0.1 : 0.1);
      newZoom = Math.max(1, Math.min(newZoom, 3)); // Set min and max zoom levels
      return newZoom;
    });
  };
  const handleQuantityChange = (e, val) => {
    const enteredQuantity = e.target.value;
    setQuantity(enteredQuantity);

    if (!enteredQuantity) {
      setQuantityError("Quantity is required.");
    } else if (parseFloat(enteredQuantity) > parseFloat(val)) {
      setQuantityError(
        "Entered quantity cannot be greater than available value."
      );
    } else {
      setQuantityError("");
    }
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
    if (e.target.value) {
      setPriceError("");
    } else {
      setPriceError("Price is required.");
    }
  };
  // coppied from dashboard
  useEffect(() => {
    const fetchPropertyNames = async () => {
      const names = {};
      for (const offer of offerList) {
        const name = await fetchPropertyName(offer.propertyId);
        names[offer.propertyId] = name;
      }
      setPropertyNames(names);
    };

    fetchPropertyNames();
  }, [offerList]);

  const fetchPropertyName = async (propertyId) => {
    const realPropertyDocRef = doc(db, "realProperty", propertyId);
    const realPropertyDocSnap = await getDoc(realPropertyDocRef);

    if (realPropertyDocSnap.exists()) {
      return realPropertyDocSnap.data().name;
    } else {
      console.log(
        `No document with ID ${propertyId} in realProperty collection!`
      );
      return null;
    }
  };

  //get business id

  const fetchBusinessId = async (propertyId) => {
    const realPropertyDocRef = doc(db, "realProperty", propertyId);
    const realPropertyDocSnap = await getDoc(realPropertyDocRef);

    if (realPropertyDocSnap.exists()) {
      return realPropertyDocSnap.data().businessId;
    } else {
      console.log(
        `No document with ID ${propertyId} in realProperty collection!`
      );
      return null;
    }
  };
 
  //   const fetchPropertyData = async () => {
  //     const auth = getAuth();
  //     const user = auth.currentUser;
  //     const db = getFirestore();

  //     const propertiesRef = collection(db, "realProperty");
  //     const propertiesQuery = query(
  //       propertiesRef,
  //       where("businessId", "==", user.uid)
  //     );
  //     const propertiesSnapshot = await getDocs(propertiesQuery);

  //     const allPropertyNames = propertiesSnapshot.docs.reduce((names, doc) => {
  //       names[doc.id] = doc.data().name;
  //       return names;
  //     }, {});

  //     const allBusinessIds = propertiesSnapshot.docs.reduce((ids, doc) => {
  //       ids[doc.id] = doc.data().businessId;
  //       return ids;
  //     }, {});

  //     setPropertyNames(allPropertyNames);
  //     setPropLoading(false);
  //     setBusinessIds(allBusinessIds);
  //   };

  //   fetchPropertyData();
  // }, []);

//   const fetchOffers = async () => {
//     // try {
//     //   const auth = getAuth();
//     //   const user = auth.currentUser;
//     //   const db = getFirestore();

//     //   const propertiesRef = collection(db, "realProperty");
//     //   const propertiesQuery = query(
//     //     propertiesRef,
//     //      where("businessId", "==", user.uid),
//     //     orderBy("createdAt", "desc")
//     //   );
//     //   const propertiesSnapshot = await getDocs(propertiesQuery);

//     //   const propertyIds = propertiesSnapshot.docs.map((doc) => doc.id);

//     //   const offersPromises = propertyIds.map(async (propertyId) => {
//     //     const offersRef = collection(db, "customerOffer");
//     //     const offersQuery = query(
//     //       offersRef,
//     //       // where(
//     //       //   "customerId",
//     //       //   "==",
//     //       //   user.uid
//     //       // )
//     //       where(
//     //         "propertyId",
//     //         "==",
//     //         propertyId
//     //       ) /* orderBy("createdAt","desc") */
//     //     );
//     //     const offersSnapshot = await getDocs(offersQuery);

//     //     return offersSnapshot.docs.map((doc) => ({
//     //       id: doc.id,
//     //       ...doc.data(),
//     //     }));
//     //   });

//     //   const offersArray = await Promise.all(offersPromises);
//     //   const offers = offersArray.flat();

//     //   setOfferList(offers);
//     //   setOfferLoading(false);
//     // } catch (error) {
//     //   console.error("Error fetching offers: ", error);
//     // }

//     const auth = getAuth();
//     const user = auth.currentUser;
//     const docRef = doc(db, "users", user.uid);
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       setUser(docSnap.data());
//     } else {
//       console.log("No such document!");
//     }
//     const realofferQuery = query(
//       collection(db, "customerOffer"),
//       where("customerId", "==", user.uid),
//       orderBy("createdToSort", "desc")
//     );
//     const realofferQuerySnapshot = await getDocs(realofferQuery);
//     const offers = realofferQuerySnapshot.docs.map((doc) => ({
//       ...doc.data(),
//       id: doc.id, // Include the document ID in the offer object
//     }));

//     setOfferList(offers);
//     setOfferLoading(false);
//     /* if (!realofferQuerySnapshot.empty) {
//       const offers = realofferQuerySnapshot.docs.map((doc) => doc.data());
//       setOfferList(offers);
//     } */
//   };

//   useEffect(() => {
//     fetchOffers();
//   }, []);

//   const fetchCrowdSaleUnitData = async () => {
//     const auth = getAuth();
//     const user = auth.currentUser;
//     const docRef = doc(db, "users", user.uid);
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       setUser(docSnap.data());
//     } else {
//       console.log("No such document!");
//     }
//     const crowdSaleUnitQuery = query(
//       collection(db, "crowdSaleUnits"),
//       where("userId", "==", user.uid)
//     );
//     const crowdSaleUnitQuerySnapshot = await getDocs(crowdSaleUnitQuery);
//     const crowdSaleUnitList = crowdSaleUnitQuerySnapshot.docs.map((doc) => ({
//       ...doc.data(),
//       id: doc.id, // Include the document ID in the offer object
//     }));

//     setCrowdsaleUnitList(crowdSaleUnitList);
//   };
//   useEffect(() => {
//     fetchCrowdSaleUnitData();
//   }, [db]);

  // Debounce test

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  // Debounce test end

  useEffect(() => {
    const fetchFractionalOwnerships = async () => {
      const fractionalOwnershipQuery = query(
        collection(db, "fractionalOwnership")
      );
      const fractionalOwnershipQuerySnapshot = await getDocs(
        fractionalOwnershipQuery
      );
      const fractionalOwnerships = fractionalOwnershipQuerySnapshot.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id })
      );
      const crowdsaleData = await Promise.all(
        fractionalOwnerships.map(async (property) => {
          const crowdsaleQuery = query(
            collection(db, "crowdSaleUnits"),
            where("foId", "==", property.id)
          );
          const crowdsaleQuerySnapshot = await getDocs(crowdsaleQuery);
          return crowdsaleQuerySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            propertyId: property.propertyId, // Include propertyId for reference
          }));
        })
      );

      // Flatten the crowdsaleData array
      const flattenedCrowdsaleData = crowdsaleData.flat();

      // You can set the crowdsale data to state or handle it as needed
      setCrowdsaleList(flattenedCrowdsaleData);
      setFractionalOwnerships(fractionalOwnerships);
    };

    fetchFractionalOwnerships();
  }, [db]);

  useEffect(() => {
    const fetchDigitalAssets = async () => {
      const digitalAssetQuery = query(collection(db, "digitalAsset"));
      const digitalAssetQuerySnapshot = await getDocs(digitalAssetQuery);
      const digitalAssets = digitalAssetQuerySnapshot.docs.map((doc) =>
        doc.data()
      );
      setDigitalAssets(digitalAssets);
    };

    fetchDigitalAssets();
  }, [db]);
  const fetchCrowdSaleData = async () => {
    const crowdSaleQuery = query(collection(db, "crowdSale"));
    const crowdSaleQuerySnapshot = await getDocs(crowdSaleQuery);
    const crowdSale = crowdSaleQuerySnapshot.docs.map((doc) => doc.data());
    setCrowdSales(crowdSale);
  };
  useEffect(() => {
    fetchCrowdSaleData();
  }, [db]);
  useEffect(() => {
    const fetchCrowdSaleUnitsData = async () => {
      const crowdSaleUnitsQuery = query(collection(db, "crowdSaleUnits"));
      const crowdSaleUnitsQuerySnapshot = await getDocs(crowdSaleUnitsQuery);
      const crowdSaleUnits = crowdSaleUnitsQuerySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCrowdSaleUnits(crowdSaleUnits);
    };

    fetchCrowdSaleUnitsData();
  }, [db]);

  const fetchPaymentData = async () => {
    const paymentTransactionQuery = query(collection(db, "paymentTransaction"));
    const paymentTransactionQuerySnapshot = await getDocs(
      paymentTransactionQuery
    );
    const paymentTransaction = paymentTransactionQuerySnapshot.docs.map(
      (doc) => ({
        ...doc.data(),
        id: doc.id,
      })
    );

    setPaymentList(paymentTransaction);
  };
  useEffect(() => {
    fetchPaymentData();
  }, [db]);

 /*  useEffect(() => {
    const auth = getAuth();

    const fetchProperties = async (user) => {
      // query to include digitalAsset field
      const realPropertyQuery = query(
        collection(db, "realProperty"),
        where("published", "==", true)
      );

      const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

      if (!realPropertyQuerySnapshot.empty) {
        const properties = realPropertyQuerySnapshot.docs.map((doc) =>
          doc.data()
        );
        setProperties(properties);
        setHasRealProperty(true);
        setUserPropLoading(false);
      } else {
        setHasRealProperty(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProperties(user);
      } else {
        setHasRealProperty(false);
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [db]); */





  useEffect(() => {
    const auth = getAuth();
  
    const fetchProperties = async (user) => {
      try {
        setLoading(true)
        // Query to include digitalAsset field
        const realPropertyQuery = query(
          collection(db, "realProperty"),
          where("published", "==", true)
        );
  
        const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);
  
        if (!realPropertyQuerySnapshot.empty) {
          const properties = realPropertyQuerySnapshot.docs.map((doc) =>
            doc.data()
          );
          setProperties(properties);
          setHasRealProperty(true);
        } else {
          setHasRealProperty(false);
        }
      } catch (err) {
        console.error("Error fetching properties:", err);
      } finally {
        setUserPropLoading(false);
        setLoading(false)
      }
    };
  
    // Subscribe to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProperties(user);
      } else {
        setHasRealProperty(false);
        setUserPropLoading(false);
      }
    });
  
    // Cleanup the listener on component unmount
    return () => {
      unsubscribe();
    };
  }, [db]);
  
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.trim() !== "") {
      const newFilteredProperties = properties.filter(
        (property) =>
          property.name
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          property.city
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          property.pincode
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredProperties(newFilteredProperties);
    } else {
      setFilteredProperties([]);
    }
  }, [debouncedSearchTerm]);

  const toggleDetails = (propertyId) => {
    setDetailsOpen((prev) => (prev === propertyId ? null : propertyId));
    setLocationOpen(null);
    setCoinOpen(null);
    setIsConfirmBuyOpen(false);
    setIsConfirmBuyBlockChain(false);
    
  };
  
  const toggleOfferDetails = (propertyId) => {
    setofferdetailsopen((prev) => (prev === propertyId ? null : propertyId));
  };

  const toggleFractionalCoins = (propertyId) => {
    setfractionalOwnershipscoinsopen((prev) =>
      prev === propertyId ? null : propertyId
    );

    setfractionalOwnershipslocationsopen(null);
    setfractionalOwnershipdetailsopen(null);
  };
  const toggleFractionalDetails = (propertyId) => {
    setfractionalOwnershipdetailsopen((prev) =>
      prev === propertyId ? null : propertyId
    );
    setfractionalOwnershipscoinsopen(null);
    setfractionalOwnershipslocationsopen(null);
  };

  const toggleFractionalLocation = (propertyId) => {
    setfractionalOwnershipslocationsopen((prev) =>
      prev === propertyId ? null : propertyId
    );
    setfractionalOwnershipscoinsopen(null);
    setfractionalOwnershipdetailsopen(null);
  };

  const toggleCoin = (id) => {
    setCoinOpen((prev) => (prev === id ? null : id));
    setLocationOpen(null);
    setDetailsOpen(null);
    setIsConfirmBuyOpen(false);
    setIsConfirmBuyBlockChain(false);
  
  };

  const toggleLocation = async (propertyId) => {
    setLocationOpen((prev) => (prev === propertyId ? null : propertyId));
    setCoinOpen(null);
    setDetailsOpen(null);
    setIsConfirmBuyOpen(false);
    setIsConfirmBuyBlockChain(false);
    

    // smartlayout field from Firestore
    const propertyDocRef = doc(db, "realProperty", propertyId);
    const propertyDocSnap = await getDoc(propertyDocRef);

    if (propertyDocSnap.exists()) {
      setSmartLayout(propertyDocSnap.data().smartlayout);
    } else {
      console.log("No such document in realProperty collection!");
    }
  };

  //fractional ownership to digital Asset

  const addFractionalOwnership = async (digitalAsset) => {
    const db = getFirestore();

    const digitalAssetRef = await addDoc(
      collection(db, "digitalAsset"),
      digitalAsset
    );

    const fractionalOwnership = {
      digitalAssetId: digitalAssetRef.id,
    };

    const fractionalOwnershipRef = await addDoc(
      collection(db, "fractionalOwnership"),
      fractionalOwnership
    );

    await updateDoc(doc(db, "digitalAsset", digitalAssetRef.id), {
      fractionalOwnershipId: fractionalOwnershipRef.id,
    });
  };

  //fractional ownership to digital Asset end

  // const [properties, setProperties] = useState([]);
  const [propertyToDelete, setPropertyToDelete] = useState(null);

  const BusinessUserNameFunc = async () => {
   const auth = getAuth();
    const user = auth.currentUser; 
    const businessUserDocRef = doc(db, "businessUser", user?.uid );
    const businessUserDocSnap = await getDoc(businessUserDocRef);

    if (businessUserDocSnap.exists()) {
      return businessUserDocSnap.data().businessName;
    } else {
      console.log("No such document in businessUser collection!");
      return "";
    }
  };

  const fetchUserData = async () => {
    // const auth = getAuth();
    // const user = auth.currentUser;
    // const docRef = doc(db, "users", user.uid);
    // const docSnap = await getDoc(docRef);

    // if (docSnap.exists()) {
    //   setUser(docSnap.data());
    // } else {
    //   console.log("No such document!");
    // }
    try {
      /* const businessName = await BusinessUserNameFunc();
      setBusinessName(businessName); */
  
      const realPropertyQuery = query(
        collection(db, "realProperty"),
         where("published", "==", true),
        // where("businessId", "==", user.uid),
        orderBy("createdAt", "desc")
      );
      const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);
  
      if (!realPropertyQuerySnapshot.empty) {
        const properties = realPropertyQuerySnapshot.docs.map((doc) =>
          doc.data()
        );
  
        setProperties(properties);
        setHasRealProperty(true);
        setUserPropLoading(false);
      } else {
        setHasRealProperty(false);
      }
      
    } catch (error) {
       console.log(error)
    }
   
  };

  useEffect(() => {
    fetchUserData();
  }, [db]);

  const fetchfractionaldata = async () => {
    // const auth = getAuth();
    // const user = auth.currentUser;
    // const docRef = doc(db, "users", user.uid);
    // const docSnap = await getDoc(docRef);

    // if (docSnap.exists()) {
    //   setUser(docSnap.data());
    // } else {
    //   console.log("No such document!");
    // }
    // const realfractionalQuery = query(
    //   collection(db, "fractionalOwnership"),
    //   where("userId", "==", user.uid),
    //   orderBy("createdToSort", "desc")
    // );
    // const realfractionalQuerySnapshot = await getDocs(realfractionalQuery);
    // const fractional = realfractionalQuerySnapshot.docs.map((doc) => ({
    //   ...doc.data(),
    //   id: doc.id,
    // }));

    // setFracList(fractional);

    // Fetch data from crowdsaleunits collection where crowdsaleid equals property.propertyId
    // const crowdsaleData = await Promise.all(
    //   fractional.map(async (property) => {
    //     const crowdsaleQuery = query(
    //       collection(db, "crowdSaleUnits"),
    //       where("foId", "==", property.id)
    //     );
    //     const crowdsaleQuerySnapshot = await getDocs(crowdsaleQuery);
    //     return crowdsaleQuerySnapshot.docs.map((doc) => ({
    //       ...doc.data(),
    //       id: doc.id,
    //       propertyId: property.propertyId, // Include propertyId for reference
    //     }));
    //   })
    // );

    // Flatten the crowdsaleData array
    // const flattenedCrowdsaleData = crowdsaleData.flat();

    // You can set the crowdsale data to state or handle it as needed
    // setCrowdsaleList(flattenedCrowdsaleData);

    // setPropLoading(false);
  };

  useEffect(() => {
    fetchfractionaldata();
  }, [db]);

  // const fetchcustomeroffer = async () => {
  //   const auth = getAuth();
  //   const user = auth.currentUser;
  //   const docRef = doc(db, "users", user.uid);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     setUser(docSnap.data());
  //   } else {
  //     console.log("No such document!");
  //   }
  //   const realofferQuery = query(
  //     collection(db, "customerOffer"),
  //     where("customerId", "==", user.uid),
  //     orderBy("createdToSort", "desc")
  //   );
  //   const realofferQuerySnapshot = await getDocs(realofferQuery);
  //   const offers = realofferQuerySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id, // Include the document ID in the offer object
  //   }));

  //   setOfferList(offers);
  //   setOfferLoading(false);
  //   /* if (!realofferQuerySnapshot.empty) {
  //     const offers = realofferQuerySnapshot.docs.map((doc) => doc.data());
  //     setOfferList(offers);
  //   } */
  // };

  // useEffect(() => {
  //   fetchcustomeroffer();
  // }, [db, flag]);

  useEffect(() => {
    if (!isCheckboxChecked) {
      setIsWalletConnected(false);
    }
  }, [isCheckboxChecked]);

  useEffect(() => {
    fetchfractionaldata();
  }, [db]);

  const handleProfileClick = () => {
    setDropdownOpen(!dropdownOpen);
    if (notificationOpen) {
      setNotificationOpen(false);
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };
  const handleEditClick = (property) => {
    setIsEditing(true);
    setEditedProperty({
      ...property,
      totalPlots: property.totalPlots || "",
      soldPlots: property.soldPlots || "",
    });
    setEditedCity(property.city);
    setEditedPropertyType(property.propertyType);
    setEditedTotalArea(property.totalArea);
    setEditedTotalPlots(property.totalPlots);
    setEditedSoldPlots(property.soldPlots);
  };

  const handleSaveClick = async (property) => {
    const propertyDocRef = doc(db, "realProperty", property?.propertyId);
    let updatedData = {
      city: editedCity,
      propertyType: editedPropertyType,
      totalArea: editedTotalArea,
    };
    if (editedTotalPlots !== undefined) {
      updatedData.totalPlots = editedTotalPlots;
    }
    if (editedSoldPlots !== undefined) {
      updatedData.soldPlots = editedSoldPlots;
    }
    await updateDoc(propertyDocRef, updatedData);
    setIsEditing(false);

    // update properties state
    setProperties((prevProperties) =>
      prevProperties.map((prevProperty) => {
        if (prevProperty?.propertyId === property?.propertyId) {
          return { ...prevProperty, ...updatedData };
        } else {
          return prevProperty;
        }
      })
    );
  };

  const handleBlockChainBuy = async () => {
    setIsConfirmBuyBlockChain(true);
    setIsConfirmBuyOpen(false);
  };

  const handleBuy = async () => {
    if (isWalletConnected) {
      setIsConfirmBuyOpen(true);
      setIsConfirmBuyBlockChain(false);
    } else {
      alert("Wallet is not connected! Connect your Wallet First");
    }

    // if (window.ethereum) {
    //   try {
    //     await window.ethereum.enable();
    //     setIsWalletConnected(true);
    //     console.log(isWalletConnected)
    //   } catch (error) {
    //     console.error("User denied account access");
    //   }
    // } else {
    //   console.log("Non-Ethereum browser detected. Try MetaMask");
    // }
    // if (window.ethereum) {

    // try {
    //   await window.ethereum.enable();
    //  console.log(check);
    //  if (!isWalletConnected){

    //   setIsWalletModalOpen(true);

    // } else{
    // setIsWalletConnected(true);
    // console.log(isWalletConnected);
    // }
    // Exit the loop once the wallet is connected
    // } catch (error) {
    //   console.error("User denied account access, retrying...");
    //   await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
    // }

    // } else {
    //   console.log("Non-Ethereum browser detected. Try MetaMask");

    // }
    /* if (!isWalletConnected) {
      if (isWalletConnected) {
        setIsConfirmBuyOpen(true);
        setIsWalletModalOpen(false)


      } else {
        console.log('Wallet is not connected');
        setIsWalletModalOpen(true);
        console.log('Wallet modal opened');
        // console.log('Wallet is not connected');
        // setIsWalletModalOpen(true)
        //   .then(() => {
        //     console.log('Wallet modal opened');
        //   })
        //   .catch((error) => {
        //     console.error('Error opening wallet modal:', error);
        //   });
        //setIsWalletModalOpen(true)

        // handleYesClick(property);

      }
    } else {

     console.log("checkbox")
     setIsWalletConnected(false);
     console.log("checkbox 1 ")
     setIsConfirmBuyOpen(false);
     setIsWalletModalOpen(false);
      console.log("checkbox 2 ")
     if (setIsWalletConnected) {
      console.log("checkbox 2 ")
      
      console.log("wallet is not connected")
      setIsConfirmBuyOpen(false);
      setIsWalletModalOpen(false);
     } else {
      setIsConfirmBuyOpen(false);
      setIsWalletModalOpen(true);

     }


    } */
  };

  const handlecheck = async () => {
    setIsCheckboxChecked((prevState) => {
      if (!prevState) {
        // If the checkbox is currently unchecked, open the modal
        setIsWalletModalOpen(true);
        // Keep the checkbox unchecked until the user confirms
        return false;
      } else {
        // If the checkbox is currently checked, simply uncheck it
        setIsCheckboxChecked(false);
        console.log("wallet disconnected");
        setIsConfirmBuyOpen(false);

        return !prevState;
      }
    });
  };

  const handleBuyYes = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        setIsWalletConnected(true);
        setNoBlockChain(false);
        if (!setIsWalletConnected) {
          console.log("not connected to wallet");
        }
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      console.log("Non-Ethereum browser detected. Try MetaMask");
    }

    setIsWalletModalOpen(false);
    console.log("model is closed");
    console.log("wallet is connected");
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = await accounts[0];
    console.log("wallet address", account);

    setIsConfirmBuyOpen(true);
    setIsCheckboxChecked(true);
  };

  const Buy = async (property) => {
    setFlag(!flag);
    if (!quantity && !price) {
      setQuantityError("Quantity must not be empty");
      setPriceError("Price must not be empty");
      return;
    }
    if (!quantity) {
      setQuantityError("Quantity must not be empty");
      return;
    }
    if (!price) {
      setPriceError("Price must not be empty");
      return;
    }
    if (quantityError || priceError) {
      console.log("Please fill in both Quantity and Price fields.");
      return;
    }

    if (property) {
      const existingOffer = offerList.find(
        (offer) =>
          offer.propertyId === property.propertyId &&
          offer.status !== "CANCEL" &&
          offer.status !== "REJECT" &&
          offer.status !== "COMPLETE"
      );

      if (existingOffer) {
        alert("Offer already exists");
        return;
      }
      const matchingProperty = digitalAssets.find(
        (prop) => prop.propertyId === property.propertyId
      );

      // Creating a customer offer document
      const customerOfferDoc = {
        createdAt: new Date().toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        createdToSort: new Date(),
        // customerId: User.uid,
        expiresAt: new Date(
          Date.now() + 7 * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }), // 1 week from now
        fractionalValue: Number(quantity) * 1000000000000000000,
        message: "",
        offerCurrency: "ETH",
        offerPrice: Number(price) * 1000000000000000000,
        propertyId: property.propertyId,
        status: "ACTIVE",
        tokenName: matchingProperty.tokenName,
      };

      const customerOfferDocRefAdded = await addDoc(
        collection(db, "customerOffer"),
        customerOfferDoc
      );

      // Create a new offer object with the added ID
      const newOffer = { ...customerOfferDoc, id: customerOfferDocRefAdded.id };
      setPropId(customerOfferDocRefAdded.id);

      // Directly adding the new offer to the offerList
      setOfferList((prevList) => [...prevList, newOffer]);

      setIsCreatingAsset(false);
      fetchUserData();
      setSelectedSection("Offers");

      setoffername(property.name);
      setFilteredProperties([]);
      console.log(newOffer.fractionalValue);

      setQuantity("");
      setPrice("");
      console.log(newOffer);

      // Call handleYesClick with the newly created offer object
      handleYesClick(newOffer);
    }
  };

  const handlewallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        // setIsWalletConnected(true);
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      alert("Non-Ethereum browser detected. Try MetaMask");
      window.open("https://metamask.io/download/");
    }
  };

  const handleCompleteOffer = async (property) => {
    /* const contractAddress = ThirdEye_Market_Contract; // thirdeyeMarketContract new

    if (window.ethereum) {
      if (!isWalletConnected) {
        console.log("wallet is not connected");
      } else {
        try {
          await window.ethereum.enable();
          setIsWalletConnected(true);
        } catch (error) {
          console.error("User denied account access");
          return;
        }
      }
    } else {
      console.log("Non-Ethereum browser detected. Try MetaMask");
      return;
    }

    // Check Polygon Testnet
    try {
      const networkVersion = await window.ethereum.request({
        method: "net_version",
      });
      if (networkVersion !== "80002") {
        console.error("Please switch to the Polygon Testnet");
        return;
      }
    } catch (error) {
      console.error("Error checking network version:", error);
      return;
    }

    // Initialize web3
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      // try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];

      const agreementId = offerId;

      console.log("account: ", account);
      console.log("contract address: ", contractAddress);
      console.log("offerId: ", agreementId);
      const args = [agreementId];
      const data = web3.eth.abi.encodeFunctionCall(
        {
          name: "depositToken2",
          type: "function",
          inputs: [
            {
              type: "string",
              name: "agreementId",
            },
          ],
        },
        args
      );

      const tx = {
        from: account,
        to: contractAddress,
        data: data,
      };

      console.log("Prepared transaction:", tx);

      const gasEstimate = await web3.eth.estimateGas(tx);
      tx.gas = gasEstimate;
     
      const trnx = await web3.eth.sendTransaction(tx);
      console.log("Transaction successful:", trnx);
     
    } */

    const fractionalOwnershipDocRef = doc(
      db,
      "fractionalOwnership",
      property.propertyId
    );
    const fractionalOwnershipDocSnap = await getDoc(fractionalOwnershipDocRef);

    if (fractionalOwnershipDocSnap.exists()) {
      console.log(
        "A document already exists in the fractionalOwnership collection for this property"
      );
      return;
    }

    const digitalAssetDocRef = doc(db, "digitalAsset", property.propertyId);
    const digitalAssetDocSnap = await getDoc(digitalAssetDocRef);

    if (!digitalAssetDocSnap.exists()) {
      console.error("No digital asset found for this property");
      return;
    }

    const digitalAsset = digitalAssetDocSnap.data();
    const matchingProperty = properties.find(
      (prop) => prop.propertyId === property.propertyId
    );
    const matchingasset = digitalAssets.find(
      (prop) => prop.propertyId === property.propertyId
    );

    const fractionalOwnershipDoc = {
      createdAt: new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      createdToSort: new Date(),
      digitalAssetId: digitalAsset.propertyId,
      propertyId: property.propertyId,
      propertyName: matchingProperty ? matchingProperty.name : "",
      tokenName: digitalAsset.tokenName,
      totalOwned: property.fractionalValue,
      totalTokens: matchingasset.totalTokens,
    //   userId: User.uid,
    };

    const fractionalOwnershipDocRefAdded = await addDoc(
      collection(db, "fractionalOwnership"),
      fractionalOwnershipDoc
    );

    // Create a new fractional object with the added ID
    const newFract = {
      ...fractionalOwnershipDoc,
      id: fractionalOwnershipDocRefAdded.id,
    };

    // Directly adding the new fract to the fracList
    setFracList((prevList) => [...prevList, newFract]);

    const offerRef = doc(db, "customerOffer", property.id); // Use dynamic document ID
    await updateDoc(offerRef, { status: "COMPLETE" });
    // await fetchOffers();

    setIsCreatingAsset(false);
    fetchUserData();
    setSelectedSection("Properties");

    console.log(offerList);
    setFilteredProperties([]);
    setofferdetailsopen(null);
  };

  const handleYesClick = async (property) => {
    const matchingProperty = digitalAssets.find(
      (prop) => prop.propertyId === property.propertyId
    );

    //initialize web3
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log(account);
      const party1 = matchingProperty.walletAddress; //business address digital add wallet
      const party2 = account[0]; //my wallet address
      const token1 = matchingProperty.tokenAddress; //token contract address given digital asset
      const token2 = "0x0000000000000000000000000000000000001010"; //given
      const amount1 = await property.fractionalValue;
      const amount2 = await property.offerPrice;
      const memory_orderId = await property.id;

      console.log(isWalletConnected);

      if (isWalletConnected) {
        console.log("party1", party1);
        console.log("party2", party2);
        console.log("token1", token1);
        console.log("token2", token2);
        console.log("amount1", amount1);
        console.log("amount2", amount2);
        console.log("memory_orderId", memory_orderId);

        const args = [
          party1,
          party2,
          token1,
          token2,
          amount1,
          amount2,
          memory_orderId,
        ];
        const data = web3.eth.abi.encodeFunctionCall(
          {
            name: "initiateAgreement",
            type: "function",
            inputs: [
              { type: "address", name: "_party1" },
              { type: "address", name: "_party2" },
              { type: "address", name: "_token1" },
              { type: "address", name: "_token2" },
              { type: "uint256", name: "_amount1" },
              { type: "uint256", name: "_amount2" },
              { type: "string", name: "_orderId" },
            ],
          },
          args
        );
        const tx = {
          from: account[0],
          to: ThirdEye_Market_Contract, // thirdeye
          data: data,
        };
        try {
          const transactionReceipt = await web3.eth.sendTransaction(tx);
          console.log(transactionReceipt);

          if (!transactionReceipt) {
            alert("Transaction failed");
            setSelectedSection(null);
            setIsTransaction(false);
            await deleteOfferFromDb(property.id); // Delete offer from db
          } else {
            alert("Transaction successful");
          }
        } catch (error) {
          alert(
            "MetaMask Tx Signature: User denied transaction signature. it is rejected"
          );
          setSelectedSection(null);
          setIsTransaction(false);
          await deleteOfferFromDb(property.id); // Delete offer from db
        }
      }
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      console.log(web3);
    } else {
      console.log(
        "No Ethereum interface found in your browser. Try MetaMask or Brave Wallet."
      );
    }
  };

  const deleteOfferFromDb = async (offerId) => {
    try {
      await deleteDoc(doc(db, "customerOffer", offerId));
      setOfferList((prevList) =>
        prevList.filter((offer) => offer.id !== offerId)
      );
    } catch (error) {
      console.error("Error deleting offer from db:", error);
    }
  };

  const handleNoClick = () => {
    setIsWalletModalOpen(false);
    setIsCheckboxChecked(false);
  };

  const handleRetryTransactionYes = async () => {
    try {
      const docRef = doc(db, "customerOffer", propId);
      await deleteDoc(docRef);
      console.log(`Document with ID ${propId} has been deleted`);
      setIsTransaction(true);
      setIsConfirmBuyOpen(false);
      setSelectedSection(null);
      setPropId(null);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleRetryTransactionNo = async () => {
    //logic to delete offer using offerid that is docid
    try {
      const docRef = doc(db, "customerOffer", propId);
      await deleteDoc(docRef);
      console.log(`Document with ID ${propId} has been deleted`);
      setIsTransaction(true);
      setIsConfirmBuyOpen(false);
      setSelectedSection("Properties");
      setPropId(null);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleWalletCategory = () => {
    setIsWalletCategory(true);
    setNoBlockChain(false);
  };

  // const handlecost =(e)=>{
  //   const val= e.tartget.value
  //   setUnitAmount(val);

  // }

  // const handlecost = (e,sale) => {
  //   const units = e.target.value;

  //   const totunt = Number(Number(units) * Number(sale.sellingRate))
  //   setCost(totunt)
  // //  setCost(Number(units) * Number(sale.sellingRate));
  // setUnitAmount(units);
  // };

  // const handlecost = (e, sale) => {
  //   console.log(sale)
  //   const units = e.target.value;
  // console.log("checking selling rate",sale.sellingRate)
  //   // Check if sale and sale.sellingRate are defined
  //   if (sale && sale.sellingRate !== undefined) {
  //     const totunt = Number(Number(units) * Number(sale.sellingRate));
  //     setCost(Number(totunt));
  //   } else {
  //     alert("cost issuess")
  //     setCost(0)
  //   }

  //   setUnitAmount(units);
  // };

  const handlecost = (e, sale) => {

    const value = e.target.value;

    // Allow null or empty values (i.e., no alert for these cases)
  if (value === "") {
    setUnitAmount(""); // Set the unit amount to an empty string
    setCost(0); // Set the cost to 0 or any default value
    setActualDiscount(0);
    return;
  }

  // Allow entering 0, but skip the alert
  if (value === "0") {
    setUnitAmount("0");
    setCost(0);
    setActualDiscount(0);
    return;
  }
    if (!/^\d+$/.test(value)) {
      alert("Please enter a valid positive integer value.");
      return;
    }
    if (Number(value) > Number(sale.sellingArea)) {
      alert("should not greater than selling units");
      return;
    }

    // fetchCrowdSaleUnitData();
    // Log the sale object for debugging
    console.log("sale object:", sale);


    



    const units = e.target.value;
    console.log("Units entered:", units);
    // only if !! shoulbe grethan zero 
   if (sale.maxDiscount>0 && sale.maxDiscountUnits >0)
   {
    if (Number(units) >= Number(sale.maxDiscountUnits)){
      console.log("units are greater than max discountunits")
      //multi by 1 df 
      console.log("discount",sale.maxDiscount)
      const mx = Number(sale.maxDiscount )/100   //percentage
      console.log("discount mx applied",mx)
  
      
  
      const df = Number(1 * Number(mx) )
      console.log("discounr factor entered:", df);
      setDiscountFactor(df)


    }else{
      console.log("let unots less than maxdiscount units")
      console.log("discount",sale.maxDiscount)
      const mx = Number(sale.maxDiscount )/100
      console.log("discount mx applied",mx)
  
      
  
      const df = Number(Number(Number(units)/Number(sale.maxDiscountUnits) )* Number(mx) )
      console.log("max discount unots", sale.maxDiscountUnits)
      console.log("discounr factor entered:", df);
      setDiscountFactor(df)


    }

  }

    //discount factor
    // console.log("discount",sale.maxDiscount)
    // const mx = Number(sale.maxDiscount )/100
    // console.log("discount mx applied",mx)

    

    // const df = Number(Number(Number(units)/25 )* Number(mx) )
    // console.log("discounr factor entered:", df);
    // setDiscountFactor(df)


    // Check if sale is defined and has the property 'sellingRate'
    if (sale && typeof sale.sellingRate !== "undefined") {
      //const totunt = Number(Number(units) * Number(sale.sellingRate) );
      const totunt = Number(
        (Number(units) * Number(sale.sellingRate) *(1- discountFactor )).toFixed(2)
      );
      console.log("totalunt cost  ",totunt)
      setCost(totunt); // Update the cost based on units and sellingRate
      const actualdf = Number (Number(Number(units) * Number(sale.sellingRate) ) - Number(totunt)).toFixed(2)
      console.log("actual df ",actualdf)
      setActualDiscount(actualdf);
      
    } else {
      console.error("Error: sale or sale.sellingRate is undefined");
      alert("There is an issue with the sale or selling rate.");
      setCost(0); // Set cost to 0 in case of error
      setActualDiscount(0)
    }

    setUnitAmount(units); // Set the unit amount entered


    
  };

  // const handletotalamount = (sale)=>{
  //     const totunt = Number(Number(unitAmount) * Number(sale.sellingRate))
  //     setCost(totunt)

  // }


  //handleDetails page open 

  const handleDetails=(property)=>{
    
    const formattedTitle = property.name.replace(/\s+/g, '-');
    navigate(`/${formattedTitle}`);
  }

  const handleDeleteAccount = async () => {
    if (window.confirm("Do you really want to delete your account?")) {
      const auth = getAuth();
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const businessUserDocRef = doc(db, "businessUser", user.uid);

      const realPropertyQuery = query(
        collection(db, "realProperty"),
        where("businessId", "==", user.uid)
      );
      const realPropertyQuerySnapshot = await getDocs(realPropertyQuery);

      if (!realPropertyQuerySnapshot.empty) {
        const storage = getStorage();

        realPropertyQuerySnapshot.docs.forEach(async (doc) => {
          const propertyData = doc.data();
          const planCopyUrl = propertyData.planCopy;

          if (planCopyUrl) {
            const storageRef = ref(storage, planCopyUrl);

            deleteObject(storageRef)
              .then(() => {
                console.log("File deleted successfully");
              })
              .catch((error) => {
                console.log(
                  "No file to delete or error deleting file: ",
                  error
                );
              });
          }

          await deleteDoc(doc.ref).catch((error) => {
            console.error("Error deleting realProperty document: ", error);
          });
        });
      }

      await deleteDoc(businessUserDocRef).catch((error) => {
        console.error("Error deleting businessUser document: ", error);
      });

      await deleteDoc(userDocRef).catch((error) => {
        console.error("Error deleting user document: ", error);
      });

      user
        .delete()
        .then(() => {
          console.log("User account deleted");
          navigate("/");
        })
        .catch((error) => {
          console.error("Error deleting user account: ", error);
        });
    }
  };

//   if (!user) {
//     return (
//       <div className="flex justify-center items-center h-screen">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500">
//           {" "}
//         </div>
//       </div>
//     );
//   }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Current date in YYYY-MM-DD format
  const presentDate = formatDate(new Date());
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500">
          {" "}
        </div>
      </div>
    );
  }
  
 
  return (
    <div className="antialiased">
      <Header>
        <Nav>
          <div className="flex justify-start items-center">
            <Logo src={logothirdeye} alt="Thirdeye"   onClick={()=>navigate("/")}/>
          </div>
          <div className="flex items-center lg:order-2 relative">
            
            

            <Tooltip
              title="Email to
contact@saralam.tech
for any queries"
            >
              <HelpOutlineIcon>contact us</HelpOutlineIcon>
            </Tooltip>
           
          </div>
        </Nav>
      </Header>

      <div className="flex  pt-16">
       

        <div className="ml-4/5 w-4/5 p-5 rounded-lg text-center my-0 shadow-lg mx-auto  bg-white mt-5 min-h-[500px] overflow-auto">
        {properties.length==0?(<h1>No Available Properties</h1>):(<div>
          <SearchInputWrapper>
              <SearchInput
                type="text"
                placeholder="🔎Find Properties by Project Name or City or Pincode"
                value={searchTerm}
                onFocus={() => setSelectedSection(null)}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchInputWrapper>
            {selectedSection === null && filteredProperties.length < 1 && !searchTerm ? (
              <div className="bg-gray-">
                <h1 className="text-2xl font-bold mb-4 mt-2">
                  Available Properties To Invest
                </h1>

                {properties.map((property, index) => {
                  const matchingDocs = landRewardList.filter(doc => doc.projectId === property.propertyId);
                  return (
                  <div
                    key={index}
                    className="h-auto w-auto my-1 border-b border-gray-200"
                  >
                    <PropertyContainer>
                      <PropertyDetails>
                        <PropertyNameWrapper>
                          <PropertyName>{property.name}</PropertyName>
                          {property && property.verified ? (
                            <Tooltip title="verified property">
                              <Logo1 src={green_verified} alt="verify" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Not verified property">
                              <Logo1 src={red_not_verified} alt="not verify" />
                            </Tooltip>
                          )}
                        </PropertyNameWrapper>
                        {/* <PropertyName>{property.name}{property.verified?<Tooltip title="  verified property">
                            <Logo1
                            src={green_verified} alt="verify" />
                            
                          </Tooltip>:<Tooltip title="  verified property">
                          <Logo1 src={red_not_verified} alt="not verify" />
                            
                          </Tooltip>}</PropertyName> */}
                       <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
                        <div style={{textAlign:"left"}}>{property?.city}</div>
                        <div style={{display:"flex",gap:"5px",alignItems:"center"}}>
                        <div style={{whiteSpace:"nowrap"}}>LANDs Remaining : {property?.LANDs} </div>
                        <div style={{alignSelf:"center"}}>
                          <FaGift title="Earn LAND" onClick={()=>handleDetails(property)}/>
                        </div>
                        </div>
                        </div>
                      </PropertyDetails>
                      <IconRow>
                      {/* <Icon title="See Report"
                          src={icoreport}
                          alt="Report"
                          className={
                            pressedReportId ===
                                        property.propertyId &&
                                      reportOpen === property.propertyId
                                        ? "opacity-50"
                                        : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  
                                      toggleReport(property.propertyId);
                                            setPressedReportId(
                                              pressedReportId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        /> */}
                        <Icon title="See Details"
                          src={icodetails}
                          alt="Details"
                          className={
                            pressedDetailsId === property.propertyId &&
                            detailsOpen === property.propertyId
                              ? "active"
                              : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  toggleDetails(property.propertyId);
                                  setPressedDetailsId(
                                    pressedDetailsId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        />
                        {/*   <Icon
                          src={icocoin}
                          alt="Coin"
                          className={ 
                            pressedCoinId === property.propertyId &&
                            coinOpen === property.propertyId
                              ? "active"
                              : ""
                             
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  toggleCoin(property.propertyId);
                                  setPressedCoinId(
                                    pressedCoinId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        /> */}
                        <Icon title="Book Now"
                          src={icocoin}
                          alt="Coin"
                          className={`
    
  ${
    !property.crowdSale && !property.digitalAsset
      ? "opacity-60 cursor-not-allowed"
      : ""
  }`}
                          style={{
                            pointerEvents:
                              !property.crowdSale && !property.digitalAsset
                                ? "none"
                                : "auto",
                          }}
                          onClick={
                            isEditing ||
                            (!property.crowdSale && !property.digitalAsset)
                              ? null
                              : () => {
                                  toggleCoin(property.propertyId);
                                  setPressedCoinId(
                                    pressedCoinId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        />
                        <Icon title="See Location"
                          src={icolocation}
                          alt="Location"
                          className={
                            pressedLocationId === property.propertyId &&
                            locationOpen === property.propertyId
                              ? "active"
                              : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  toggleLocation(property.propertyId);
                                  setPressedLocationId(
                                    pressedLocationId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        />
                      </IconRow>
                    </PropertyContainer>
                    {detailsOpen === property.propertyId && (
                      <Container1>
                        <Grid>
                          <InputGroup>
                            <Label>City</Label>
                            <Input
                              type="text"
                              value={isEditing ? editedCity : property.city}
                              readOnly={!isEditing}
                              onChange={(e) => setEditedCity(e.target.value)}
                            />
                          </InputGroup>
                          {isEditing ? (
                            <InputGroup>
                              <Label>Type</Label>
                              <Select
                                value={editedPropertyType}
                                onChange={(e) =>
                                  setEditedPropertyType(e.target.value)
                                }
                              >
                                <option value="">Select...</option>
                                <option value="Commercial Building">
                                  Commercial Building
                                </option>
                                <option value="Agriculture Land">
                                  Agriculture Land
                                </option>
                                <option value="Independent House">
                                  Independent House
                                </option>
                                <option value="Residential Apartment">
                                  Residential Apartment
                                </option>
                              </Select>
                            </InputGroup>
                          ) : (
                            <InputGroup>
                              <Label>Type</Label>
                              <Input
                                type="text"
                                value={property.propertyType}
                                readOnly
                              />
                            </InputGroup>
                          )}

                          <InputGroup>
                            <Label>Total Area</Label>
                            <Input
                              type="text"
                              value={
                                isEditing ? editedTotalArea : property.totalArea
                              }
                              readOnly={!isEditing}
                              onChange={(e) =>
                                setEditedTotalArea(e.target.value)
                              }
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Total Plots</Label>
                            <Input
                              type="text"
                              value={
                                isEditing
                                  ? editedTotalPlots
                                  : property.totalPlots
                              }
                              readOnly={!isEditing}
                              onChange={(e) =>
                                setEditedTotalPlots(e.target.value)
                              }
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Sold Plots</Label>
                            <Input
                              type="text"
                              value={
                                isEditing ? editedSoldPlots : property.soldPlots
                              }
                              readOnly={!isEditing}
                              onChange={(e) =>
                                setEditedSoldPlots(e.target.value)
                              }
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Digital Asset</Label>
                            <Input
                              type="text"
                              value={property.digitalAsset ? "Yes" : "No"}
                              readOnly
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Verified</Label>
                            <Input
                              type="text"
                              value={property.verified ? "Yes" : "No"}
                              readOnly
                            />
                          </InputGroup>
                        </Grid>
                      </Container1>
                    )}
                    {/* {reportOpen === property.propertyId && (
                                              <Container>
                                                
                                                <div className="reward-details-card__grid-container">
                                                          
                                                          <div className="reward-details-card__font-medium"  >Date</div>
                                                          <div className="reward-details-card__font-medium"  >User</div>
                                                          <div className="reward-details-card__font-medium"  >Referrer</div>
                                                          <div className="reward-details-card__font-medium" >LANDs</div>
                                                          <div className="reward-details-card__font-medium"  >Activity</div>
                                                          {matchingDocs 
                                                  .sort((a, b) => {
                                                    // Ensure both `createdAt` fields are valid and compare their timestamps
                                                    const dateA = a.createdAt?.toDate() || new Date(0); // Default to epoch if undefined
                                                    const dateB = b.createdAt?.toDate() || new Date(0);
                                                    return dateA - dateB; // Ascending order: oldest first, newest last
                                                  })
                                                  .map((reward, index) => {
                                                    const matchingUserDoc=userList.find(doc=>doc?.id===reward?.userId)
                                                   return <React.Fragment key={reward.userId || index}>
                                                      <div>{reward.createdAt?.toDate().toLocaleDateString() || "N/A"}</div>
                                                      <div>{matchingUserDoc?.mobile || "N/A"}</div>
                                                      <div>{reward?.referredUserId || "N/A"}</div>
                                                      <div>{reward?.LANDs || 0}</div>
                                                      <div>{reward?.activity || "NA"}</div>
                                                    </React.Fragment>
                                      })}
                                                
                                                        </div>
                                                 
                                                 
                                               
                                              </Container>
                                            )} */}
                    {coinOpen === property.propertyId && (
                      <>
                        {property.crowdSale &&
                          (() => {
                            const sale = crowdSales.find(
                              (sale) => sale.propertyId === property.propertyId
                            );

                            if (sale) {
                              return (
                                <div
                                  className="w-full p-5 rounded-lg text-center"
                                  style={{ marginTop: "12px" }}
                                >
                                  <h2 className="font-bold">Use Paper</h2>
                                  {!isConfirmBuyBlockChain ? (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Total Units</Label>
                                          <Input
                                            type="text"
                                            value={sale.totalArea}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Selling Units</Label>
                                          <Input
                                            type="text"
                                            value={sale.sellingArea}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Available Units</Label>
                                          <Input
                                            type="text"
                                            value={sale.availableUnits}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Base Currency</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? String(sale.baseCurrency)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Market Rate</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? String(sale.marketRate)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Selling Rate</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? Number(sale.sellingRate).toFixed(2)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Sale Start Date</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale ? String(sale.saleStart) : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Sale End Date</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale ? String(sale.saleEnd) : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Target Amount</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? String(sale.minSaleAmount)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Current Sale Amount</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? Number(sale.currentSaleAmount).toFixed(2)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Status</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale ? String(sale.status) : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                      </Grid>

                                      {console.log(
                                        sale.startDate > presentDate
                                      )}
                                      {console.log(presentDate)}
                                      {console.log(formatDate)}

                                      {/* {(Number(sale.availableUnits) > 0) ? (
                                        (sale.startDate <= presentDate) ? (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                            onClick={handleBlockChainBuy} // Function used here
                                          >
                                            Buy
                                          </button>
                                        ) : (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                            disabled
                                          ></button>
                                        )
                                      ) : (
                                        <button
                                          className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                          disabled
                                        ></button>
                                      )} */}
                                     
                                      
                                      {Number(sale.availableUnits) > 0 ? (
                                       ( (sale.saleStart )<=( presentDate) )? (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                            // onClick={handleBlockChainBuy} // Function used here
                                            onClick={()=>navigate("/auth")}
                                          >
                                            Book Now
                                          </button>
                                        ) : (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                            disabled
                                          >
                                            Sale hasn't started yet
                                          </button>
                                        )
                                      ) : (
                                        <button
                                          className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                          disabled
                                        >
                                          No Available Units
                                        </button>
                                      )}
                                    </Container1>
                                  ) : (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Total Units</Label>
                                          <Input
                                            type="text"
                                            onChange={(e) =>
                                              handlecost(e, sale)
                                            }
                                            value={unitAmount}
                                            title="Decimal values  are not accepted"
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Cost</Label>
                                          <Input
                                            type="text"
                                            value={cost}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Discount</Label>
                                          <Input
                                            type="text"
                                            value={actualDiscount}
                                            readOnly
                                          />
                                        </InputGroup>
                                      </Grid>
                                      <button
                                        // onClick={() => {
                                        //   handlePayment(sale, cost);
                                        // }}
                                        disabled={isProcessing}
                                        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                      >
                                        Pay Now
                                       
                                      </button>
                                      <button
                                       
                                      // disabled={isProcessing}
                                      
                                       className="px-4 py-2 mt-4 ml-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                     >
                                      Pay Later
                                      
                                     </button>
                                     { isProcessing && (
                                       <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                                       <div className="p-5  rounded-lg text-center shadow-lg">
                                         <div className="loader mb-4"></div> {/* Loader animation */}
                                         
                                         <p style={{color:"white"}}>Please wait for a few seconds...</p>
                                       </div>
                                     </div>
                                     )}
                                     <style jsx>{`
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`}</style>


                                    </Container1>
                                  )}
                                </div>
                              );
                            }
                          })()}
                        {property.digitalAsset &&
                          (() => {
                            const asset = digitalAssets.find(
                              (asset) =>
                                asset.propertyId === property.propertyId
                            );
                            if (asset) {
                              return (
                                <div
                                  className="w-full p-5 rounded-lg text-center"
                                  style={{ marginTop: "12px" }}
                                >
                                  <h2 className="font-bold">Paperless</h2>
                                  {!isConfirmBuyOpen ? (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Blockchain</Label>
                                          <Input
                                            type="text"
                                            value={asset.blockchain}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Total Area</Label>
                                          <Input
                                            type="text"
                                            value={asset.TotalArea}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Token Name</Label>
                                          <Input
                                            type="text"
                                            value={asset.tokenName}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Base Price</Label>
                                          {/* <input type="text" className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" value={asset.tokenTokens} readOnly /> */}
                                          <Input
                                            type="text"
                                            value={
                                              asset
                                                ? String(asset.basePrice)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Base Currency</Label>
                                          {/* <input type="text" className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" value={asset.tokenTokens} readOnly /> */}
                                          <Input
                                            type="text"
                                            value={
                                              asset
                                                ? String(asset.baseCurrency)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                      </Grid>
                                      <button
                                        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                        onClick={()=>navigate("/auth")}
                                      >
                                        Book Now
                                      </button>
                                    </Container1>
                                  ) : (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Enter Quantity</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={quantity}
                                            onChange={(e) =>
                                              handleQuantityChange(
                                                e,
                                                asset.totalTokens
                                              )
                                            }
                                          />
                                          {quantityError && (
                                            <p className="text-red-500 text-xs mt-1">
                                              {quantityError}
                                            </p>
                                          )}
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Available</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={asset.totalTokens}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Token Name</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={asset.tokenName}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Price</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={price}
                                            onChange={handlePriceChange}
                                          />
                                          {priceError && (
                                            <p className="text-red-500 text-xs mt-1">
                                              {priceError}
                                            </p>
                                          )}
                                        </InputGroup>
                                      </Grid>

                                      <button
                                        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                        onClick={() => Buy(property)}
                                      >
                                        Confirm Offer
                                      </button>
                                    </Container1>
                                  )}
                                </div>
                              );
                            }
                          })()}
                      </>
                    )}

                    {!isTransaction && (
                      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded-md shadow-md">
                          <p>Do you want to Retry Again?</p>
                          <div className="mt-4 flex justify-end">
                            <button
                              onClick={handleRetryTransactionYes}
                              className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 mr-2"
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => handleRetryTransactionNo()}
                              className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {locationOpen === property.propertyId && (
                      <ResponsiveContainer>
                        <ResponsiveIframeWrapper>
                          <ResponsiveIframe
                            src={smartLayout}
                            allow="geolocation"
                            sandbox="allow-same-origin allow-scripts allow-modals"
                          ></ResponsiveIframe>
                        </ResponsiveIframeWrapper>

                        <SectionTitleWrapper>
                          <SectionTitle
                            onClick={() => window.open(smartLayout, "_blank")}
                          >
                            Open in new Tab
                          </SectionTitle>
                          <SectionDivider />
                          <SectionTitle>Plan</SectionTitle>
                          <SectionDivider />
                        </SectionTitleWrapper>

                        <SectionTitleWrapper>
                          {property.planCopy ? (
                            <PlanLink
                              onClick={() =>
                                window.open(property.planCopy, "_blank")
                              }
                            >
                              click here
                            </PlanLink>
                          ) : (
                            <NoPlanText>No Plan Copy</NoPlanText>
                          )}
                        </SectionTitleWrapper>

                        <SectionTitleWrapper>
                          <SectionDivider />
                          <SectionTitle>Images</SectionTitle>
                          <SectionDivider />
                        </SectionTitleWrapper>

                        <ImageWrapper>
                          {property.Image1Copy ||
                          property.Image2Copy ||
                          property.Image3Copy ? (
                            <>
                              {property.Image1Copy && (
                                <ImageContainer
                                  isExpanded={
                                    expandedImage === property.Image1Copy
                                  }
                                >
                                  <img
                                    src={property.Image1Copy}
                                    alt="Image 1"
                                    onClick={() =>
                                      handleImageClick(property.Image1Copy)
                                    }
                                  />
                                </ImageContainer>
                              )}
                              {property.Image2Copy && (
                                <ImageContainer
                                  isExpanded={
                                    expandedImage === property.Image2Copy
                                  }
                                >
                                  <img
                                    src={property.Image2Copy}
                                    alt="Image 2"
                                    onClick={() =>
                                      handleImageClick(property.Image2Copy)
                                    }
                                  />
                                </ImageContainer>
                              )}
                              {property.Image3Copy && (
                                <ImageContainer
                                  isExpanded={
                                    expandedImage === property.Image3Copy
                                  }
                                >
                                  <img
                                    src={property.Image3Copy}
                                    alt="Image 3"
                                    onClick={() =>
                                      handleImageClick(property.Image3Copy)
                                    }
                                  />
                                </ImageContainer>
                              )}
                            </>
                          ) : (
                            <NoImageText>No images</NoImageText>
                          )}
                        </ImageWrapper>

                        {expandedImage && (
                          <ExpandedImageOverlay>
                            <ExpandedImageContainer
                              onClick={() => setExpandedImage(null)}
                              onWheel={handleWheel}
                            >
                              <ExpandedImage
                                src={expandedImage}
                                alt="Expanded"
                                zoomLevel={zoomLevel}
                              />
                              <CloseButton
                                onClick={() => setExpandedImage(null)}
                              >
                                X
                              </CloseButton>
                            </ExpandedImageContainer>
                          </ExpandedImageOverlay>
                        )}
                      </ResponsiveContainer>
                    )}
                  </div>
                )})}
              </div>
            ) : selectedSection === null && filteredProperties.length > 0 ? (
              
              <div>
                <h1 className="text-2xl font-bold mb-4">
                  Available Properties
                </h1>
                {filteredProperties.map((property, index) => 
                {
                  const matchingDocs = landRewardList.filter(doc => doc.projectId === property.propertyId);
                 return( 
                 <div
                    key={index}
                    className="h-auto w-auto my-1 border-b border-gray-200"
                  >
                    <PropertyContainer>
                      <PropertyDetails>
                        <PropertyNameWrapper>
                          <PropertyName>{property.name}</PropertyName>
                          {property && property.verified ? (
                            <Tooltip title="verified property">
                              <Logo1 src={green_verified} alt="verify" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Not verified property">
                              <Logo1 src={red_not_verified} alt="not verify" />
                            </Tooltip>
                          )}
                        </PropertyNameWrapper>
                       <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
                        <div style={{textAlign:"left"}}>{property?.city}</div>
                        <div style={{display:"flex",gap:"5px",alignItems:"center"}}>
                        <div style={{whiteSpace:"nowrap"}}>LANDs Remaining : {property?.LANDs} </div>
                        <div style={{alignSelf:"center"}}>
                          <FaGift title="Earn LAND" onClick={()=>handleDetails(property)}/>
                        </div>
                        </div>
                        </div>
                      </PropertyDetails>
                      <IconRow>
                     {/*  <Icon title="See Report"
                          src={icoreport}
                          alt="Report"
                          className={
                            pressedReportId ===
                                        property.propertyId &&
                                      reportOpen === property.propertyId
                                        ? "opacity-50"
                                        : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  
                                      toggleReport(property.propertyId);
                                            setPressedReportId(
                                              pressedReportId !==
                                                property.propertyId
                                                ? property.propertyId
                                                : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        /> */}

                        <Icon title="See Details"
                          src={icodetails}
                          alt="Details"
                          className={
                            pressedDetailsId === property.propertyId &&
                            detailsOpen === property.propertyId
                              ? "active"
                              : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  toggleDetails(property.propertyId);
                                  setPressedDetailsId(
                                    pressedDetailsId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        />
                        {/* <Icon
                          src={icocoin}
                          alt="Coin"
                          className={
                            pressedCoinId === property.propertyId &&
                            coinOpen === property.propertyId
                              ? "active"
                              : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  toggleCoin(property.propertyId);
                                  setPressedCoinId(
                                    pressedCoinId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        /> */}
                        <Icon title="Book Now"
                          src={icocoin}
                          alt="Coin"
                          className={`
    
  ${
    !property.digitalAsset && !property.crowdSale
      ? "opacity-60 cursor-not-allowed"
      : ""
  }`}
                          style={{
                            pointerEvents:
                              !property.digitalAsset && !property.crowdSale
                                ? "none"
                                : "auto",
                          }}
                          onClick={
                            isEditing ||
                            (!property.digitalAsset && !property.crowdSale)
                              ? null
                              : () => {
                                  toggleCoin(property.propertyId);
                                  setPressedCoinId(
                                    pressedCoinId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        />

                        <Icon title="See Location"
                          src={icolocation}
                          alt="Location"
                          className={
                            pressedLocationId === property.propertyId &&
                            locationOpen === property.propertyId
                              ? "active"
                              : ""
                          }
                          onClick={
                            isEditing
                              ? null
                              : () => {
                                  toggleLocation(property.propertyId);
                                  setPressedLocationId(
                                    pressedLocationId !== property.propertyId
                                      ? property.propertyId
                                      : null
                                  );
                                  setQuantityError("");
                                  setPriceError("");
                                  setQuantity("");
                                  setPrice("");
                                }
                          }
                        />
                      </IconRow>
                    </PropertyContainer>
                    {detailsOpen === property.propertyId && (
                      <Container1>
                        <Grid>
                          <InputGroup>
                            <Label>City</Label>
                            <Input
                              type="text"
                              value={isEditing ? editedCity : property.city}
                              readOnly={!isEditing}
                              onChange={(e) => setEditedCity(e.target.value)}
                            />
                          </InputGroup>
                          {isEditing ? (
                            <InputGroup>
                              <Label>Type</Label>
                              <Select
                                value={editedPropertyType}
                                onChange={(e) =>
                                  setEditedPropertyType(e.target.value)
                                }
                              >
                                <option value="">Select...</option>
                                <option value="Commercial Building">
                                  Commercial Building
                                </option>
                                <option value="Agriculture Land">
                                  Agriculture Land
                                </option>
                                <option value="Independent House">
                                  Independent House
                                </option>
                                <option value="Residential Apartment">
                                  Residential Apartment
                                </option>
                              </Select>
                            </InputGroup>
                          ) : (
                            <InputGroup>
                              <Label>Type</Label>
                              <Input
                                type="text"
                                value={property.propertyType}
                                readOnly
                              />
                            </InputGroup>
                          )}

                          <InputGroup>
                            <Label>Total Area</Label>
                            <Input
                              type="text"
                              value={
                                isEditing ? editedTotalArea : property.totalArea
                              }
                              readOnly={!isEditing}
                              onChange={(e) =>
                                setEditedTotalArea(e.target.value)
                              }
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Total Plots</Label>
                            <Input
                              type="text"
                              value={
                                isEditing
                                  ? editedTotalPlots
                                  : property.totalPlots
                              }
                              readOnly={!isEditing}
                              onChange={(e) =>
                                setEditedTotalPlots(e.target.value)
                              }
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Sold Plots</Label>
                            <Input
                              type="text"
                              value={
                                isEditing ? editedSoldPlots : property.soldPlots
                              }
                              readOnly={!isEditing}
                              onChange={(e) =>
                                setEditedSoldPlots(e.target.value)
                              }
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Digital Asset</Label>
                            <Input
                              type="text"
                              value={property.digitalAsset ? "Yes" : "No"}
                              readOnly
                            />
                          </InputGroup>
                          <InputGroup>
                            <Label>Verified</Label>
                            <Input
                              type="text"
                              value={property.verified ? "Yes" : "No"}
                              readOnly
                            />
                          </InputGroup>
                        </Grid>
                      </Container1>
                    )}
                     {/* {reportOpen === property.propertyId && (
                                              <Container1>
                                                
                                                <div className="reward-details-card__grid-container">
                                                          
                                                          <div className="reward-details-card__font-medium"  >Date</div>
                                                          <div className="reward-details-card__font-medium"  >User</div>
                                                          <div className="reward-details-card__font-medium"  >Referrer</div>
                                                          <div className="reward-details-card__font-medium" >LANDs</div>
                                                          <div className="reward-details-card__font-medium"  >Activity</div>
                                                          {matchingDocs 
                                                  .sort((a, b) => {
                                                    // Ensure both `createdAt` fields are valid and compare their timestamps
                                                    const dateA = a.createdAt?.toDate() || new Date(0); // Default to epoch if undefined
                                                    const dateB = b.createdAt?.toDate() || new Date(0);
                                                    return dateA - dateB; // Ascending order: oldest first, newest last
                                                  })
                                                  .map((reward, index) => {
                                                    const matchingUserDoc=userList.find(doc=>doc?.id===reward?.userId)
                                                   return <React.Fragment key={reward.userId || index}>
                                                      <div>{reward.createdAt?.toDate().toLocaleDateString() || "N/A"}</div>
                                                      <div>{matchingUserDoc?.mobile || "N/A"}</div>
                                                      <div>{reward?.referredUserId || "N/A"}</div>
                                                      <div>{reward?.LANDs || 0}</div>
                                                      <div>{reward?.activity || "NA"}</div>
                                                    </React.Fragment>
                                      })}
                                                
                                                        </div>
                                                 
                                                 
                                               
                                              </Container1>
                                            )} */}
                    {coinOpen === property.propertyId && (
                      <>
                        {property.crowdSale &&
                          (() => {
                            const sale = crowdSales.find(
                              (sale) => sale.propertyId === property.propertyId
                            );

                            if (sale) {
                              return (
                                <div
                                  className="w-full p-5 rounded-lg text-center"
                                  style={{ marginTop: "12px" }}
                                >
                                  <h2 className="font-bold">Use Paper</h2>
                                  {!isConfirmBuyBlockChain ? (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Total Units</Label>
                                          <Input
                                            type="text"
                                            value={sale.totalArea}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Selling Units</Label>
                                          <Input
                                            type="text"
                                            value={sale.sellingArea}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Available Units</Label>
                                          <Input
                                            type="text"
                                            value={sale.availableUnits}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Base Currency</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? String(sale.baseCurrency)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Market Rate</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? String(sale.marketRate)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Selling Rate</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? Number(sale.sellingRate).toFixed(2)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Sale Start Date</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale ? String(sale.saleStart) : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Sale End Date</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale ? String(sale.saleEnd) : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Target Amount</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? String(sale.minSaleAmount)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Current Sale Amount</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale
                                                ? Number(sale.currentSaleAmount).toFixed(2)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Status</Label>

                                          <Input
                                            type="text"
                                            value={
                                              sale ? String(sale.status) : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                      </Grid>

                                      {console.log(
                                        sale.startDate > presentDate
                                      )}
                                      {console.log(presentDate)}
                                      {console.log(formatDate)}

                                      {/* {(Number(sale.availableUnits) > 0) ? (
                                        (sale.startDate <= presentDate) ? (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                            onClick={handleBlockChainBuy} // Function used here
                                          >
                                            Buy
                                          </button>
                                        ) : (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                            disabled
                                          ></button>
                                        )
                                      ) : (
                                        <button
                                          className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                          disabled
                                        ></button>
                                      )} */}
                                     
                                      
                                      {Number(sale.availableUnits) > 0 ? (
                                       ( (sale.saleStart )<=( presentDate) )? (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                            // onClick={handleBlockChainBuy} // Function used here
                                            onClick={()=>navigate("/auth")}
                                          >
                                            Book Now
                                          </button>
                                        ) : (
                                          <button
                                            className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                            disabled
                                          >
                                            Sale hasn't started yet
                                          </button>
                                        )
                                      ) : (
                                        <button
                                          className="px-4 py-2 mt-4 font-bold text-gray-400 bg-gray-200 rounded cursor-not-allowed"
                                          disabled
                                        >
                                          No Available Units
                                        </button>
                                      )}
                                    </Container1>
                                  ) : (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Total Units</Label>
                                          <Input
                                            type="text"
                                            onChange={(e) =>
                                              handlecost(e, sale)
                                            }
                                            value={unitAmount}
                                            title="Decimal values  are not accepted"
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Cost</Label>
                                          <Input
                                            type="text"
                                            value={cost}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Discount</Label>
                                          <Input
                                            type="text"
                                            value={actualDiscount}
                                            readOnly
                                          />
                                        </InputGroup>
                                      </Grid>
                                      <button
                                        // onClick={() => {
                                        //   handlePayment(sale, cost);
                                        // }}
                                        disabled={isProcessing}
                                        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                      >
                                        Pay Now
                                       
                                      </button>
                                      <button
                                       
                                      // disabled={isProcessing}
                                      
                                       className="px-4 py-2 mt-4 ml-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                     >
                                      Pay Later
                                      
                                     </button>
                                     { isProcessing && (
                                       <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                                       <div className="p-5  rounded-lg text-center shadow-lg">
                                         <div className="loader mb-4"></div> {/* Loader animation */}
                                         
                                         <p style={{color:"white"}}>Please wait for a few seconds...</p>
                                       </div>
                                     </div>
                                     )}
                                     <style jsx>{`
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`}</style>


                                    </Container1>
                                  )}
                                </div>
                              );
                            }
                          })()}
                        {property.digitalAsset &&
                          (() => {
                            const asset = digitalAssets.find(
                              (asset) =>
                                asset.propertyId === property.propertyId
                            );
                            if (asset) {
                              return (
                                <div
                                  className="w-full p-5 rounded-lg text-center"
                                  style={{ marginTop: "12px" }}
                                >
                                  <h2 className="font-bold">Paperless</h2>
                                  {!isConfirmBuyOpen ? (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Blockchain</Label>
                                          <Input
                                            type="text"
                                            value={asset.blockchain}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Total Area</Label>
                                          <Input
                                            type="text"
                                            value={asset.TotalArea}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Token Name</Label>
                                          <Input
                                            type="text"
                                            value={asset.tokenName}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Base Price</Label>
                                          {/* <input type="text" className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" value={asset.tokenTokens} readOnly /> */}
                                          <Input
                                            type="text"
                                            value={
                                              asset
                                                ? String(asset.basePrice)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Base Currency</Label>
                                          {/* <input type="text" className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" value={asset.tokenTokens} readOnly /> */}
                                          <Input
                                            type="text"
                                            value={
                                              asset
                                                ? String(asset.baseCurrency)
                                                : ""
                                            }
                                            readOnly
                                          />
                                        </InputGroup>
                                      </Grid>
                                      <button
                                        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                        onClick={()=>navigate("/auth")}
                                      >
                                        Book Now
                                      </button>
                                    </Container1>
                                  ) : (
                                    <Container1>
                                      <Grid>
                                        <InputGroup>
                                          <Label>Enter Quantity</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={quantity}
                                            onChange={(e) =>
                                              handleQuantityChange(
                                                e,
                                                asset.totalTokens
                                              )
                                            }
                                          />
                                          {quantityError && (
                                            <p className="text-red-500 text-xs mt-1">
                                              {quantityError}
                                            </p>
                                          )}
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Available</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={asset.totalTokens}
                                            readOnly
                                          />
                                        </InputGroup>

                                        <InputGroup>
                                          <Label>Token Name</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={asset.tokenName}
                                            readOnly
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Label>Price</Label>
                                          <Input
                                            type="text"
                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                            value={price}
                                            onChange={handlePriceChange}
                                          />
                                          {priceError && (
                                            <p className="text-red-500 text-xs mt-1">
                                              {priceError}
                                            </p>
                                          )}
                                        </InputGroup>
                                      </Grid>

                                      <button
                                        className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                        onClick={() => Buy(property)}
                                      >
                                        Confirm Offer
                                      </button>
                                    </Container1>
                                  )}
                                </div>
                              );
                            }
                          })()}
                      </>
                    )}

                    {!isTransaction && (
                      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded-md shadow-md">
                          <p>Do you want to Retry Again?</p>
                          <div className="mt-4 flex justify-end">
                            <button
                              onClick={handleRetryTransactionYes}
                              className="bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 mr-2"
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => handleRetryTransactionNo()}
                              className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {locationOpen === property.propertyId && (
                      <ResponsiveContainer>
                        <ResponsiveIframeWrapper>
                          <ResponsiveIframe
                            src={smartLayout}
                            allow="geolocation"
                            sandbox="allow-same-origin allow-scripts allow-modals"
                          ></ResponsiveIframe>
                        </ResponsiveIframeWrapper>

                        <SectionTitleWrapper>
                          <SectionTitle
                            onClick={() => window.open(smartLayout, "_blank")}
                          >
                            Open in new Tab
                          </SectionTitle>
                          <SectionDivider />
                          <SectionTitle>Plan</SectionTitle>
                          <SectionDivider />
                        </SectionTitleWrapper>

                        <SectionTitleWrapper>
                          {property.planCopy ? (
                            <PlanLink
                              onClick={() =>
                                window.open(property.planCopy, "_blank")
                              }
                            >
                              click here
                            </PlanLink>
                          ) : (
                            <NoPlanText>No Plan Copy</NoPlanText>
                          )}
                        </SectionTitleWrapper>

                        <SectionTitleWrapper>
                          <SectionDivider />
                          <SectionTitle>Images</SectionTitle>
                          <SectionDivider />
                        </SectionTitleWrapper>

                        <ImageWrapper>
                          {property.Image1Copy ||
                          property.Image2Copy ||
                          property.Image3Copy ? (
                            <>
                              {property.Image1Copy && (
                                <ImageContainer
                                  isExpanded={
                                    expandedImage === property.Image1Copy
                                  }
                                >
                                  <img
                                    src={property.Image1Copy}
                                    alt="Image 1"
                                    onClick={() =>
                                      handleImageClick(property.Image1Copy)
                                    }
                                  />
                                </ImageContainer>
                              )}
                              {property.Image2Copy && (
                                <ImageContainer
                                  isExpanded={
                                    expandedImage === property.Image2Copy
                                  }
                                >
                                  <img
                                    src={property.Image2Copy}
                                    alt="Image 2"
                                    onClick={() =>
                                      handleImageClick(property.Image2Copy)
                                    }
                                  />
                                </ImageContainer>
                              )}
                              {property.Image3Copy && (
                                <ImageContainer
                                  isExpanded={
                                    expandedImage === property.Image3Copy
                                  }
                                >
                                  <img
                                    src={property.Image3Copy}
                                    alt="Image 3"
                                    onClick={() =>
                                      handleImageClick(property.Image3Copy)
                                    }
                                  />
                                </ImageContainer>
                              )}
                            </>
                          ) : (
                            <NoImageText>No images</NoImageText>
                          )}
                        </ImageWrapper>

                        {expandedImage && (
                          <ExpandedImageOverlay>
                            <ExpandedImageContainer
                              onClick={() => setExpandedImage(null)}
                              onWheel={handleWheel}
                            >
                              <ExpandedImage
                                src={expandedImage}
                                alt="Expanded"
                                zoomLevel={zoomLevel}
                              />
                              <CloseButton
                                onClick={() => setExpandedImage(null)}
                              >
                                X
                              </CloseButton>
                            </ExpandedImageContainer>
                          </ExpandedImageOverlay>
                        )}
                      </ResponsiveContainer>
                    )}
                    
                  </div>
)})}
              </div>
            ) :(
              <h1 className="text-2xl font-bold mb-4 mt-2">
                No Properties
            </h1>
            

            )}
          </div>)}
          
        </div>
      </div>
    </div>
  );
};

export default Search;
