
import "./MonitorLand.css";
import { Logo } from "../area";
import { useNavigate } from "react-router-dom";
import { Header, HeaderContent } from "../Faq.style";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import logo from "../../images/thirdeye.png";
import { useEffect, useState } from "react";

const MonitorLand = () => {
    const navigate=useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user,setUser]=useState(null);
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user)
          setIsLoggedIn(true); 
        } else {
          setIsLoggedIn(false); 
        }
      });
  
      return () => unsubscribe();
    }, []);
  return (
    <>
    <Header>
    <HeaderContent>
    <Logo src={logo} alt="Thirdeye" onClick={()=>navigate("/")} />
          {isLoggedIn && (<h3
            className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
            onClick={() => {
              navigate("/rewardcustomerdash");
            }}
          >
            Dashboard
          </h3>)}
    </HeaderContent>
  </Header>
    <div className="monitorland-container">
      <div className="monitorland-header">
        <h1>
          Monitor and Manage Your <span className="highlight">Open Lands</span> with Ease!
        </h1>
        <p>
          Transform the way you manage your open lands with the <a style={{color:"#2980b9",cursor:"pointer"}} onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.saralam.realblock","_blank")}>ThirdEye Mobile App</a>. Whether you own a single plot or multiple properties, our app provides powerful tools to help you stay updated, secure, and plan for the future—all from the palm of your hand.
        </p>
      </div>

      <div className="monitorland-why">
        <h2>Why Use ThirdEye?</h2>
        <div className="monitorland-card">
          <h3>1. Real-Time Monitoring</h3>
          <p>
            Keep track of your open lands 24/7. Get updates and ensure your property stays secure and well-maintained.
          </p>
        </div>

        <div className="monitorland-card">
          <h3>2. Essential Services at Your Fingertips</h3>
          <ul>
            <li>📄 <strong>Get Encumbrance Certificates (EC):</strong> Easily request and receive EC for your property.</li>
            <li>📍 <strong>On-Demand Property Visits:</strong> Schedule a visit and get updates whenever you need.</li>
            <li>📷 <strong>Live Photos:</strong> Request live photos of your property to ensure everything is as it should be.</li>
          </ul>
        </div>

        <div className="monitorland-card">
          <h3>3. Secure Your Land</h3>
          <ul>
            <li>🛡️ <strong>Fencing Walls:</strong> Plan and build fencing to secure your open lands.</li>
            <li>🏗️ <strong>Custom Building Plans:</strong> Draw and design building plans tailored to your vision.</li>
          </ul>
        </div>
      </div>

      <div className="monitorland-how">
        <h2>How It Works</h2>
        <div className="monitorland-steps">
          <p>📲 <strong>Download the App:</strong> ThirdEye is available on Google Play.</p>
          <p>🏠 <strong>Register Your Property:</strong> Add your open lands to monitor and manage them seamlessly.</p>
          <p>🏛️ <strong>Request Services:</strong> Choose the services you need, and our team will handle the rest.</p>
        </div>
      </div>

      <div className="monitorland-cta">
        <h2>Take Control Today!</h2>
        <p>
          Stop worrying about your open lands and start managing them like a pro. With ThirdEye, you can monitor, secure, and plan your property’s future effortlessly.
        </p>
        <a onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.saralam.realblock","_blank")} className="cta-button">👉 Download Now: ThirdEye Mobile App</a>
      </div>
    </div>
    </>
  );
};

export default MonitorLand;
