/* import React from "react";
// import "../index.css";
import bannerImage from "../images/thirdeye.png"; // Add your image here
import "../components/home.css"
import { HeaderContent } from "./Faq.style";
import { Header } from "./dashboard";
import logo from "../images/thirdeye.png";
import { Logo } from "./area";
import sample from "../images/sampleimg.jpg"
import cmg from "../images/commingsoon.jpg"




const listings = [
  {
    id: 1,
    title: 'Pharma City',
    location: 'Yacharam, Telangana',
    price: 'Own A Piece Of Land At ₹100 ',
    status: 'Coming Soon',
    image: cmg,
    link: '/',
  },
  {
    id: 2,
    title: 'Oak Brook Gardens',
    location:' Pochampally Road, Ramoji Film City, Telangana',
    price: 'You Can Buy  1 SQYD Too',
    status: 'on sale',
    image: sample,
    link: '/details',
  },
  {
    id: 3,
    title: 'Dry Port',
    location: 'Hyderabad, Telangana',
    price: 'You Can Buy  1 SQYD Too',
    status: 'Coming Soon',
    image:  cmg,
    link: '/',
  },
];
function Home() {
  return (<>
    <Header>
          <HeaderContent>
            <Logo src={logo} alt="Thirdeye" />
          </HeaderContent>
        </Header>
        <div className="container">
      <div className="property-grid">
        {listings.map((property) => (
          <div key={property.id} className="property-tile">
            <img src={property.image} alt={property.title} className="property-image" />
            <div className="property-info">
              <h3 className="property-title">{property.title}</h3>
              <p className="property-location">{property.location}</p>
              <p className="property-price"> {property.price}</p>
              <span className={`property-status ${property.status.toLowerCase()}`}>
                {property.status}
              </span>
              {property.status.toLowerCase() !== "coming soon" && (
  <a href={property.link} className="view-details-btn">View Details</a>)}
      <div className="flex items-center justify-center h-full">
  <a 
    href={property.link} 
    className="text-blue-500 hover:text-blue-700  font-semibold transition-all duration-300 mt-5"
  >
   Share & Earn Land!
  </a>
</div>

            </div>
          
          </div>
        ))}
      </div>
    </div>
</>
  );
}

export default Home;
 */

import React, { useEffect, useState } from "react";
import "../components/home.css";
import { HeaderContent } from "./Faq.style";
import { Header } from "./dashboard";
import logo from "../images/thirdeye.png";
import { Logo } from "./area";
import sample from "../images/sampleimg.jpg";
import sample2 from "../images/THCard.jpg";
import sample3 from "../images/AparnaKoheda.png";
import cmg from "../images/commingsoon.jpg";
import { useNavigate } from "react-router-dom";

import CampaignIcon from "@mui/icons-material/Campaign";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import PreviewIcon from "@mui/icons-material/Preview";
import { FaBars, FaRupeeSign, FaSearchLocation } from "react-icons/fa";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Footer from "./Footer";
import { CurrencyRupee } from "@mui/icons-material";
import SvgIcon from "@mui/icons-material/Campaign";
import { collection, getDocs, getFirestore, orderBy, query, where } from "firebase/firestore";
// Dynamically generate unique IDs for each property
/* const listingsWithIds = listings.map((property) => ({
  id:  crypto.randomUUID(), 
  ...property,
})); */

function Home() {
  const navigate = useNavigate();
  const db=getFirestore();

  const [c1open, setC1Open] = useState(false);
  const [c2open, setC2Open] = useState(false);
  const [c3open, setC3Open] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading,setLoading]=useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Property listings without hardcoded IDs
  const listings = [
    {
      id: "8BQHymQlmeDiTCxkhaUP",
      title: "Arpan Koheda",
      location: "Koheda,Hyderabad,Telangana",
      price: "A Vision for the Future",
      status: "on sale",
      land: 10000,
      image: sample3,
      link: "/details",
    },
    {
      id: "xVBe90BdInon8rxSb1nF",
      title: "Oak Brook Gardens",
      location: "Pochampally Road, Ramoji Film City, Telangana",
      price: "You Can Buy 1 SQYD Too",
      status: "on sale",
      land: 100000,
      image: sample,
      link: "/details",
    },
    {
      id: "qfhjwm9Akdz1j6s1VbSi",
      title: "Tirumala Hills 2",
      location: "Kawadipally, Abdullapurmet,Hyderabad, Telangana",
      price: "The God’s Own Residence",
      status: "on sale",
      land: 10000,
      image: sample2,
      link: "/details",
    },
    
    
    
    
  ];
  const [propertiesList, setPropertiesList] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        // Use Firestore query with orderBy
        const q = query(collection(db, "realProperty"),
         where("published", "==", true),orderBy("LANDs", "desc"));
        const querySnapshot = await getDocs(q);
       
  
        const properties = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Include the document ID
          ...doc.data(), // Spread the document data
        }));
  
        setPropertiesList(properties); // Update the state with sorted properties
      } catch (error) {
        console.error("Error fetching properties: ", error);
      }finally{
        setLoading(false)
      }
    };
  
    fetchProperties();
  }, [db]);
  


  const handleopenprofile = (property) => {
    // Replace spaces with hyphens in the property title
    const formattedTitle = property.name.replace(/\s+/g, "-");

    // Save the property ID in local storage
    localStorage.setItem("selectedProject", property.id);

    console.log(
      "checking in home propertyId",
      localStorage.getItem("selectedProject")
    );

    // Navigate to the new URL
    navigate(`/${formattedTitle}`);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header>
      <HeaderContent>
        {/* Hamburger Menu */}
        {isMobile && (
          <FaBars
            style={{
              fontSize: "24px",
              cursor: "pointer",
              marginRight: "10px",
            }}
            onClick={() => setMenuOpen(!menuOpen)}
          />
        )}

        {/* Content */}
        {(menuOpen || !isMobile) && (
          <div
            className="container-tab"
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: isMobile ? "flex-start" : "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <ul className="ulclass">
              <Logo src={logo} alt="Thirdeye" />
            </ul>
            <ul className="ulclass" onClick={() => window.open("/feepromotion","_blank")}>
              <CampaignIcon></CampaignIcon>
             Free Promotion
            </ul>
            <ul className="ulclass" onClick={() =>window.open("/search","_blank")}>
           <TravelExploreIcon></TravelExploreIcon>
             Search Property
              
            </ul>
            <ul className="ulclass" onClick={() =>window.open("/earnland","_blank")}>
              <CurrencyRupee></CurrencyRupee>
              Earn LAND 
            </ul>
            <ul className="ulclass" onClick={() => window.open("/monitorland","_blank")}>
            <PreviewIcon></PreviewIcon> 
             Monitor Land
            </ul>
            <ul className="ulclass" >
              {" "}
              {isLoggedIn && (
                <h3
                  className="flex items-center gap-2 text-blue-500 text-xl font-bold cursor-pointer hover:text-blue-700 hover:scale-105 transition-all duration-300 mr-2"
                  onClick={() => {
                    navigate("/rewardcustomerdash");
                  }}
                >
                  Dashboard
                </h3>
              )}
            </ul>
          </div>
        )}
      </HeaderContent>
    </Header>
      <div className="container">
       {loading?( <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500">
          {" "}
        </div>
      </div>):(propertiesList.length > 0 ? (
  <div className="property-grid">
    {propertiesList.map((property) => (
      <div key={property.id} className="property-tile">
        <img
          src={property?.Image1Copy}
          alt={property.name}
          className="property-image"
        />
        <div className="property-info">
          <h3 className="property-title">{property.name}</h3>
          <p className="property-location">
            {property?.city}, {property?.state}
          </p>
          <p className="property-price">{property?.tagline}</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              gap: "7px",
            }}
          >
            <span className={`property-status`}>
              {property.LANDs}
              <br />
              <span style={{ color: "white" }}>LAND Rewards</span>
            </span>
            <a
              className="view-details-btn"
              onClick={() => handleopenprofile(property)}
              style={{ cursor: "pointer" }}
            >
              View Details
            </a>
          </div>

          <div className="flex items-center justify-center h-full">
            <a
              onClick={() => handleopenprofile(property)}
              className="text-blue-500 hover:text-blue-700 font-semibold transition-all duration-300 mt-5 cursor-pointer flex items-center justify-center content-center"
            >
              <FaRupeeSign /> View & Earn Land!
            </a>
          </div>
        </div>
      </div>
    ))}
  </div>
) : (
  <div className="flex justify-center items-center h-screen">
        <h1>No Available Properties</h1>
      </div>
  
))}


      

          
          
          
          
            {/*  {listings.map((property) => (
            <div key={property.id} className="property-tile">
              <img
                src={property.image}
                alt={property.title}
                className="property-image"
              />
              <div className="property-info">
                <h3 className="property-title">{property.title}</h3>
                <p className="property-location">{property.location}</p>
                <p className="property-price">{property.price}</p>
                <div style={{display:"flex",alignItems:"center",alignContent:"center",gap:"7px"}}>
                <span
                  className={`property-status ${property.status.toLowerCase()}`}
                >
                  {property.land}
                  <br />
                  <span  style={{ color: "white" }}>
                    LAND Rewards
                  </span>
                </span>
                {property.status.toLowerCase() !== "coming soon" && (
                  <a
                    className="view-details-btn"
                    onClick={() => handleopenprofile(property)}
                    style={{ cursor: "pointer" }}
                  >
                    View Details
                  </a>
                )}
                </div>

                <div className="flex items-center justify-center h-full">
                  <a
                    onClick={
                      property.status !== "Coming Soon"
                        ? () => handleopenprofile(property)
                        : undefined
                    }
                    className={`${
                      property.status === "Coming Soon"
                        ? `text-blue-500  font-semibold transition-all duration-300 mt-5  flex  items-center justify-center content-center`
                        : `text-blue-500 hover:text-blue-700 font-semibold transition-all duration-300 mt-5 cursor-pointer flex  items-center justify-center content-center`
                    }`}
                  >
                    {property.status !== "Coming Soon" && (
                      <>
                        <FaRupeeSign /> View & Earn Land!
                      </>
                    )}
                  </a>
                </div>
              </div>
            </div>
          ))} */}
 
       
      </div>
    </>
  );
}

export default Home;
